import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";

const FETCH_QUERY = gql`
mutation MyMutation ($fName: String!, $lName: String!, $mName: String!, $email: String!, $phone: String!, $password: String!, $passwordRep: String!, $cardNum: String!) {
    regByEmail(
      inp: {firstName: $fName, lastName: $lName, email: $email, phone: $phone, password: $password, passwordRepeat: $passwordRep, unionCardNum: $cardNum, middleName: $mName}
    ) {
      errors
      ok
      user {
        email
        firstName
        lastName
        middleName
        phone
        pic
        unionCardNum
        position {
          id
          name
        }
      }
    }
  }
`
const maskGenerator = createDefaultMaskGenerator('+7 999 999 9999');

function Registration() {
    const [inpStates, setInpStates] = useState({
        firstName: false,
        lastName: false,
        phone: false,
        email: false,
        password: false,
        passwordRep: false,
        unionCardNum: false
    })
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [midlleName, setMiddleName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRep, setPasswordRep] = useState('');
    const [unionCardNum, setUnionCardNum] = useState('');

    const [submitHandler, {data}] = useMutation(FETCH_QUERY, {variables: {
        fName: firstName,
        lName: lastName,
        mName: midlleName,
        email: email,
        phone: phone,
        password: password,
        passwordRep: passwordRep,
        cardNum: unionCardNum
    }})

    const navigation = useNavigate()
    return (
        <>
            <link rel="stylesheet" href="/assets/css/personal__ooffice/po__registration.css"></link>

            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <a href="/" className="g-bc__item">главная</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">Регистрация</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-section g-registration__section">
                <div className="g-container">
                    <div className="g-row">
                        <h2 className="g-section__heading">Зарегистрироваться   </h2>
                        <form action="#" className="g-form g-dfw">
                            <h4 className="g-post__heading">Основное</h4>
                            <label className={inpStates.unionCardNum ? 'g-f__label w-33 mr15 err' : 'g-f__label w-33 mr15'}>
                                <span>Табельный номер</span>
                                <input type="number" placeholder="889 605 978" value={unionCardNum} onChange={e => {console.log(inpStates);setUnionCardNum(e.target.value); unionCardNum != '' ? inpStates['unionCardNum'] = true : inpStates['unionCardNum'] = false; console.log(inpStates);}} required/>
                            </label>
                            <label className={inpStates.firstName ? 'g-f__label w-33 mr15 err' : 'g-f__label w-33 mr15'}>
                                <span>Ваше имя</span>
                                <input type="text" name="name"  value={firstName} onChange={e => setFirstName(e.target.value)} required/>
                            </label>
                            <label className={inpStates.lastName ? 'g-f__label w-33 mr15 err' : 'g-f__label w-33 mr15'}>
                                <span>Фамилия</span>
                                <input type="text" name="" value={lastName} onChange={e => setLastName(e.target.value)} required/>
                            </label>
                            <label className={inpStates.phone ? 'g-f__label w-33 mr15 err' : 'g-f__label w-33 mr15'}>
                                <span>Номер телефона</span>
                                {/* <input type="tel" name="tel[]" className="masked-phone" placeholder="+7" value={phone} onChange={e => setPhone(e.target.value)} required/> */}
                                <MaskedInput
                                    maskGenerator={maskGenerator}
                                    value={phone}
                                    onChange={setPhone}
                                />
                            </label>
                            <label className={inpStates.email ? 'g-f__label w-33 mr15 err' : 'g-f__label w-33 mr15'}>
                                <span>Электронная почта</span>
                                <input type="email" placeholder="name@mail.com" value={email} onChange={e => setEmail(e.target.value)} required/>
                            </label>
                            <label className="g-f__label agreement reset w-33 g-dfw g-alt-center">
                            </label>

                            <h4 className="g-post__heading">Информация о себе</h4>
                            <label className="g-f__label w-33 mr15 g-hi">
                                <div className="g-fl__wrap">
                                    <span>Филиал / структурное подразделение</span>
                                    <select name="">
                                        <option value="Охрана труда">Инженерно-технический цен...</option>
                                    </select>
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5 9L12 16.5L4.5 9" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </label>
                            <label className="g-f__label w-33 mr15 g-hi">
                                <div className="g-fl__wrap">
                                    <span>Ваша должность</span>
                                    <select name="">
                                        <option value="Охрана труда">Инженер II категории</option>
                                    </select>
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5 9L12 16.5L4.5 9" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </label>
                            <label className="g-f__label w-33 g-hi">
                                <div className="g-fl__wrap">
                                    <span>Дата трудоустройства</span>
                                    <input type="date" name="" value="10 ноября 2022"/>
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M16.5 2.25V5.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5 2.25V5.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M3.75 8.25H20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.625 11.9998H11.25L9.75 13.8748C9.99664 13.8748 10.2395 13.9356 10.457 14.0518C10.6745 14.1681 10.86 14.3362 10.9971 14.5412C11.1341 14.7463 11.2185 14.9819 11.2427 15.2274C11.267 15.4728 11.2303 15.7205 11.136 15.9484C11.0417 16.1763 10.8926 16.3774 10.702 16.5339C10.5114 16.6904 10.2852 16.7976 10.0433 16.8458C9.80143 16.894 9.55139 16.8819 9.31534 16.8104C9.07928 16.7389 8.86449 16.6104 8.68999 16.4361" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M13.5 13.1248L15 11.9998V16.8748" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </label>
                            <label className="g-f__label w-33 mr15 g-hi">
                                <div className="g-fl__wrap">
                                    <span>Семейное положение</span>
                                    <select name="">
                                        <option value="Охрана труда">Женат</option>
                                    </select>
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5 9L12 16.5L4.5 9" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </label>
                            <label className="g-f__label w-33 mr15 g-hi">
                                <div className="g-fl__wrap">
                                    <span>Наличие детей</span>
                                    <select name="">
                                        <option value="Охрана труда">Детей нет</option>
                                    </select>
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5 9L12 16.5L4.5 9" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </label>
                            <label className="g-f__label w-33 g-hi">
                                <div className="g-fl__wrap">
                                    <span>Статус работника</span>
                                    <select name="">
                                        <option value="Охрана труда">Молодой специалист</option>
                                    </select>
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5 9L12 16.5L4.5 9" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </label>
                            <label className="g-f__label w-33 mr15 g-hi">
                                <div className="g-fl__wrap">
                                    <span>Статус ветерана войны (ликвидатор ЧАЭС)</span>
                                    <select name="">
                                        <option value="Охрана труда">Нет</option>
                                    </select>
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5 9L12 16.5L4.5 9" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </label>

                            <h4 className="g-post__heading">Прикрепить файл</h4>
                            <label className="g-f__label dashed file border-blue g-dfw">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.25 12H15.75" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12 8.25V15.75" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <h4 className="blue">Загрузить файл с устройста</h4>                                
                                <p>Файлы форматов: ODT, PDF, DOC, DOCX, XLSX</p>
                                <input type="file" name="" id=""/>
                            </label>
                            <div className="g-f__label file g-ha g-dfw">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 9H15" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9 12H15" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9 15H12" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.6893 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5V14.6893C20.25 14.7878 20.2306 14.8854 20.1929 14.9764C20.1552 15.0673 20.1 15.15 20.0303 15.2197L15.2197 20.0303C15.15 20.1 15.0673 20.1552 14.9764 20.1929C14.8854 20.2306 14.7878 20.25 14.6893 20.25V20.25Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M20.1822 14.999H15V20.1815" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <h4>Файл презентации идеи</h4>                                
                                <p>Файл PDF · 84 kb</p>
                                <svg className="g-fl_action" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.4998 3.5L2.49976 3.50001" stroke="#B2BCC3" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6.5 6.5V10.5" stroke="#B2BCC3" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.5 6.5V10.5" stroke="#B2BCC3" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12.5 3.5V13C12.5 13.1326 12.4473 13.2598 12.3536 13.3536C12.2598 13.4473 12.1326 13.5 12 13.5H4C3.86739 13.5 3.74021 13.4473 3.64645 13.3536C3.55268 13.2598 3.5 13.1326 3.5 13V3.5" stroke="#B2BCC3" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10.5 3.5V2.5C10.5 2.23478 10.3946 1.98043 10.2071 1.79289C10.0196 1.60536 9.76522 1.5 9.5 1.5H6.5C6.23478 1.5 5.98043 1.60536 5.79289 1.79289C5.60536 1.98043 5.5 2.23478 5.5 2.5V3.5" stroke="#B2BCC3" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </div>

                            <h4 className="g-post__heading">Данные для входа</h4>
                            <label className={inpStates.password ? 'g-f__label w-33 mr15 g-hi err' : 'g-f__label w-33 mr15 g-hi'}>
                                <div className="g-fl__wrap">
                                    <span>Пароль</span>
                                    <input type="password" placeholder="· · · · · · · · · · · · · · ·" value={password} onChange={e => setPassword(e.target.value)} required/>
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 5.24902C4.5 5.24902 1.5 11.9998 1.5 11.9998C1.5 11.9998 4.5 18.749 12 18.749C19.5 18.749 22.5 11.9998 22.5 11.9998C22.5 11.9998 19.5 5.24902 12 5.24902Z" stroke="#A8B4BD" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z" stroke="#A8B4BD" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                                 
                            </label>
                            <label className={inpStates.passwordRep ? 'g-f__label w-33 mr15 g-hi err' : 'g-f__label w-33 mr15 g-hi'}>
                                <div className="g-fl__wrap">
                                    <span>Пароль</span>
                                    <input type="password" placeholder="· · · · · · · · · · · · · · ·" value={passwordRep} onChange={e => setPasswordRep(e.target.value)} required/>
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.5 3.75L19.5 20.25" stroke="#A8B4BD" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.5226 14.7752C13.7866 15.4441 12.8151 15.7933 11.8217 15.7459C10.8284 15.6986 9.89446 15.2586 9.22548 14.5227C8.5565 13.7868 8.20723 12.8153 8.25448 11.822C8.30174 10.8286 8.74167 9.89463 9.47749 9.22559" stroke="#A8B4BD" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6.93698 6.43066C3.11486 8.36618 1.5 12 1.5 12C1.5 12 4.5 18.7493 12 18.7493C13.7572 18.7633 15.4926 18.3585 17.0623 17.5685" stroke="#A8B4BD" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M19.5571 15.8528C21.6011 14.0222 22.5 11.9998 22.5 11.9998C22.5 11.9998 19.5 5.24904 12 5.24904C11.3504 5.24798 10.7019 5.30079 10.061 5.40693" stroke="#A8B4BD" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12.7058 8.31641C13.5028 8.46946 14.2288 8.8768 14.7747 9.47735C15.3206 10.0779 15.6571 10.8393 15.7337 11.6472" stroke="#A8B4BD" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                                                               
                            </label>
                            <label className="g-f__label w-33 reset ">
                                <div className="g-fl__wrap">
                                    <span>Пароль должен содержать не менее 8 символов. Пароль должен содержать заглавные
                                        и строчные буквы, цифры, пробелы
                                        и специальные символы.</span>
                                </div>                                                                              
                            </label>
                            <button type="button" onClick={() => submitHandler({variables: {fName: firstName, lName: lastName, mName: midlleName, email: email, phone: phone, password: password, passwordRep:passwordRep, cardNum: unionCardNum}}).then(res => console.log(res?.data.regByEmail.ok ? navigation('/activation', {state: {email: email}}) : console.log(res?.data.regByEmail.errors[0])))} className="g-btn g-btn__blue g-f__label reset w-33 mr15 g-f__btn g-dfw g-alt-center g-jc-sb">
                                <span className="reset">Зарегистрироваться</span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 16.5L16.5 5.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="white" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>                                
                            </button>
                            <label className="g-f__label agreement reset w-33 g-dfw g-alt-center">
                                <input type="checkbox"/>
                                <div className="g-fl__wrap">
                                    <span>Принимаю <Link to="/policy">условия обработки</Link> персональных данных</span>
                                </div>
                            </label>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Registration;