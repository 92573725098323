import clsx from "clsx";
import { createEvent, restore } from "effector";
import { useStore } from "effector-react";
import { useCallback, useEffect, useState } from "react";
import { Link as RLink } from "react-router-dom";

let setMegaOpen = createEvent()
let $megaOpen = restore(setMegaOpen, false)

let setMobileOpen = createEvent()
let $mobileOpen = restore(setMobileOpen, false)

function Link({...props}) {
    return <RLink {...props} onClick={()=> {
        setMegaOpen(false)
        setMobileOpen(false)
    }} />
}

function Navbar() {
    let megaOpen = useStore($megaOpen)
    let mobileOpen = useStore($mobileOpen)
    const [sidebarOpen, setSidebarOpen] = useState(Boolean)

    const nav = document.querySelectorAll('.g-nav');
        
    window.addEventListener('scroll', ()=> {
        if (document.documentElement.scrollTop > 80) {
            nav.forEach(item => item.classList.add('fix'));
        }else {
            nav.forEach(item => item.classList.remove('fix'));
        }
    });

    console.log('1 ad event')
    useEffect(()=> {
        console.log('ad event')
        window.addEventListener('popstate', ()=> {
            console.log('set di')
            setSidebarOpen(false)
        })
    }, [window, setSidebarOpen])
    

    return (
        <>
            <nav className={megaOpen ? 'g-nav mega': 'g-nav'}>
                <div className="g-container">
                    <div className="g-row g-alt-center g-jc-sb">
                        <div className={clsx('g-mega-menu', megaOpen && 'active')}>
                            <svg onClick={() => setMegaOpen(!megaOpen)} className="g-mm__icon g-mm__btn" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.4126 3.14258H0.785645" stroke="white" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
                                <path d="M21.2142 11H0.785645" stroke="white" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
                                <path d="M12.4126 18.8574H0.785645" stroke="white" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
                            </svg>
                            {/* <svg onClick={() => setMegaOpen(!megaOpen)} className="g-mm__icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.96875 17.1875C13.9556 17.1875 17.1875 13.9556 17.1875 9.96875C17.1875 5.98194 13.9556 2.75 9.96875 2.75C5.98194 2.75 2.75 5.98194 2.75 9.96875C2.75 13.9556 5.98194 17.1875 9.96875 17.1875Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15.0728 15.0742L19.2494 19.2508" stroke="white" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
                            </svg>                     */}
                            <div className="g-mega-menu__content g-dfw">
                                <div className="g-container">
                                    <div className="g-row g-jc-sb">
                                        <div className="g-mmc__col">
                                            <h4 className="g-mmcc_heading">Медиа</h4>
                                            <ul className="g-mmcc__links-list">
                                                <li>
                                                    <Link to="/gdu-media/news">Новости</Link>
                                                </li>
                                                <li>
                                                    <Link to="/gdu-media/print">Печатные издания</Link>
                                                </li>
                                                <li>
                                                    <Link to="/gdu-media/projects">Проекты профсоюза</Link>
                                                </li>
                                                <li>
                                                    <Link to="/gdu-media/materials">Фото/Видео материалы</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="g-mmc__col">
                                            <h4 className="g-mmcc_heading">Организация</h4>
                                            <ul className="g-mmcc__links-list">
                                                <li><Link to="/union">Об организации</Link></li>
                                                <li><Link to="/union">Руководство</Link></li>
                                                <li><Link to="/union">Организационная структура</Link></li>
                                                <li><Link to="/union">Комисcии Совета ОППО</Link></li>
                                                <li><Link to="/union/legal-work">Правовая работа</Link></li>
                                                <li><Link to="/union/job-safety">Охрана труда</Link></li>
                                            </ul>
                                        </div>
                                        <div className="g-mmc__col">
                                            <h4 className="g-mmcc_heading">Помощь</h4>
                                            <ul className="g-mmcc__links-list">
                                                {/* <li><Link to="/help-local-regs">Нормативные акты</Link></li> */}
                                                <li><Link to="/help">Последние вопросы</Link></li>
                                                <li><Link to="/help-book">Запись на прием</Link></li>
                                                <li><Link to="/help-ask-quetion">Задать вопрос</Link></li>
                                                <li><Link to="/contacts">Контакты</Link></li>
                                            </ul>
                                        </div>
                                        <div className="g-mmc__col">
                                            <h4 className="g-mmcc_heading">Полезное</h4>
                                            <ul className="g-mmcc__links-list">
                                                {/* <li><Link to="/useful">СК «Факел»</Link></li>
                                                <li><Link to="/useful/competition-projects">Конкурсные проекты</Link></li> */}
                                                <li><a target="_blank" href="http://discount-prof.ru/participants/">Профсоюзный дисконт</a></li>
                                                <li><a target="_blank" href="https://xn--80afnaylbafcido5b6k.xn--p1ai/partnery_privilegii">Газпром профсоюз «Привилегия»</a></li>
                                                <li><Link to="/">Проектный офис</Link></li>
                                            </ul>
                                        </div>
                                        <div className="g-mmc__col">
                                            <a className="g-mmcc__link">8 (3494) 94-09-88</a>
                                            <p className="g-mmcc__text">Горячаяя линия</p>
                                            <a className="g-mmcc__link">mail@oppogdu.ru</a>
                                            <p className="g-mmcc__text">Вопросы и предложения</p>
                                            <div className="g-mmcc__apps g-dfw g-jc-sb">
                                                <a href="#" className="g-mmcca__btn g-btn g-btn__transparent g-dfw">
                                                    <span>App Store</span>
                                                    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_14_38813)">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.99614 1.74133C10.3104 0.00919944 12.1371 0 12.1371 0C12.1371 0 12.4088 1.62864 11.1035 3.19715C9.70976 4.87277 8.12514 4.5981 8.12514 4.5981C8.12514 4.5981 7.8278 3.28126 8.99614 1.74133Z" fill="white"/>
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.29315 5.73807C8.96932 5.73807 10.2237 4.80859 11.857 4.80859C14.6681 4.80859 15.774 6.80915 15.774 6.80915C15.774 6.80915 13.6111 7.91473 13.6111 10.5984C13.6111 13.6253 16.3052 14.6685 16.3052 14.6685C16.3052 14.6685 14.4213 19.97 11.8773 19.97C10.7087 19.97 9.80023 19.1815 8.56914 19.1815C7.31439 19.1815 6.06918 19.9983 5.25831 19.9983C2.93511 19.9983 0.00146484 14.9694 0.00146484 10.9269C0.00146484 6.95142 2.48598 4.86346 4.81608 4.86346C6.32939 4.86445 7.50463 5.73807 8.29315 5.73807Z" fill="white"/>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_14_38813">
                                                                <rect width="16.3044" height="20" fill="white"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg> 
                                                </a>
                                                <a href="#" className="g-mmcca__btn g-btn g-btn__transparent g-dfw">
                                                    <span>Google Play</span>
                                                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_14_38808)">
                                                            <path d="M10.2144 10.0083C9.6526 10.7049 9.09698 11.3886 8.54305 12.0707C6.60148 14.4606 4.65897 16.8497 2.71552 19.2381C2.30766 19.7381 1.7481 19.96 1.11945 19.9982C0.729565 20.0223 0.438555 19.8297 0.249791 19.4892C0.0481054 19.1257 0.00147718 18.7285 0.00147718 18.3223C0.00147718 14.6055 0.00147718 10.8886 0.00147718 7.17179C0.00147718 5.33639 -0.00470287 3.50156 0.00821847 1.66617C0.0105005 1.35829 0.0587861 1.05247 0.151476 0.758865C0.350914 0.136393 0.811027 -0.107989 1.44361 0.0436963C1.99136 0.175719 2.45485 0.430214 2.82339 0.886394C5.21477 3.85194 7.61515 6.80999 10.0245 9.76055C10.0835 9.83302 10.1386 9.90887 10.2144 10.0083Z" fill="white"/>
                                                            <path d="M14.0766 12.8161L11.7935 10.0043L14.0738 7.19531C14.7912 7.61048 15.4957 8.01498 16.1968 8.42509C16.4957 8.59981 16.803 8.76498 17.0783 8.97172C17.921 9.60374 17.9171 10.3762 17.089 11.0318C17.0467 11.0689 17.0018 11.1029 16.9547 11.1335C16.003 11.6914 15.0508 12.2476 14.0766 12.8161Z" fill="white"/>
                                                            <path d="M5.57178 17.66L11.0055 10.9746C11.6763 11.8005 12.328 12.601 13.0032 13.4314L5.60717 17.701L5.57178 17.66Z" fill="white"/>
                                                            <path d="M5.60451 2.30664L13.0011 6.5763L11.0051 9.03305L5.5708 2.34765L5.60451 2.30664Z" fill="white"/>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_14_38808">
                                                                <rect width="17.7096" height="20" fill="white"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>                                    
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="g-mm__search g-dfw">
                                <label className="g-label">
                                    {/* <input type="text" name="" placeholder="Поиск по сайту" id="" /> */}
                                </label>
                                
                                {localStorage.getItem("token") ? (
                                    <Link to="/project-office" className="g-auth__item g-btn g-btn__white loged">Личный кабинет</Link>
                                ):(
                                    <Link to="/auth" className="g-btn g-btn__white g-dfw">
                                        <span>Войти в личный кабинет</span>
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                        </svg>                            
                                    </Link>
                                )}
                            </div>
                        </div>
                        <Link to="/" className="g-logo">
                            <img src="/assets/img/logo.png" alt="Logo" />
                        </Link> 
                        <div className="g-menu g-header__menu g-dfw">
                            <Link to="/gdu-media">Медиа</Link>
                            <Link to="/union">Профсоюз</Link>
                            <Link to="/gdu-media/projects">Проекты</Link>
                            {/* <Link to="/useful/competition-projects">Конкурсы</Link>
                            <Link to="/useful">Спорт</Link> */}
                            <Link to="/help">Помощь</Link>
                            <Link to="/contacts">Контакты</Link>
                        </div>
                        <div className="g-auth">
                            {localStorage.getItem("token") ? (
                                <Link to="/project-office" className="g-auth__item g-btn g-btn__white loged">Личный кабинет</Link>
                            ):(
                                <>
                                    {/* <Link to="/registration" className="g-auth__item g-btn g-btn__white">Вступить</Link> */}
                                    <Link to="/auth" className="g-auth__item g-btn g-btn__transparent">Войти</Link>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
            <nav className="g-nav mobile">
                <div className="g-container">
                    <div className="g-row g-alt-center g-jc-sb">
                        <Link to="/" className="g-logo">
                            <img src="/assets/img/logo.png" alt="Logo" />
                        </Link>
                        <div className={mobileOpen ? "g-m_overlay open" : "g-m_overlay"}></div>
                        <div className={mobileOpen ? "g-m_side-bar open" : "g-m_side-bar"}>
                            <h4 className="g-msb__heading">Медиа</h4>
                            <Link to="/gdu-media/news">Новости</Link>
                            <Link to="/gdu-media/print">Печатные издания</Link>
                            <Link to="/gdu-media/projects">Проекты профсоюза</Link>
                            <Link to="/gdu-media/materials">Видеозаписи</Link>
                            <Link className="last" to="/gdu-media/materials">Фотогалерея</Link>

                            <h4 className="g-msb__heading">Организация</h4>
                            <Link to="/union">Об организации</Link>
                            <Link to="/union">Руководство</Link>
                            <Link to="/union">Организационная структура</Link>
                            <Link to="/union">Комисcии Совета ОППО</Link>
                            <Link to="/union/legal-work">Правовая работа</Link>
                            <Link className="last" to="/union/occupational-safety-and-health">Охрана труда</Link>
                            
                            <h4 className="g-msb__heading">Помощь</h4>
                            {/* <Link to="/help-local-regs">Нормативные акты</Link> */}
                            <Link to="/help">Последние вопросы</Link>
                            <Link to="/help-book">Запись на прием</Link>
                            <Link to="/help-ask-quetion">Задать вопрос</Link>
                            <Link className="last" to="/contacts">Контакты</Link>

                            <h4 className="g-msb__heading">Полезное</h4>
                            {/* <Link to="/useful">СК «Факел»</Link>
                            <Link to="/useful/competition-projects">Конкурсные проекты</Link> */}
                            <Link to="/useful/gazprom-privilege">Профсоюзный дисконт</Link>
                            <Link to="/useful/gazprom-privilege">Газпром профсоюз «Привилегия»</Link>
                            <Link className="last" to="#">Проектный офис</Link>
                            <div className="g-auth">
                                {localStorage.getItem("token") ? (
                                    <Link to="/project-office" className="g-auth__item g-btn g-btn__white loged">Личный кабинет</Link>
                                ):(
                                    <>
                                        {/* <Link to="/registration" className="g-auth__item g-btn g-btn__white">Вступить</Link> */}
                                        <Link to="/auth" className="g-auth__item g-btn g-btn__transparent">Войти</Link>
                                    </>
                                )}
                            </div>
                        </div>
                        <button className="g-menu__collapse_btn" onClick={() => setMobileOpen(!mobileOpen)}>
                            <span className={mobileOpen ? "g-humburger active" : "g-humburger"} ></span>
                        </button>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;