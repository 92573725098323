import { gql, useQuery } from "@apollo/client";

const JobSafetyComissionsQuery = gql`{
    staffList(
      depType: job_safety
    ) {
      id
      firstName
      lastName
      middleName
      position {
        id
        name
      }
      dep {
        id
        type
        name
      }
      pic
    }
  }
  `

export function JobSafetyComissions() {
    let ft = useQuery(JobSafetyComissionsQuery)

    return (
        <>
            <h4 className="g-tab__heading">Комисcия по охране труда</h4>
            {ft.data?.staffList?.map(person => <div className="g-com__item g-dfw">
                <div className="g-comi__avatar">
                    <img src={"/media/"+person.pic} alt="" />
                </div>
                <div className="g-comi__wrap">
                    <h4>{person.lastName} {person.firstName} {person.middleName}</h4>
                    <p>{person.position?.name}</p>
                </div>
            </div>)}
        </>
    );
}
