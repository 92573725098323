import { gql, useQuery } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import { fmtDate } from "../../util";

const FETCH_QUERY = gql`
  query MyQuery ($id: ID!) {
    helpQuestion(id: $id) {
      answer
      answerAt
      body
      createdAt
    }
    helpQuestions(pagination: {offset: 0, limit: 7}) {
        answer
        answerAt
        id
        body
    }
    
  }
`

function QuetionToChairmanIn() {

    const {state} = useLocation()
    const {data} = useQuery(FETCH_QUERY, {variables: {id: state?.id}})

    return (
        <>
            <link rel="stylesheet" href="/assets/css/help__questions_to_the_chairman-in.css" />
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <a href="/" className="g-bc__item">главная</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <a href="/help" className="g-bc__item">помощь</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">вопрос</p>
                        </div>
                    </div>
                </div>
                
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-post__content">
                            <div className="g-post__data g-dfw g-alt-center">
                                <h2 className="g-post__title">{data?.helpQuestion.body}</h2>
                                <div className="g-pd__item g-dfw">Вопрос · {fmtDate(data?.helpQuestion.createdAt)}</div>
                            </div>
                            <div className="g-post__block">
                                <blockquote className="g-blockquote">
                                    <p className="g-bq__text">
                                        {data?.helpQuestion.answer}
                                    </p>
                                    <div className="g-bq__meta">
                                        <p>Председатель ОППО «Газпром добыча Уренгой профсоюз»</p>
                                        <h4>Иван Забаев</h4>
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                        <div className="g-post__sidebar">
                            <h2 className="g-post__sidebar__heading">Другие вопросы</h2>
                            {data?.helpQuestions.map(item => (
                                <Link to="/help-in" state={{id: item.id}} className="g-quetion g-dfw">
                                    <div className="g-btn g-dfw g-alt-center">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.3438 18.5619H4.09635C3.92162 18.5619 3.75403 18.4925 3.63047 18.3689C3.50691 18.2454 3.4375 18.0778 3.4375 17.903V10.6556C3.4375 9.61736 3.642 8.58927 4.03933 7.63004C4.43665 6.67081 5.01902 5.79923 5.75318 5.06507C6.48734 4.33091 7.35892 3.74854 8.31815 3.35122C9.27738 2.95389 10.3055 2.74939 11.3437 2.74939H11.3438C12.382 2.74939 13.4101 2.95389 14.3693 3.35122C15.3286 3.74854 16.2001 4.33091 16.9343 5.06508C17.6685 5.79924 18.2508 6.67082 18.6482 7.63005C19.0455 8.58928 19.25 9.61738 19.25 10.6556V10.6557C19.25 12.7525 18.417 14.7635 16.9343 16.2462C15.4516 17.7289 13.4406 18.5619 11.3438 18.5619Z" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.59375 9.62427H13.75" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M8.59375 12.3743H13.75" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    <h4>{item.body}</h4>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default QuetionToChairmanIn;