import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { initTabs } from "../tabs";
import { groupBy } from "../../util";

const FETCH_QUERY = gql`
  query ($depType: DepType, $pagination: OffsetPaginationInput) {
      headManagers {
        id
        pic
        firstName
        lastName
        middleName
        position {
          name
        }
      }
      orgs {
        id
        name
        logo
        desc
        relWorkerCount
      }
        staffList(depType: $depType, pagination: $pagination) {
            id
            firstName
            lastName
            middleName
            position {
                id
                name
            }
            dep {
                id
                name
            }
            pic
        }
      
  }`

function TradeUnion() {
    let loc = useLocation()
    useEffect(() => {
        initTabs(loc)
    })
    const { data } = useQuery(FETCH_QUERY, {variables: {depType: "oppo_comission", pagination: {offset: 0, limit: 70}}});
    const [orgsCn, setOrgsCn] = useState(6)

    let oppoGroups = groupBy(data?.staffList || [], (it )=> it.dep.name)

    return (
        <>
            <link rel="stylesheet" href="/assets/css/trade-union.css" />
            {/* <!-- Banner Section --> */}
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <a href="#" className="g-bc__item">главная</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                        <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                </svg>
                            </p>
                            <p className="g-bc__item">профсоюз</p>
                        </div>
                        <div className="g-banner__heading">
                            <h1>Профсоюз</h1>
                            <p>Объединенная первичная профсоюзная организация «Газпром добыча Уренгой профсоюз» - одна из крупнейших и старейших в «Газпром профсоюзе». В структуру Профсоюзной организации входят сегодня 19 первичных профсоюзных организаций, она объединяет собой более 12 тысяч сотрудников ООО «Газпром добыча Уренгой» - 99,7% от общей численности коллектива компании.</p>
                            <p>Мы ставим перед собой важные задачи по многим направлениям работы и планомерно реализуем их в рамках социального партнерства с Обществом «Газпром добыча Уренгой», а также органами власти и общественными организациями.</p>
                        </div>
                        <div className="g-banner__counters g-dfw">
                            <div className="g-counter g-dfw">
                                <div className="g-c__text-wrap g-text__wrap">
                                    <h3 className="g-section__tag white">Членов профсоюза</h3>
                                    <h2 className="g-c__title">> 12 000</h2>
                                </div>
                                {/* <svg className="g-counter__icon" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5001 30C21.8848 30 26.2501 25.6348 26.2501 20.25C26.2501 14.8652 21.8848 10.5 16.5001 10.5C11.1153 10.5 6.75006 14.8652 6.75006 20.25C6.75006 25.6348 11.1153 30 16.5001 30Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M29.1398 10.8632C30.4809 10.4853 31.8873 10.3992 33.2644 10.6107C34.6415 10.8222 35.9573 11.3264 37.1232 12.0892C38.289 12.852 39.2779 13.8558 40.0231 15.033C40.7684 16.2102 41.2527 17.5334 41.4435 18.9135C41.6343 20.2937 41.5271 21.6986 41.1292 23.0339C40.7313 24.3691 40.0518 25.6035 39.1367 26.654C38.2215 27.7046 37.0918 28.5468 35.8237 29.1239C34.5557 29.7011 33.1786 29.9998 31.7854 30" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M2.99927 37.0119C4.52202 34.8459 6.54357 33.0781 8.89324 31.8577C11.2429 30.6372 13.8517 30.0001 16.4994 30C19.1471 29.9999 21.756 30.6368 24.1058 31.8571C26.4555 33.0773 28.4772 34.845 30.0001 37.0108" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M31.7854 30C34.4333 29.9981 37.0428 30.6343 39.3927 31.8546C41.7427 33.075 43.764 34.8437 45.2855 37.0109" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg> */}
                            </div>
                            <div className="g-counter g-dfw">
                                {/* <div className="g-c__text-wrap g-text__wrap">
                                    <h3 className="g-section__tag white">проектов развития</h3>
                                    <h2 className="g-c__title">> 348</h2>
                                </div>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M40.5015 13.5H7.50146C6.67304 13.5 6.00146 14.1716 6.00146 15V39C6.00146 39.8284 6.67304 40.5 7.50146 40.5H40.5015C41.3299 40.5 42.0015 39.8284 42.0015 39V15C42.0015 14.1716 41.3299 13.5 40.5015 13.5Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M31.5 13.5V10.5C31.5 9.70435 31.1839 8.94129 30.6213 8.37868C30.0587 7.81607 29.2956 7.5 28.5 7.5H19.5C18.7044 7.5 17.9413 7.81607 17.3787 8.37868C16.8161 8.94129 16.5 9.70435 16.5 10.5V13.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M42.0015 23.6826C36.5308 26.8477 30.3203 28.5096 24 28.4997C17.6808 28.5096 11.4713 26.8483 6.00122 23.6842" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M21.75 22.5H26.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Fast Link Section --> */}
            <section className="g-section g-fast__links">
                <div className="g-container">
                    <div className="g-row">
                        <a href="#goals" className="g-fast__link g-dfw g-alt-center">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p>Цели</p>
                        </a>
                        <a href="#menegers" className="g-fast__link g-dfw g-alt-center">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p>руководство</p>
                        </a>
                        <a href="#history" className="g-fast__link g-dfw g-alt-center">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p>история</p>
                        </a>
                        <a href="#comission" className="g-fast__link g-dfw g-alt-center">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p>Комисcии совета ОППО</p>
                        </a>
                        <a href="#structure" className="g-fast__link g-dfw g-alt-center">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p>структура</p>
                        </a>
                        <a href="#occupational_safety" className="g-fast__link g-dfw g-alt-center">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p>охрана труда</p>
                        </a>
                        <a href="#occupational_safety1" className="g-fast__link g-dfw g-alt-center">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p>Правовая работа</p>
                        </a>
                    </div>
                </div>
            </section>
            {/* <!-- Goals Section --> */}
            <section id="goals" className="g-section g-goals">
                <div className="g-container">
                    <div className="g-row">
                        <h3 className="g-section__tag">Цели</h3>
                        <h2 className="g-section__heading">Мы ставим перед собой высокие цели</h2>
                    </div>
                    <div className="g-row">
                        <div className="g-goal__col">
                            <div className="g-gc__header g-dfw g-alt-center g-jc-sb">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.375 25V11.25C9.375 10.7527 9.57254 10.2758 9.92418 9.92418C10.2758 9.57254 10.7527 9.375 11.25 9.375H48.75C49.2473 9.375 49.7242 9.57254 50.0758 9.92418C50.4275 10.2758 50.625 10.7527 50.625 11.25V25C50.625 44.6911 33.9127 51.2149 30.5757 52.3212C30.2026 52.4496 29.7974 52.4496 29.4243 52.3212C26.0873 51.2149 9.375 44.6911 9.375 25Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M47.4379 38.4567L30 26.25L12.5618 38.4567" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <h4>01</h4>
                            </div>
                            <h2 className="g-gc__heading">Защита интересов </h2>
                            <p className="g-gc__text">
                                Профсоюзная организация представляет и защищает профессиональные и социально-трудовые права и интересы членов Профсоюза,  и это главная уставная цель нашей профсоюзной организации. Главной задачей также является создание благоприятных 
                                условий для повышения жизненного уровня членов Профсоюза и их семей.
                            </p>
                            <Link to="interests-protection" className="g-cl__link" >Читать полностью</Link>
                        </div>
                        <div className="g-goal__col">
                            <div className="g-gc__header g-dfw g-alt-center g-jc-sb">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30 13.123C11.25 13.123 3.75 29.9999 3.75 29.9999C3.75 29.9999 11.25 46.873 30 46.873C48.75 46.873 56.25 29.9999 56.25 29.9999C56.25 29.9999 48.75 13.123 30 13.123Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M30 39.375C35.1777 39.375 39.375 35.1777 39.375 30C39.375 24.8223 35.1777 20.625 30 20.625C24.8223 20.625 20.625 24.8223 20.625 30C20.625 35.1777 24.8223 39.375 30 39.375Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <h4>02</h4>
                            </div>
                            <h2 className="g-gc__heading">Охрана труда </h2>
                            <p className="g-gc__text">
                            Мы уделяем большое внимание направлению охраны труда. В составе структурных подразделений профсоюзной организации на общественных началах работают уполномоченные профсоюза по охране труда, Комиссия по охране труда, члены которой принимают активное участие в проведении АПК 5 уровня. 
                            В целях стимулирования работы уполномоченных...
                            </p>
                            <Link to="job-safety-inner" className="g-cl__link">Читать полностью</Link>
                        </div>
                        <div className="g-goal__col">
                            <div className="g-gc__header g-dfw g-alt-center g-jc-sb">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.5 30H16.875L20.625 24.375L28.125 35.625L31.875 30H37.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.58928 22.4985C6.57155 22.1876 6.56262 21.8756 6.5625 21.5625C6.56298 18.7456 7.53903 16.0159 9.32471 13.8373C11.1104 11.6588 13.5955 10.1659 16.3575 9.61254C19.1195 9.05917 21.9879 9.47947 24.475 10.802C26.9621 12.1245 28.9144 14.2675 30 16.8668L30 16.8668C31.0856 14.2676 33.0379 12.1245 35.525 10.802C38.0121 9.47948 40.8805 9.05918 43.6425 9.61254C46.4045 10.1659 48.8896 11.6588 50.6753 13.8373C52.461 16.0159 53.437 18.7456 53.4375 21.5625C53.4375 37.5 30 50.625 30 50.625C30 50.625 20.638 45.3823 13.7478 37.5003" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <h4>03</h4>
                            </div>
                            <h2 className="g-gc__heading">Обучение</h2>
                            <p className="g-gc__text">
                            Мы обучаем наш актив, применяя для этого различные формы и форматы обучения. Для профактива и активных членов «первичек» в целях формирования резерва профсоюзных кадров в ОППО работает уникальная модульная программа обучения профактива «Менеджмент в профсоюзной организации». 
                            Актив изучает основы профсоюзной работы в областях охраны труда...
                            </p>
                            <Link to="education" className="g-cl__link">Читать полностью</Link>
                        </div>
                        <div className="g-goal__col">
                            <div className="g-gc__header g-dfw g-alt-center g-jc-sb">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.625 13.125H15C13.9645 13.125 13.125 13.9645 13.125 15V45C13.125 46.0355 13.9645 46.875 15 46.875H20.625C21.6605 46.875 22.5 46.0355 22.5 45V15C22.5 13.9645 21.6605 13.125 20.625 13.125Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M45 13.125H39.375C38.3395 13.125 37.5 13.9645 37.5 15V45C37.5 46.0355 38.3395 46.875 39.375 46.875H45C46.0355 46.875 46.875 46.0355 46.875 45V15C46.875 13.9645 46.0355 13.125 45 13.125Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M46.875 18.75H52.5C52.9973 18.75 53.4742 18.9475 53.8258 19.2992C54.1775 19.6508 54.375 20.1277 54.375 20.625V39.375C54.375 39.8723 54.1775 40.3492 53.8258 40.7008C53.4742 41.0525 52.9973 41.25 52.5 41.25H46.875" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M13.125 41.25H7.5C7.00272 41.25 6.52581 41.0525 6.17417 40.7008C5.82254 40.3492 5.625 39.8723 5.625 39.375V20.625C5.625 20.1277 5.82254 19.6508 6.17417 19.2992C6.52581 18.9475 7.00272 18.75 7.5 18.75H13.125" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M22.5 30H37.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M54.375 30H58.125" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1.875 30H5.625" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <h4>04</h4>
                            </div>
                            <h2 className="g-gc__heading">Спорт</h2>
                            <p className="g-gc__text">
                            Мы понимаем, как важен для любого человека спорт, поэтому видим одной из своих задач популяризацию здорового образа жизни в коллективе. Профсоюз создаёт условия для членов профсоюза в развитии их спортивных навыков, оснащая современным инвентарем спортивные залы Общества и участвуя в организации соревнований...
                            </p>
                            <Link to="sport" className="g-cl__link">Читать полностью</Link>
                        </div>
                        <div className="g-goal__col">
                            <div className="g-gc__header g-dfw g-alt-center g-jc-sb">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M50.6267 16.875H9.37671C8.34118 16.875 7.50171 17.7145 7.50171 18.75V48.75C7.50171 49.7855 8.34118 50.625 9.37671 50.625H50.6267C51.6622 50.625 52.5017 49.7855 52.5017 48.75V18.75C52.5017 17.7145 51.6622 16.875 50.6267 16.875Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M39.375 16.875V13.125C39.375 12.1304 38.9799 11.1766 38.2766 10.4733C37.5734 9.77009 36.6196 9.375 35.625 9.375H24.375C23.3804 9.375 22.4266 9.77009 21.7234 10.4733C21.0201 11.1766 20.625 12.1304 20.625 13.125V16.875" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M52.5018 29.6035C45.6634 33.5599 37.9003 35.6372 29.9999 35.6248C22.1009 35.6372 14.339 33.5606 7.50146 29.6055" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M27.1875 28.125H32.8125" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <h4>05</h4>
                            </div>
                            <h2 className="g-gc__heading">Программы </h2>
                            <p className="g-gc__text">
                            Мы поддерживаем проектные инициативы членов Профсоюза. Любой сотрудник предприятия - член профсоюза начиная с рабочего и заканчивая руководителем высшего звена - может прийти к нам со своей инициативой и мы поможем реализовать его идею по улучшению жизни в компании, сделав ее интересней и насыщеннее.  
                            «Газпром добыча Уренгой профсоюз» - одна из...
                            </p>
                            <Link to="programs" className="g-cl__link">Читать полностью</Link>
                        </div>
                        <div className="g-goal__col">
                            <div className="g-gc__header g-dfw g-alt-center g-jc-sb">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31.0338 44.703L42.8547 52.1921C44.3657 53.1494 46.2417 51.7257 45.7933 49.9619L42.378 36.5269C42.2818 36.1527 42.2932 35.759 42.4109 35.391C42.5285 35.023 42.7476 34.6957 43.043 34.4467L53.6432 25.624C55.036 24.4647 54.3171 22.1532 52.5277 22.037L38.6846 21.1386C38.3117 21.112 37.9541 20.98 37.6533 20.7579C37.3526 20.5359 37.1211 20.233 36.9858 19.8846L31.8229 6.88307C31.6826 6.51319 31.4331 6.19476 31.1075 5.97005C30.7819 5.74534 30.3956 5.625 30 5.625C29.6044 5.625 29.2181 5.74534 28.8926 5.97005C28.567 6.19476 28.3174 6.51319 28.1771 6.88307L23.0142 19.8846C22.8789 20.233 22.6474 20.5359 22.3467 20.7579C22.0459 20.98 21.6883 21.112 21.3155 21.1386L7.47235 22.037C5.68288 22.1532 4.96399 24.4647 6.35681 25.624L16.957 34.4467C17.2524 34.6957 17.4715 35.023 17.5891 35.391C17.7068 35.759 17.7182 36.1527 17.622 36.5269L14.4547 48.9863C13.9167 51.1029 16.1679 52.8114 17.9811 51.6627L28.9662 44.703C29.2751 44.5063 29.6338 44.4018 30 44.4018C30.3662 44.4018 30.7249 44.5063 31.0338 44.703V44.703Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <h4>06</h4>
                            </div>
                            <h2 className="g-gc__heading">Патриотизм </h2>
                            <p className="g-gc__text">
                            Мы развиваем социальную и культурную составляющую профсоюзной работы. Творческие конкурсы, волонтерское движение, массовые спортивные марафоны, чемпионаты по интеллектуальным играм – за каждым из этих направлений большое количество членов профсоюза и их семей. 
Патриотическое воспитание и поддержание интереса к истории...
                            </p>
                            <Link to="patriot" className="g-cl__link">Читать полностью</Link>
                        </div>
                        <div className="g-goal__col">
                            <div className="g-gc__header g-dfw g-alt-center g-jc-sb">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.625 37.5C27.356 37.5 32.8125 32.0435 32.8125 25.3125C32.8125 18.5815 27.356 13.125 20.625 13.125C13.894 13.125 8.4375 18.5815 8.4375 25.3125C8.4375 32.0435 13.894 37.5 20.625 37.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M36.4248 13.5788C38.1011 13.1065 39.8591 12.999 41.5805 13.2633C43.3019 13.5277 44.9467 14.1578 46.404 15.1114C47.8613 16.0649 49.0974 17.3197 50.0289 18.7912C50.9605 20.2626 51.5659 21.9166 51.8044 23.6418C52.0429 25.367 51.9089 27.1232 51.4115 28.7922C50.9141 30.4612 50.0648 32.0043 48.9209 33.3174C47.7769 34.6306 46.3648 35.6833 44.7797 36.4048C43.1946 37.1263 41.4733 37.4997 39.7317 37.4999" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M3.74902 46.2648C5.65246 43.5573 8.1794 41.3476 11.1165 39.8221C14.0536 38.2966 17.3146 37.5001 20.6242 37.5C23.9338 37.4999 27.1949 38.2961 30.1321 39.8213C33.0693 41.3466 35.5964 43.5562 37.5001 46.2636" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M39.7317 37.5C43.0416 37.4976 46.3034 38.2928 49.2408 39.8183C52.1783 41.3438 54.7049 43.5546 56.6068 46.2636" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                                <h4>07</h4>
                            </div>
                            <h2 className="g-gc__heading">Молодежь</h2>
                            <p className="g-gc__text">
                            Стратегически важное и перспективное направление деятельности профсоюза – работа с молодёжью. Мы понимаем, что именно молодые работники – это будущее компании, ее социальное и производственное развитие, поэтому совместно с Обществом всемерно способствуем адаптации молодых специалистов в коллективах  компании, помогаем...
                            </p>
                            <Link to="teens" className="g-cl__link">Читать полностью</Link>
                        </div>
                        <div className="g-goal__col">
                            <div className="g-gc__header g-dfw g-alt-center g-jc-sb">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30 30L52.5 7.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M45.9099 14.0899C41.9242 10.1121 36.5908 7.77518 30.9647 7.54137C25.3385 7.30757 19.8296 9.19395 15.5276 12.8273C11.2257 16.4607 8.44417 21.5764 7.73334 27.1623C7.02252 32.7483 8.43417 38.3976 11.689 42.9926C14.9439 47.5876 19.8048 50.7935 25.3102 51.9762C30.8156 53.1589 36.5644 52.2322 41.4192 49.3794C46.2741 46.5266 49.8813 41.9555 51.5274 36.5705C53.1734 31.1855 52.7383 25.3788 50.3082 20.2991" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M37.955 22.0453C36.0951 20.1855 33.6423 19.0361 31.0229 18.7971C28.4036 18.558 25.7832 19.2443 23.6174 20.7367C21.4515 22.2292 19.877 24.4333 19.1676 26.9661C18.4582 29.4989 18.6587 32.2002 19.7343 34.6005C20.8099 37.0008 22.6925 38.9483 25.055 40.1046C27.4174 41.2609 30.1104 41.5528 32.6658 40.9297C35.2211 40.3065 37.4774 38.8077 39.0423 36.6936C40.6073 34.5796 41.382 31.984 41.2319 29.358" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                                <h4>08</h4>
                            </div>
                            <h2 className="g-gc__heading">Развитие</h2>
                            <p className="g-gc__text">
                            Мы не останавливаемся на достигнутом. В истории ОППО «Газпром добыча Уренгой профсоюз» много славных страниц, о которых сегодня с чувством большой профессиональной и личной гордости вспоминают ветераны, а молодежь стремится продолжить их дело и стать достойными преемниками знаний и опыта старших поколений...
                            </p>
                            <Link to="development" className="g-cl__link">Читать полностью</Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Menegers Section --> */}
            <section id="menegers" className="g-section g-menegers__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-manegment g-w40p">
                            <h3 className="g-section__tag">Руководство</h3>
                            {/* <h2 className="g-section__heading">Обеспечивая выполнение целей организации</h2> */}
                        </div>
                        <div className="g-manegers g-dfw">
                            {data?.headManagers.map(item => (
                                <div className="g-maneger g-dfw" key={item.id}>
                                    <div className="g-mg__img">
                                        <img src={'/media/' + item.pic} />
                                    </div>
                                    <div className="g-mg__text-wrap">
                                        <h4 className="g-mgtw__heading">{item.lastName} <br /> {item.firstName} {item.middleName}</h4>
                                        <p className="g-mgtw__text">{item.position.name}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Histary Section --> */}
            {/* <section id="history" className="g-section g-history__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-w40p">
                            <h3 className="g-section__tag">история</h3>
                            <h2 className="g-section__heading">Богатый опыт</h2>
                            <p className="g-section__text">
                                В истории ОППО «Газпром добыча Уренгой профсоюз» есть немало славных страниц, о которых ветераны вспоминают с чувством большой профессиональной и личной гордости. Молодежь стремится продолжить дело, начатое первопроходцами, стать достойными преемниками знаний и опыта старших поколений, приумножить их достижения. Профсоюзная организация способствует укреплению корпоративного единства и духа, основанных на богатых трудовых традициях многих поколений газовиков.
                            </p>
                        </div>
                        <div className="g-slider g-history__slider swiper">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide g-his__slide">
                                    <div className="g-his__block video">
                                        <img src="../assets/img/trade-union/his.png" alt="" />
                                        <div className="g-btn g-btn__transparent rounded g-dfw g-jc-center g-alt-center">
                                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M40.1244 21.7872L14.9984 6.16254C14.7868 6.03094 14.5445 5.95908 14.2965 5.95435C14.0485 5.94963 13.8038 6.01221 13.5875 6.13566C13.3711 6.25911 13.191 6.43897 13.0657 6.65674C12.9403 6.87451 12.8743 7.12233 12.8743 7.37471V38.624C12.8743 38.8764 12.9403 39.1242 13.0657 39.342C13.191 39.5598 13.3711 39.7396 13.5875 39.8631C13.8038 39.9865 14.0485 40.0491 14.2965 40.0444C14.5445 40.0397 14.7868 39.9678 14.9984 39.8362L40.1244 24.2115C40.3285 24.0846 40.4971 23.9065 40.6141 23.6943C40.731 23.482 40.7925 23.2427 40.7925 22.9994C40.7925 22.756 40.731 22.5167 40.6141 22.3045C40.4971 22.0922 40.3285 21.9141 40.1244 21.7872Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="g-his__block g-dfw">
                                        <img src="../assets/img/trade-union/his1.png" alt="" />
                                        <div className="g-active-project__slider__navigation g-slider__navigation g-dfw">
                                            <div className="g-bsn__btn prev">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.25 14.625L5.625 9L11.25 3.375" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="g-bsn__btn next">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.75 3.375L12.375 9L6.75 14.625" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- Commission Section --> */}
            <section id="comission" className="g-section g-commission__section">
                <div className="g-container">
                    <div className="g-row">
                        <h3 className="g-section__tag">комиссия</h3>
                        <h2 className="g-section__heading">Комисcии совета ОППО</h2>
                    </div>
                    <div className="g-row g-tabination">
                        <div className="g-tab_navigation reset g-w40p">
                            {Object.entries(oppoGroups).map(([name, items])=> <h2 className="g-tn__item reset g-mgtw__heading">{name}</h2>)}

                        </div>
                        {Object.entries(oppoGroups).map(([name, items])=> <div className="g-tab reset g-w60p g-dfw g-jc-sb">
                            {items.map(person => <div className="g-com__item g-dfw">
                                <div className="g-comi__avatar">
                                    {person.pic? <img src={"/media/"+person.pic} alt="" />: null}
                                </div>
                                <div className="g-comi__wrap">
                                    <h4>{person.lastName} {person.firstName} {person.middleName}</h4>
                                    <p>{person.position.name}</p>
                                </div>
                            </div>)}
                        </div>)}
                    </div>
                </div>
            </section>
            {/* <!-- Structure Section --> */}
            <section id="structure" className="g-section g-structure__section">
                <div className="g-container">
                    <div className="g-row">
                        <h3 className="g-section__tag">Структура</h3>
                        <h2 className="g-section__heading">Организационная структура</h2>
                    </div>
                    <div className="g-row">
                        {data?.orgs.slice(0, orgsCn).map(item => (
                            <Link to={"/union/org/"+item.id} className="g-structure__card">
                                <div className="g-sc__logo">
                                    <img src={'/media/' + item.logo} alt="" />
                                </div>
                                {/* <p className="g-sc__text">{item.desc.slice(0, 100)}...</p> */}
                                {/* <p className="g-sc__views">{item.relWorkerCount} человек</p> */}
                                <br/>
                                <div className="g-bage gray">{item.name}</div>
                            </Link>
                        ))}
                        {orgsCn < data?.orgs?.length ? <div className="g-pagination g-dfw g-alt-center">
                            <button onClick={() => setOrgsCn(orgsCn + 6)} className="g-btn g-btn__gray g-dfw g-alt-center">
                                <span>Загрузить больше</span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.86066 8.57031H2.73566V4.44531" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5.65253 16.3475C6.71017 17.4051 8.05768 18.1254 9.52466 18.4172C10.9916 18.709 12.5122 18.5592 13.8941 17.9868C15.2759 17.4145 16.457 16.4452 17.288 15.2015C18.119 13.9579 18.5625 12.4957 18.5625 11C18.5625 9.50428 18.119 8.04215 17.288 6.7985C16.457 5.55486 15.2759 4.58555 13.8941 4.01316C12.5122 3.44078 10.9916 3.29101 9.52466 3.58281C8.05768 3.87462 6.71017 4.59487 5.65253 5.65251L2.73572 8.56932" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        </div> : null}
                    </div>
                </div>
            </section>
            {/* <!-- Occupational Safety and Health Section --> */}
            <section id="occupational_safety" className="g-section g-osah-section">
                <div className="g-container">
                    <div className="g-row">
                        <h3 className="g-section__tag">охрана труда</h3>
                        <h2 className="g-section__heading">Охрана труда</h2>
                    </div>
                    <div className="g-row">
                        <div className="g-osah__card img g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <h4 className="g-osahc__heading">Комиссия по охране труда</h4>
                                <Link to="/union/job-safety" className="g-osahc__link">смотреть</Link>
                            </div>
                            <div className="g-osahc__icon">
                                <img src="../assets/img/trade-union/00923.png" alt="" />
                            </div>
                        </div>
                        <div className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <h4 className="g-osahc__heading">Цели, задачи и руководство</h4>
                                <Link to="/union/job-safety#tasks" className="g-osahc__link">смотреть</Link>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.375 25V11.25C9.375 10.7527 9.57254 10.2758 9.92418 9.92418C10.2758 9.57254 10.7527 9.375 11.25 9.375H48.75C49.2473 9.375 49.7242 9.57254 50.0758 9.92418C50.4275 10.2758 50.625 10.7527 50.625 11.25V25C50.625 44.6911 33.9127 51.2149 30.5757 52.3212C30.2026 52.4496 29.7974 52.4496 29.4243 52.3212C26.0873 51.2149 9.375 44.6911 9.375 25Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M47.4379 38.4567L30 26.25L12.5618 38.4567" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <h4 className="g-osahc__heading">Нормативные документы по охране труда</h4>
                                <Link to="/union/job-safety#docs" className="g-osahc__link">смотреть</Link>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.5 22.5H37.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M22.5 30H37.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M22.5 37.5H30" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M36.7234 50.625H11.25C10.7527 50.625 10.2758 50.4275 9.92418 50.0758C9.57254 49.7242 9.375 49.2473 9.375 48.75V11.25C9.375 10.7527 9.57254 10.2758 9.92418 9.92418C10.2758 9.57254 10.7527 9.375 11.25 9.375H48.75C49.2473 9.375 49.7242 9.57254 50.0758 9.92418C50.4275 10.2758 50.625 10.7527 50.625 11.25V36.7234C50.625 36.9696 50.5765 37.2134 50.4823 37.4409C50.388 37.6684 50.2499 37.8751 50.0758 38.0492L38.0492 50.0758C37.8751 50.2499 37.6684 50.388 37.4409 50.4823C37.2134 50.5765 36.9696 50.625 36.7234 50.625V50.625Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M50.4555 37.498H37.5V50.4543" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Occupational Safety and Health Section --> */}
            <section id="occupational_safety1" className="g-section g-osah-section">
                <div className="g-container">
                    <div className="g-row">
                        <h3 className="g-section__tag">Правовая работа</h3>
                        <h2 className="g-section__heading">Правовая работа</h2>
                    </div>
                    <div className="g-row">
                        <div className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <h4 className="g-osahc__heading">Цели, задачи и руководство</h4>
                                <Link to="/union/legal-work#tasks" className="g-osahc__link">смотреть</Link>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.375 25V11.25C9.375 10.7527 9.57254 10.2758 9.92418 9.92418C10.2758 9.57254 10.7527 9.375 11.25 9.375H48.75C49.2473 9.375 49.7242 9.57254 50.0758 9.92418C50.4275 10.2758 50.625 10.7527 50.625 11.25V25C50.625 44.6911 33.9127 51.2149 30.5757 52.3212C30.2026 52.4496 29.7974 52.4496 29.4243 52.3212C26.0873 51.2149 9.375 44.6911 9.375 25Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M47.4379 38.4567L30 26.25L12.5618 38.4567" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <h4 className="g-osahc__heading">Нормативные документы по правовой работе</h4>
                                <Link to="/union/legal-work#docs" className="g-osahc__link">смотреть</Link>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.5 22.5H37.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M22.5 30H37.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M22.5 37.5H30" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M36.7234 50.625H11.25C10.7527 50.625 10.2758 50.4275 9.92418 50.0758C9.57254 49.7242 9.375 49.2473 9.375 48.75V11.25C9.375 10.7527 9.57254 10.2758 9.92418 9.92418C10.2758 9.57254 10.7527 9.375 11.25 9.375H48.75C49.2473 9.375 49.7242 9.57254 50.0758 9.92418C50.4275 10.2758 50.625 10.7527 50.625 11.25V36.7234C50.625 36.9696 50.5765 37.2134 50.4823 37.4409C50.388 37.6684 50.2499 37.8751 50.0758 38.0492L38.0492 50.0758C37.8751 50.2499 37.6684 50.388 37.4409 50.4823C37.2134 50.5765 36.9696 50.625 36.7234 50.625V50.625Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M50.4555 37.498H37.5V50.4543" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <h4 className="g-osahc__heading">Частые вопросы</h4>
                                <Link to="/union/legal-work#faq" className="g-osahc__link">смотреть</Link>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30 52.5C42.4264 52.5 52.5 42.4264 52.5 30C52.5 17.5736 42.4264 7.5 30 7.5C17.5736 7.5 7.5 17.5736 7.5 30C7.5 42.4264 17.5736 52.5 30 52.5Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M30 44.0625C31.0355 44.0625 31.875 43.223 31.875 42.1875C31.875 41.152 31.0355 40.3125 30 40.3125C28.9645 40.3125 28.125 41.152 28.125 42.1875C28.125 43.223 28.9645 44.0625 30 44.0625Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M30 33.751V31.876C31.2979 31.876 32.5667 31.4911 33.6459 30.77C34.7251 30.0489 35.5663 29.024 36.063 27.8248C36.5597 26.6257 36.6896 25.3062 36.4364 24.0332C36.1832 22.7602 35.5582 21.5909 34.6404 20.6731C33.7226 19.7553 32.5533 19.1303 31.2803 18.8771C30.0073 18.6239 28.6878 18.7538 27.4886 19.2505C26.2895 19.7472 25.2646 20.5884 24.5435 21.6675C23.8224 22.7467 23.4375 24.0155 23.4375 25.3135" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TradeUnion;