import { useEffect, useState } from "react"

export function TabItem({item, activeTab, onClick, renderItem}) {
    return <div onClick={onClick} className={"g-tn__item__sl g-dfw " + (item.id == activeTab?.id? "active": "")}>
            {renderItem({item})}
        </div>
}

export function SlTabs({tabItems, renderTab, renderItem}) {
    let [tab, setTab] = useState(tabItems[0])

    useEffect(()=> {
        if (tabItems && tabItems.length && !tab) {
            setTab(tabItems[0])
        }
    }, [tabItems])

    return <div className="g-tabination__sl media g-dfw">
    <div className="g-tab_navigation__sl">
        {tabItems?.map(item => <TabItem key={item.id} activeTab={tab} item={item} onClick={()=> setTab(item)} renderItem={renderItem} />)}
    </div>
    <div className="g-tab__sl g-dfw active">
        {tab? renderTab({tab}): null}
    </div>
</div>
}
