import { Link } from "react-router-dom";
function Favorites() {
    return (
        <>
            <link rel="stylesheet" href="/assets/css/media/projects.css"/>
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                    <div className="g-bread__crumbs g-dfw">
                            <Link to="/" className="g-bc__item">главная</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <Link to="/gdu-media" className="g-bc__item">медиа</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">избранное</p>
                        </div>
                    </div>
                </div>
                
            </section>
            {/* <!-- Favorite Projects Section --> */}
            <section className="g-section g-active__projects">
                <div className="g-container">
                    <div className="g-row">
                        <h2 className="g-section__heading">Избранное</h2>
                        <div className="g-section__actions g-tab__nav g-dfw">
                            <button className="g-tn__item g-btn reset g-btn__black">Все</button>
                            <button className="g-tn__item g-btn reset g-btn__transparent-gray">События</button>
                            <button className="g-tn__item g-btn reset g-btn__transparent-gray">Мероприятия</button>
                            <button className="g-tn__item g-btn reset g-btn__transparent-gray">Экология</button>
                            <button className="g-tn__item g-btn reset g-btn__transparent-gray">Назначения</button>
                        </div>
                    </div>
                    <div className="g-row g-jc-sb">
                        <div className="g-post g-dfw">
                            <div className="g-post__banner">
                                <img src="/assets/img/home/ap__1.png" alt=""/>
                                <div className="g-post__meta g-bage blue">17 августа · 2022</div>
                            </div>
                            <div className="g-post_excerpt g-dfw">
                                <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>    
                                </div>
                                <div className="g-pe__wrap">
                                    <h2 className="g-pe__title">Ямальский марафон 2022</h2>
                                    <p className="g-pe__text">Проект · 10 июля 2022</p>
                                </div>
                            </div>
                        </div>
                        <div className="g-post g-dfw">
                            <div className="g-post__banner">
                                <img src="/assets/img/home/ap__2.png" alt=""/>
                                <div className="g-post__meta g-bage blue">17 августа · 2022</div>
                            </div>
                            <div className="g-post_excerpt g-dfw">
                                <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>    
                                </div>
                                <div className="g-pe__wrap">
                                    <h2 className="g-pe__title">Ямальский марафон 2022</h2>
                                    <p className="g-pe__text">Проект · 10 июля 2022</p>
                                </div>
                            </div>
                        </div>
                        <div className="g-post g-dfw">
                            <div className="g-post__banner">
                                <img src="/assets/img/home/ap__3.png" alt=""/>
                                <div className="g-post__meta g-bage blue">17 августа · 2022</div>
                            </div>
                            <div className="g-post_excerpt g-dfw">
                                <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>    
                                </div>
                                <div className="g-pe__wrap">
                                    <h2 className="g-pe__title">Ямальский марафон 2022</h2>
                                    <p className="g-pe__text">Проект · 10 июля 2022</p>
                                </div>
                            </div>
                        </div>
                        <div className="g-post g-dfw">
                            <div className="g-post__banner">
                                <img src="/assets/img/home/ap__1.png" alt=""/>
                                <div className="g-post__meta g-bage blue">17 августа · 2022</div>
                            </div>
                            <div className="g-post_excerpt g-dfw">
                                <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>    
                                </div>
                                <div className="g-pe__wrap">
                                    <h2 className="g-pe__title">Ямальский марафон 2022</h2>
                                    <p className="g-pe__text">Проект · 10 июля 2022</p>
                                </div>
                            </div>
                        </div>
                        <div className="g-post g-dfw">
                            <div className="g-post__banner">
                                <img src="/assets/img/home/ap__2.png" alt=""/>
                                <div className="g-post__meta g-bage blue">17 августа · 2022</div>
                            </div>
                            <div className="g-post_excerpt g-dfw">
                                <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>    
                                </div>
                                <div className="g-pe__wrap">
                                    <h2 className="g-pe__title">Ямальский марафон 2022</h2>
                                    <p className="g-pe__text">Проект · 10 июля 2022</p>
                                </div>
                            </div>
                        </div>
                        <div className="g-post g-dfw">
                            <div className="g-post__banner">
                                <img src="/assets/img/home/ap__3.png" alt=""/>
                                <div className="g-post__meta g-bage blue">17 августа · 2022</div>
                            </div>
                            <div className="g-post_excerpt g-dfw">
                                <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>    
                                </div>
                                <div className="g-pe__wrap">
                                    <h2 className="g-pe__title">Ямальский марафон 2022</h2>
                                    <p className="g-pe__text">Проект · 10 июля 2022</p>
                                </div>
                            </div>
                        </div>
                        <div className="g-pagination g-dfw g-alt-center">
                            <button className="g-btn g-btn__gray g-dfw g-alt-center">
                                <span>Загрузить больше</span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.86066 8.57031H2.73566V4.44531" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.65253 16.3475C6.71017 17.4051 8.05768 18.1254 9.52466 18.4172C10.9916 18.709 12.5122 18.5592 13.8941 17.9868C15.2759 17.4145 16.457 16.4452 17.288 15.2015C18.119 13.9579 18.5625 12.4957 18.5625 11C18.5625 9.50428 18.119 8.04215 17.288 6.7985C16.457 5.55486 15.2759 4.58555 13.8941 4.01316C12.5122 3.44078 10.9916 3.29101 9.52466 3.58281C8.05768 3.87462 6.71017 4.59487 5.65253 5.65251L2.73572 8.56932" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                            
                            </button>
                            <div className="g-pages__list g-dfw">
                                <a href="#" className="g-pl__item active">1</a>
                                <a href="#" className="g-pl__item">2</a>
                                <a href="#" className="g-pl__item">3</a>
                                <a href="#" className="g-pl__item">4</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Favorites;