
export const groupBy = function (xs, fn) {
    return xs.reduce(function (rv, x) {
        let grouper = fn(x);
        (rv[grouper] = rv[grouper] || []).push(x);
        return rv;
    }, {});
};

export function openDialog(name) {
    let popUps = document.querySelectorAll('.g-pop_up');
    let elem = Array.from(popUps).find(it => it.dataset.target == name)
    if (elem) {
        elem.classList.add('active')
    }
}

export function toggleInList(alist, item) {
    console.log('toggleInList')
    console.log(alist.map(it => it.id))
    let existing = alist.findIndex(it => it.id == item.id)
    if (existing > -1) {
        alist.splice(existing, 1)
    } else {
        alist.push(item)
    }
    console.log(alist.map(it => it.id))
    return [...alist]
}

export function pick(obj, keys) {
    const ret = {};
    for (const key of keys) {
      ret[key] = obj[key];
    }
    return ret;
  }

export const chunkIntoN = (arr, n) => {
    const size = Math.ceil(arr.length / n);
    return Array.from({ length: n }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  }

export const monthes = ['Январь', 'февраль', 'Март', 'Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];

export const fmtDate = (t)=> {
    if (!t) return ""
    let d = new Date(t)
    return `${d.getDate()} ${monthes[d.getMonth()]} · ${d.getFullYear()}`
}

export const fmtDatetime = t => {
    if (!t) return ""
    let d = new Date(t)
    return `${d.getDate()} ${monthes[d.getMonth()].slice(0, 3)}, ${d.getUTCHours()}:${d.getUTCMinutes() <= 9 ? '0' + d.getUTCMinutes() : d.getUTCMinutes()}`
}