import { gql, useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

const FETCH_QUERY = gql`
mutation MyMutation($email: String!, $verificationCode: String!) {
    activate(email: $email, verificationCode: $verificationCode) {
      ok
      errors
      user {
        firstName
        lastName
        middleName
        email
        phone
        pic
        unionCardNum
        dep {
          name
          type
        }
        position {
          id
          name
        }
      }
    }
  }
`

function AccountActivation() {
    const [activateAccount, {data}] = useMutation(FETCH_QUERY)
    const {state} = useLocation()
    const navigation = useNavigate()
    
    activateAccount({variables: {email: state.email, verificationCode: ''}}).then(res => res?.data.activate.ok ? navigation('/auth') : () => {
        alert(res?.data.activate.errors[0])
        navigation('/registration')
    })
}
export default AccountActivation;