import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { Navigate, useNavigate, Link } from "react-router-dom";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";


const FETCH_MUTATION = gql`
mutation MyMutation($tagId: ID!, $body: String!) {
    createHelpQuestion(inp: {tagId: $tagId, body: $body}) {
        errors
        ok
      }
  }
`
const FETCH_QUERY = gql`
    query MyQuery {
        helpQuestionTags {
            id
            title
          }
    }
`

const maskGenerator = createDefaultMaskGenerator('+7 999 999 9999');


function AskQuetion() {

    const [sendHandler, {data}]= useMutation(FETCH_MUTATION)
    const themes = useQuery(FETCH_QUERY)
    const navigation = useNavigate()
    const [tag, setTag] = useState('')
    const [body, setBody] = useState('')
    const [date, setDate] = useState('')
    const [phone, setPhone] = useState('');

    const onSubmit = (e)=> {
        e.preventDefault()
        sendHandler({variables: {tagId: tag, body: body}}).then(res => res.data?.createHelpQuestion.ok ? navigation('/help-request__success-quetion') : alert(res.data?.createHelpQuestion.errors[0]))
    }

    return (
        <>
            <link rel="stylesheet" href="/assets/css/help.css" />
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <Link to="/" className="g-bc__item">главная</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">задать вопрос</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                        <h2 className="g-section__heading">Задать вопрос председателю</h2>
                        <form onSubmit={onSubmit} className="g-form g-dfw">
                            <label className="g-f__label w-33">
                                <span>Номер телефона</span>
                                <MaskedInput
                                    required
                                    maskGenerator={maskGenerator}
                                    value={phone}
                                    onChange={setPhone}
                                />
                            </label>
                            <label className="g-f__label w-33">
                                <span>Электронная почта</span>
                                <input type="email" required placeholder="name@mail.com" />
                            </label>
                            {/* <label className="g-f__label w-33 g-hi">
                                <div className="g-fl__wrap">
                                    <span>Тема вашего вопроса</span>
                                    <select required onChange={e => setTag(e.target.value)}>
                                        <option value="">Выберите тему вашего вопроса</option>
                                        {themes.data?.helpQuestionTags.map(item => (
                                            <option value={item.id}>{item.title}</option>
                                        ))}
                                    </select>
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5 9L12 16.5L4.5 9" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </label> */}
                            <label className="g-f__label" >
                                <span>Ваш вопрос</span>
                                <input required onChange={e => setBody(e.target.value)} type="text" placeholder="Меня интересует..." />
                            </label>

                            <button type="submit" className="g-btn g-btn__blue g-f__label reset w-33 g-f__btn g-dfw g-alt-center g-jc-sb">
                                <span className="reset">Отправить запрос</span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 16.5L16.5 5.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="white" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>                                
                            </button>
                            
                            <label className="g-f__label agreement reset w-33 g-dfw g-alt-center">
                                <input type="checkbox" checked required/>
                                <div className="g-fl__wrap">
                                    <span>Принимаю <Link target={"_blank"} to="/policy">условия обработки</Link> персональных данных</span>
                                </div>
                            </label>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AskQuetion;