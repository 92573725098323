import React from 'react';
import ReactDOM from 'react-dom/client';
import "swiper/css/bundle";
import './index.css';

import formatISO from 'date-fns/formatISO'

import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { registerLocale } from 'react-datepicker';

import el from "date-fns/locale/ru";
registerLocale("ru", el); 

const httpLink = createHttpLink({
  uri: '/graphql/',
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const offsetMerger = (keyArgs)=> {
  return {
      keyArgs,
      merge(existing, incoming, { args }) {
      const merged = existing ? existing.slice(0) : [];

      if (incoming) {
        if (args) {
          // Assume an offset of 0 if args.offset omitted.
          const { offset = 0 } = args.pagination || {};
          for (let i = 0; i < incoming.length; ++i) {
            merged[offset + i] = incoming[i];
          }
        } else {
          // It's unusual (probably a mistake) for a paginated field not
          // to receive any arguments, so you might prefer to throw an
          // exception here, instead of recovering by appending incoming
          // onto the existing array.
          merged.push.apply(merged, incoming);
        }
      }

      return merged;
    },
}
}

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache()
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client} >
      <App/>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function cache() {
  return new InMemoryCache({
    typePolicies: {
      BudgetItem: {
        fields: {
          price: {
            read: (v) => parseFloat(v)
          },
          amount: {
            read: (v) => parseFloat(v)
          }
        }
      },
      Step: {
        fields: {
          startAt: {
            read: (v) => v? new Date(v): null,
          }
        }
      },
      EventAppPassport: {
        fields: {
          startAt: {
            read: (v) => v? new Date(v): null,
          },
          endAt: {
            read: (v) => v? new Date(v): null,
          }
        }
      },
      Query: {
        fields: {
          posts: offsetMerger(['filters']),
          events: offsetMerger(['filters']),
          galleries: offsetMerger(['filters']),
          magazines: offsetMerger(['filters']),
          postComments: offsetMerger(['pagination']),
          eventComments: offsetMerger(['pagination']),
          galleryComments: offsetMerger(['pagination']),
          helpQuestions: offsetMerger(['order', 'filters']),
        }
      },
    },
  });
}
