function LocalRegsIn() {
    return (
        <>
            <link rel="stylesheet" href="/assets/css/help__local-regulations-in.css" />
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <a href="#" className="g-bc__item">главная</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">медиа</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row g-jc-sb">
                        <h2 className="g-section__heading">Устав нефтегазстрой профсоюза РФ</h2>
                        <div className="g-post__materials g-dfw">
                            <div className="g-pm__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>                                        
                            </div>
                            <div className="g-pm__text-wrap">
                                <p>Файл PDF · 84 kb</p>
                                <h4>Приказ о создании комиссии по проверке знаний</h4>
                            </div>
                            <div className="g-pm__actions g-dfw">
                                <button className="g-btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.375 10.6875L15.75 7.3125L12.375 3.9375" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M13.5 15.1875H2.8125C2.66332 15.1875 2.52024 15.1282 2.41475 15.0227C2.30926 14.9173 2.25 14.7742 2.25 14.625V6.1875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M5.27515 12.375C5.64978 10.926 6.49513 9.64256 7.67842 8.72623C8.86171 7.8099 10.3159 7.31261 11.8125 7.3125H15.75" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                            
                                </button>
                                <button className="g-btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.04688 7.73535L9 10.6877L11.9531 7.73535" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M9 2.8125V10.6854" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15.1875 10.6875V14.625C15.1875 14.7742 15.1282 14.9173 15.0227 15.0227C14.9173 15.1282 14.7742 15.1875 14.625 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V10.6875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                                                                        
                                </button>
                            </div>
                        </div>
                        <div className="g-post__materials g-dfw">
                            <div className="g-pm__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>                                        
                            </div>
                            <div className="g-pm__text-wrap">
                                <p>Файл PDF · 84 kb</p>
                                <h4>Перечень инструкций по охране труда и других документов</h4>
                            </div>
                            <div className="g-pm__actions g-dfw">
                                <button className="g-btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.375 10.6875L15.75 7.3125L12.375 3.9375" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M13.5 15.1875H2.8125C2.66332 15.1875 2.52024 15.1282 2.41475 15.0227C2.30926 14.9173 2.25 14.7742 2.25 14.625V6.1875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M5.27515 12.375C5.64978 10.926 6.49513 9.64256 7.67842 8.72623C8.86171 7.8099 10.3159 7.31261 11.8125 7.3125H15.75" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                            
                                </button>
                                <button className="g-btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.04688 7.73535L9 10.6877L11.9531 7.73535" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M9 2.8125V10.6854" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15.1875 10.6875V14.625C15.1875 14.7742 15.1282 14.9173 15.0227 15.0227C14.9173 15.1282 14.7742 15.1875 14.625 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V10.6875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                                                                        
                                </button>
                            </div>
                        </div>
                        <div className="g-post__materials g-dfw">
                            <div className="g-pm__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>                                        
                            </div>
                            <div className="g-pm__text-wrap">
                                <p>Файл PDF · 84 kb</p>
                                <h4>Политика в области охраны труда, промышленной
                                    и пожарной безопасности</h4>
                            </div>
                            <div className="g-pm__actions g-dfw">
                                <button className="g-btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.375 10.6875L15.75 7.3125L12.375 3.9375" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M13.5 15.1875H2.8125C2.66332 15.1875 2.52024 15.1282 2.41475 15.0227C2.30926 14.9173 2.25 14.7742 2.25 14.625V6.1875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M5.27515 12.375C5.64978 10.926 6.49513 9.64256 7.67842 8.72623C8.86171 7.8099 10.3159 7.31261 11.8125 7.3125H15.75" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                            
                                </button>
                                <button className="g-btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.04688 7.73535L9 10.6877L11.9531 7.73535" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M9 2.8125V10.6854" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15.1875 10.6875V14.625C15.1875 14.7742 15.1282 14.9173 15.0227 15.0227C14.9173 15.1282 14.7742 15.1875 14.625 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V10.6875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                                                                        
                                </button>
                            </div>
                        </div>
                        <div className="g-post__materials g-dfw">
                            <div className="g-pm__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>                                        
                            </div>
                            <div className="g-pm__text-wrap">
                                <p>Файл PDF · 84 kb</p>
                                <h4>Перечень документов об обучении и аттестации работников подрядных организаций</h4>
                            </div>
                            <div className="g-pm__actions g-dfw">
                                <button className="g-btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.375 10.6875L15.75 7.3125L12.375 3.9375" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M13.5 15.1875H2.8125C2.66332 15.1875 2.52024 15.1282 2.41475 15.0227C2.30926 14.9173 2.25 14.7742 2.25 14.625V6.1875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M5.27515 12.375C5.64978 10.926 6.49513 9.64256 7.67842 8.72623C8.86171 7.8099 10.3159 7.31261 11.8125 7.3125H15.75" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                            
                                </button>
                                <button className="g-btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.04688 7.73535L9 10.6877L11.9531 7.73535" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M9 2.8125V10.6854" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15.1875 10.6875V14.625C15.1875 14.7742 15.1282 14.9173 15.0227 15.0227C14.9173 15.1282 14.7742 15.1875 14.625 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V10.6875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                                                                        
                                </button>
                            </div>
                        </div>
                        <div className="g-post__materials g-dfw">
                            <div className="g-pm__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>                                        
                            </div>
                            <div className="g-pm__text-wrap">
                                <p>Файл PDF · 84 kb</p>
                                <h4>Перечень документов об обучении и аттестации работников подрядных организаций</h4>
                            </div>
                            <div className="g-pm__actions g-dfw">
                                <button className="g-btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.375 10.6875L15.75 7.3125L12.375 3.9375" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M13.5 15.1875H2.8125C2.66332 15.1875 2.52024 15.1282 2.41475 15.0227C2.30926 14.9173 2.25 14.7742 2.25 14.625V6.1875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M5.27515 12.375C5.64978 10.926 6.49513 9.64256 7.67842 8.72623C8.86171 7.8099 10.3159 7.31261 11.8125 7.3125H15.75" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                            
                                </button>
                                <button className="g-btn">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.04688 7.73535L9 10.6877L11.9531 7.73535" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M9 2.8125V10.6854" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15.1875 10.6875V14.625C15.1875 14.7742 15.1282 14.9173 15.0227 15.0227C14.9173 15.1282 14.7742 15.1875 14.625 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V10.6875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                                                                        
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        
        </>
    )
}

export default LocalRegsIn;