import { HashLink as Link } from 'react-router-hash-link';

function Footer() {
    return (
        <div className="g-container">
            <div className="g-row">
                <div className="g-footer__search g-label g-dfw g-alt-center g-jc-sb">
                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16.4429 16.4434L20.9992 20.9997" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>*/}
                    <input type="text" placeholder="© 2023 ОППО Газпром добыча Уренгой профсоюз" name="" id="" />                 
                </div>
                <div className="g-social__list g-dfw g-jc-sb">
                    {/* <a href="#" className="g-sl__item g-dfw g-jc-center">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_14_28391)">
                            <path d="M19.0294 5.82386C17.1137 1.64762 12.6564 -0.721476 8.01405 0.198065C6.46232 0.498192 5.00639 1.18785 3.78672 2.1904C1.59642 3.99117 0.351213 6.31556 0.114943 9.13804C-0.0510856 11.1112 0.363985 12.9822 1.32822 14.7255C1.37292 14.8149 1.3857 14.9171 1.36015 15.0129C1.12388 15.9133 0.87484 16.8136 0.625798 17.714C0.421456 18.4612 0.217114 19.2083 0 19.9937C0.108557 19.9682 0.185185 19.949 0.255428 19.9363C1.86462 19.5148 3.4802 19.0933 5.09579 18.6719C5.19796 18.6463 5.30651 18.6591 5.4023 18.7038C7.56066 19.7958 9.8212 20.1087 12.1839 19.5787C16.3793 18.6463 19.5466 15.0001 19.8787 10.7153C20.0128 9.01033 19.7382 7.3692 19.0294 5.81109V5.82386ZM11.8519 17.9311C9.68071 18.4165 7.63729 18.0844 5.72797 16.9541C5.57471 16.8647 5.44061 16.8328 5.26181 16.8839C4.38697 17.1202 3.50575 17.35 2.63091 17.5735C2.56066 17.5927 2.49042 17.6055 2.39464 17.631C2.61175 16.8328 2.79055 16.0537 3.03959 15.2939C3.19285 14.8213 3.14815 14.4637 2.88633 14.0295C2.04981 12.6629 1.6986 11.1559 1.77522 9.5595C1.94764 5.70254 4.71903 2.64379 8.212 1.88389C12.4457 0.964348 16.5645 3.3526 17.8672 7.47776C19.2976 12.0052 16.4879 16.8903 11.8582 17.9311H11.8519Z" fill="black"/>
                            <path d="M12.7585 11.1489C12.5541 11.0532 12.3945 11.1234 12.254 11.3022C12.0113 11.6151 11.7559 11.9216 11.5133 12.2281C11.3792 12.4005 11.2131 12.4325 11.0152 12.3558C9.54008 11.7747 8.4162 10.785 7.6116 9.4312C7.50305 9.2524 7.51582 9.10553 7.64992 8.9395C7.87342 8.6713 8.07776 8.39671 8.26294 8.10297C8.33319 7.98803 8.35873 7.79646 8.31403 7.67513C8.0586 7.01102 7.77763 6.35329 7.50305 5.69557C7.40088 5.44652 7.23485 5.30604 6.94111 5.33158C6.80062 5.34435 6.66014 5.3252 6.52604 5.3252C6.40471 5.3252 6.28338 5.34435 6.16844 5.39544C6.05349 5.44014 5.95132 5.51038 5.86831 5.59978C5.28082 6.16811 4.96154 6.85138 4.94238 7.67513C4.9807 7.9114 4.99347 8.15406 5.05094 8.39033C5.22974 9.16938 5.65119 9.82711 6.11097 10.4657C7.50943 12.4005 9.24634 13.8629 11.5835 14.5206C12.4967 14.776 13.3523 14.7313 14.1697 14.1949C14.8274 13.7671 15.0382 13.1477 15.0126 12.4133C15.0126 12.3239 14.9424 12.2026 14.8658 12.1643C14.1633 11.8131 13.4609 11.481 12.7521 11.1489H12.7585Z" fill="black"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_14_28391">
                            <rect width="19.9106" height="20" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        <p>WhatsApp</p>                    
                    </a> */}
                    <a target="_blank" href="https://t.me/gdu_profsouz" className="g-sl__item g-dfw g-jc-center">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 15.5228 15.5228 20 10 20C4.47723 20 0 15.5228 0 10C0 4.47723 4.47723 0 10 0C15.5228 0 20 4.47723 20 10ZM10.3592 7.38294C9.38422 7.78704 7.44067 8.62733 4.52854 9.89737C4.05388 10.0834 3.81013 10.2694 3.78448 10.449C3.74599 10.7505 4.12444 10.8724 4.644 11.0391C4.71456 11.0584 4.78512 11.084 4.86209 11.1097C5.37524 11.2765 6.06158 11.4689 6.41437 11.4753C6.73509 11.4817 7.10071 11.347 7.4984 11.0776C10.2245 9.23669 11.6292 8.30661 11.7191 8.28736C11.7832 8.27454 11.8666 8.25529 11.9243 8.30661C11.982 8.35792 11.9756 8.45414 11.9692 8.48621C11.9307 8.64657 10.4362 10.0385 9.66004 10.7569C9.41629 10.9814 9.24952 11.1418 9.21103 11.1738C9.13406 11.2572 9.05067 11.3342 8.9737 11.4047C8.49904 11.8602 8.14625 12.2065 8.99294 12.7646C9.40346 13.034 9.7306 13.2585 10.0513 13.4766C10.4105 13.7203 10.7633 13.9577 11.2187 14.2591C11.3342 14.3361 11.4496 14.4131 11.5587 14.4901C11.9756 14.7851 12.3477 15.0481 12.8031 15.0096C13.0725 14.984 13.3483 14.7338 13.4894 13.9833C13.823 12.213 14.4708 8.37716 14.6248 6.79282C14.6376 6.6517 14.6248 6.47851 14.6055 6.40154C14.5927 6.32457 14.567 6.21552 14.4644 6.13214C14.3425 6.03592 14.1565 6.01668 14.0795 6.01668C13.7011 6.02309 13.1238 6.22194 10.3464 7.37652L10.3592 7.38294Z" fill="black"/>
                        </svg>
                        <p>Telegram</p>                    
                    </a>
                    <a target="_blank" href="https://www.youtube.com/channel/UCVUr6cpxogns7tcGHSYoeQQ" className="g-sl__item g-dfw g-jc-center">
                        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_14_28396)">
                            <path d="M19.8012 3.034C19.8012 3.034 19.6087 1.65491 19.0058 1.05196C18.2425 0.256575 17.3894 0.25016 17.0045 0.20526C14.2078 0 10.0064 0 10.0064 0H10C10 0 5.79859 0 3.00192 0.20526C2.61065 0.25016 1.75754 0.256575 1.00064 1.05196C0.397691 1.65491 0.20526 3.034 0.20526 3.034C0.20526 3.034 0 4.65683 0 6.27325V7.78704C0 9.40346 0.198845 11.0263 0.198845 11.0263C0.198845 11.0263 0.391276 12.4054 0.994227 13.0083C1.75754 13.8037 2.75818 13.7781 3.20077 13.8615C4.80436 14.0154 10 14.0603 10 14.0603C10 14.0603 14.2014 14.0539 16.9981 13.855C17.3894 13.8101 18.2425 13.8037 18.9994 13.0083C19.6023 12.4054 19.7947 11.0263 19.7947 11.0263C19.7947 11.0263 19.9936 9.40988 19.9936 7.78704V6.27325C19.9936 4.65683 19.7947 3.034 19.7947 3.034H19.8012ZM7.93457 9.62797V4.00898L13.3355 6.8313L7.93457 9.63438V9.62797Z" fill="black"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_14_28396">
                            <rect width="20" height="14.0667" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        <p>YouTube</p>                    
                    </a>
                    <a target="_blank" href="https://vk.com/gdu_profsouz" className="g-sl__item g-dfw g-jc-center">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_14_28398)">
                            <path d="M18.5953 1.40475C17.1905 0 14.9262 0 10.4169 0H9.58307C5.07376 0 2.80949 0 1.40475 1.40475C0 2.80949 0 5.07376 0 9.58307V10.4169C0 14.9262 0 17.1905 1.40475 18.5953C2.80949 20.0064 5.07376 20 9.58307 20H10.4169C14.9391 20 17.2033 20 18.6145 18.5953C20.0257 17.1905 20 14.9262 20 10.4169V9.58307C20 5.07376 20 2.80949 18.5953 1.40475ZM14.3489 14.4067C14.1309 13.6241 13.6883 12.925 13.0725 12.399C12.4567 11.8666 11.6998 11.533 10.898 11.4304V14.4067H10.6414C6.08082 14.4067 3.483 11.2829 3.37396 6.07441H5.65747C5.73445 9.89096 7.41501 11.4881 8.7492 11.8409V6.08082H10.898V9.37139C12.213 9.23028 13.5985 7.72931 14.0667 6.08082H16.2155C16.0423 6.94035 15.696 7.75497 15.1956 8.46697C14.6953 9.18538 14.0475 9.78833 13.3034 10.2437C14.1373 10.6543 14.8749 11.2444 15.465 11.9628C16.0552 12.6812 16.4849 13.5151 16.7287 14.4131H14.3554L14.3489 14.4067Z" fill="black"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_14_28398">
                            <rect width="20" height="20" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>                    
                        <p>Вконтакте</p>                    
                    </a>
                    <a target="_blank" href="https://xn--80afnaylbafcido5b6k.xn--p1ai/" className="g-sl__item g-dfw g-jc-center">
                        <div style={{width: 30, height: 34}}>
                            <img style={{width: 20, height: 20}} src="/assets/img/gzp_logo.jpg" />                   
                        </div>
                        <p>Газпром</p>                    
                    </a>
                    <a target="_blank" href="https://www.rogwu.ru/" className="g-sl__item g-dfw g-jc-center">
                        <div style={{width: 30, height: 34}}>
                            <img style={{width: 20, height: 20}} src="/assets/img/ngsp_logo.jpg" />                   
                        </div>
                        <p>НГСП</p>                    
                    </a>
                </div>
            </div>
            <div className="g-row g-jc-sb">
                <div className="g-mmc__col">
                    <h4 className="g-mmcc_heading">Медиа</h4>
                    <ul className="g-mmcc__links-list">
                        <li><Link to="/gdu-media/news#top">Новости</Link></li>
                        <li><Link to="/gdu-media/print#top">Печатные издания</Link></li>
                        <li><Link to="/gdu-media/projects#top">Проекты профсоюза</Link></li>
                        {/* <li><a href="#">Видеозаписи</a></li> */}
                        <li><Link to="/gdu-media/materials#top">Фотогалерея</Link></li>
                    </ul>
                </div>
                <div className="g-mmc__col">
                    <h4 className="g-mmcc_heading">Организация</h4>
                    <ul className="g-mmcc__links-list">
                        <li><Link to="/union#top">Об организации</Link></li>
                        <li><Link to="/union#menegers">Руководство</Link></li>
                        <li><Link to="/union#structure">Организационная структура</Link></li>
                        <li><Link to="/union#comission">Комисcии Совета ОППО</Link></li>
                        <li><Link to="/union/legal-work">Правовая работа</Link></li>
                        <li><Link to="/union/job-safety">Охрана труда</Link></li>
                    </ul>
                </div>
                <div className="g-mmc__col">
                    <h4 className="g-mmcc_heading">Помощь</h4>
                    <ul className="g-mmcc__links-list">
                        {/* <li><Link to="/help-local-regs">Нормативные акты</Link></li> */}
                        <li><Link to="/help">Последние вопросы</Link></li>
                        <li><Link to="/help-book">Запись на прием</Link></li>
                        <li><Link to="/help">Задать вопрос</Link></li>
                        <li><Link to="/contacts">Контакты</Link></li>
                    </ul>
                </div> 
                <div className="g-mmc__col">
                    <h4 className="g-mmcc_heading">Полезное</h4>
                    <ul className="g-mmcc__links-list">
                        {/* <li><a href="#">СК «Факел»</a></li> */}
                        {/* <li><a href="#">Конкурсные проекты</a></li> */}
                        <li><a target="_blank" href="http://discount-prof.ru/participants/">Профсоюзный дисконт</a></li>
                        <li><a target="_blank" href="https://xn--80afnaylbafcido5b6k.xn--p1ai/partnery_privilegii">Газпром профсоюз «Привилегия»</a></li>
                        <li><a href={localStorage.getItem("token")? "/project-office": "/auth"}>Проектный офис</a></li>
                    </ul>
                </div>
                <div className="g-mmc__col">
                    <div className="g-mmcc__apps g-dfw g-jc-sb">
                        <a target="_blank" href="https://apps.apple.com/ru/app/%D0%B3%D0%B4%D1%83-%D0%BF%D1%80%D0%BE%D1%84%D1%81%D0%BE%D1%8E%D0%B7/id1666333644" className="g-mmcca__btn g-dfw g-btn g-btn__transparent-blue">
                            <span>App Store</span>
                            <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_14_28439)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.99614 1.74035C10.3104 0.00822288 12.1371 -0.000976562 12.1371 -0.000976562C12.1371 -0.000976562 12.4088 1.62766 11.1035 3.19617C9.70976 4.87179 8.12514 4.59712 8.12514 4.59712C8.12514 4.59712 7.8278 3.28028 8.99614 1.74035Z" fill="#0072C5"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.29315 5.73905C8.96932 5.73905 10.2237 4.80957 11.857 4.80957C14.6681 4.80957 15.774 6.81013 15.774 6.81013C15.774 6.81013 13.6111 7.91571 13.6111 10.5993C13.6111 13.6263 16.3052 14.6694 16.3052 14.6694C16.3052 14.6694 14.4213 19.971 11.8773 19.971C10.7087 19.971 9.80023 19.1824 8.56914 19.1824C7.31439 19.1824 6.06918 19.9992 5.25831 19.9992C2.93511 19.9992 0.00146484 14.9704 0.00146484 10.9279C0.00146484 6.95239 2.48598 4.86444 4.81608 4.86444C6.32939 4.86542 7.50463 5.73905 8.29315 5.73905Z" fill="#0072C5"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_14_28439">
                                        <rect width="16.3044" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=ru.oppogdu.app" className="g-mmcca__btn g-dfw g-btn g-btn__transparent-blue">
                            <span>Google Play</span>
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_14_28434)">
                                    <path d="M10.2144 10.0083C9.6526 10.7049 9.09698 11.3886 8.54305 12.0707C6.60148 14.4606 4.65897 16.8497 2.71552 19.2381C2.30766 19.7381 1.7481 19.96 1.11945 19.9982C0.729565 20.0223 0.438555 19.8297 0.249791 19.4892C0.0481054 19.1257 0.00147718 18.7285 0.00147718 18.3223C0.00147718 14.6055 0.00147718 10.8886 0.00147718 7.17179C0.00147718 5.33639 -0.00470287 3.50156 0.00821847 1.66617C0.0105005 1.35829 0.0587861 1.05247 0.151476 0.758865C0.350914 0.136393 0.811027 -0.107989 1.44361 0.0436963C1.99136 0.175719 2.45485 0.430214 2.82339 0.886394C5.21477 3.85194 7.61515 6.80999 10.0245 9.76055C10.0835 9.83302 10.1386 9.90887 10.2144 10.0083Z" fill="#0072C5"/>
                                    <path d="M14.0766 12.8161L11.7935 10.0043L14.0738 7.19531C14.7912 7.61048 15.4957 8.01498 16.1968 8.42509C16.4957 8.59981 16.803 8.76498 17.0783 8.97172C17.921 9.60374 17.9171 10.3762 17.089 11.0318C17.0467 11.0689 17.0018 11.1029 16.9547 11.1335C16.003 11.6914 15.0508 12.2476 14.0766 12.8161Z" fill="#0072C5"/>
                                    <path d="M5.57178 17.659L11.0055 10.9736C11.6763 11.7995 12.328 12.6 13.0032 13.4304L5.60717 17.7L5.57178 17.659Z" fill="#0072C5"/>
                                    <path d="M5.60451 2.30664L13.0011 6.5763L11.0051 9.03305L5.5708 2.34765L5.60451 2.30664Z" fill="#0072C5"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_14_28434">
                                        <rect width="17.7096" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>                                                          
                        </a>
                    </div> 
                    <div className="g-mmcc__contact g-dfw g-alt-center">
                        <div className="g-btn">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.22471 9.75133C7.87302 11.0768 8.94739 12.1462 10.2758 12.7885C10.3729 12.8346 10.4804 12.8545 10.5877 12.8464C10.6949 12.8383 10.7982 12.8024 10.8874 12.7422L12.8433 11.438C12.9298 11.3803 13.0293 11.3451 13.1328 11.3356C13.2364 11.3261 13.3407 11.3426 13.4362 11.3835L17.0954 12.9517C17.2197 13.0045 17.3234 13.0963 17.3911 13.2132C17.4587 13.3301 17.4865 13.4658 17.4702 13.5999C17.3546 14.5049 16.913 15.3367 16.2282 15.9396C15.5434 16.5425 14.6624 16.8751 13.75 16.8751C10.9321 16.8751 8.22956 15.7557 6.23699 13.7631C4.24442 11.7706 3.125 9.06806 3.125 6.25014C3.12505 5.33777 3.45765 4.45671 4.06053 3.77192C4.66341 3.08712 5.49523 2.64556 6.40023 2.5299C6.53431 2.51368 6.67002 2.54146 6.78693 2.60908C6.90384 2.67669 6.99561 2.78046 7.04841 2.90476L8.61798 6.5671C8.65858 6.66183 8.67512 6.76513 8.66614 6.8678C8.65715 6.97047 8.62291 7.06932 8.56647 7.15556L7.26676 9.14147C7.2076 9.23081 7.17263 9.33396 7.16526 9.44085C7.15789 9.54775 7.17837 9.65472 7.22471 9.75133V9.75133Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div className="g-mmccc__text-wrap">
                            <a className="g-mmcc__link">8 (3494) 94-09-88</a>
                            <p className="g-mmcc__text">Горячаяя линия</p>
                        </div>
                    </div>
                    <div className="g-mmcc__contact g-dfw g-alt-center">
                        <div className="g-btn">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.5 4.375H17.5V15C17.5 15.1658 17.4342 15.3247 17.3169 15.4419C17.1997 15.5592 17.0408 15.625 16.875 15.625H3.125C2.95924 15.625 2.80027 15.5592 2.68306 15.4419C2.56585 15.3247 2.5 15.1658 2.5 15V4.375Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M17.5 4.375L10 11.25L2.5 4.375" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>                        
                        </div>
                        <div className="g-mmccc__text-wrap">
                            <a className="g-mmcc__link">mail@oppogdu.ru</a>
                            <p className="g-mmcc__text">Вопросы и предложения</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="g-row g-copyright">
                <div className="g-copyright__list g-dfw">
                    <p className="g-cpl__item">© 2022 ОППО Газпром добыча Уренгой профсоюз</p>
                    <Link to="/policy" className="g-cpl__item">Политика конфиденциальности</Link>
                    <a className="g-cpl__item">Карта сайта</a>
                    <a className="g-cpl__item">Разработка и продвижение</a>
                    <a className="g-cpl__item">
                        <img src="../../assets/img/copyright__logo.png" alt="" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer;