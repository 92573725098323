import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Controller } from 'swiper';
import { useQuery, gql } from "@apollo/client";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "fslightbox"
import { fmtDate } from "../util";

const FETCH_QUERY = gql(`
query MyQuery {
    postCount
    eventCount
    helpQuestionCount
    bonusPartnerCount
    magazinesCount

    posts(order: {createdAt: DESC}) {
      id
      pic
      title
      slug
      createdAt
    }
    events(pagination: {offset: 0, limit: 10}) {
      id
      pic
      start
      end
      title
      slug
    }
}
`)

function Home() {
    const { data } = useQuery(FETCH_QUERY);
    let navigate = useNavigate()

    useEffect(()=> {
        if (!window.fsLightbox) {
            window.fsLightbox = new window.FsLightbox()
            window.refreshFsLightbox()
    } else {
            window.refreshFsLightbox()
        }
        
    }, [data])

    const [eventSwiper, setEventSwiper] = useState(null);
    const [postSwiper, setPostSwiper] = useState(null);
    const [attendSwiper, setAttendSwiper] = useState(null);

    return (
        <>
            <link rel="stylesheet" href="/assets/css/home.css?v=2" />
            {/* Banner Section */} 
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-banner__heading">
                            <h1>Газпром
                                добыча
                                Уренгой
                                профсоюз
                            </h1>
                            <Link to="/union" className="g-btn g-btn__transparent g-dfw g-jc-sb">
                                <span>Об организации</span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 16.5L16.5 5.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="white" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>                                
                            </Link>
                        </div>
                        <div className="g-banner__video g-dfw g-jc-sb g-alt-center">
                            <a data-fslightbox="video" href="#dzen-promo" data-class="g-block" className="g-btn g-btn__transparent rounded">
                                <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M50.7231 27.4528L18.1034 7.51789C17.8286 7.34998 17.5141 7.2583 17.1921 7.25227C16.8702 7.24624 16.5525 7.32609 16.2716 7.48359C15.9908 7.6411 15.757 7.87058 15.5942 8.14842C15.4315 8.42627 15.3457 8.74245 15.3457 9.06445V48.9343C15.3457 49.2563 15.4315 49.5725 15.5942 49.8503C15.757 50.1282 15.9908 50.3576 16.2716 50.5152C16.5525 50.6727 16.8702 50.7525 17.1921 50.7465C17.5141 50.7404 17.8286 50.6488 18.1034 50.4809L50.7231 30.5459C50.988 30.384 51.2069 30.1568 51.3588 29.886C51.5106 29.6151 51.5904 29.3099 51.5904 28.9994C51.5904 28.6889 51.5106 28.3836 51.3588 28.1128C51.2069 27.842 50.988 27.6147 50.7231 27.4528Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </a>
                        </div>
                        <iframe id="dzen-promo" className="g-none" width="1027" height="765" 
                            src="https://dzen.ru/embed/v-8MLS9L6WGk?from_block=partner&from=zen&backoffice=1&mute=0&autoplay=0&tv=0"
                            allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media" 
                            frameBorder="0" scrolling="no" allowFullScreen=""></iframe>
                        <div className="g-hot-links g-dfw">
                            <div className="g-hl__col g-dfw"></div>
                            <Link to="/gdu-media/projects" className="g-hl__col g-hl__item g-dfw">
                                <h4>Проекты профсоюза</h4>
                                <p>{data?.eventCount} проектов</p>
                                <i className="g-hlc__icon">
                                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.75 37.125L27 48.9375L47.25 37.125" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6.75 27L27 38.8125L47.25 27" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6.75 16.875L27 28.6875L47.25 16.875L27 5.0625L6.75 16.875Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </i>
                            </Link>
                            <a href="http://discount-prof.ru/participants/" target={"_blank"} className="g-hl__col g-hl__item g-dfw">
                                <h4>Профсоюзный дисконт</h4>
                                {/* <p>{data?.bonusPartnerCount} скидок</p> */}
                                {/* <i className="g-hlc__icon">
                                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.6">
                                        <path d="M42.1875 11.8125L11.8125 42.1875" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M16.0312 21.9375C19.2932 21.9375 21.9375 19.2932 21.9375 16.0312C21.9375 12.7693 19.2932 10.125 16.0312 10.125C12.7693 10.125 10.125 12.7693 10.125 16.0312C10.125 19.2932 12.7693 21.9375 16.0312 21.9375Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M37.9688 43.875C41.2307 43.875 43.875 41.2307 43.875 37.9688C43.875 34.7068 41.2307 32.0625 37.9688 32.0625C34.7068 32.0625 32.0625 34.7068 32.0625 37.9688C32.0625 41.2307 34.7068 43.875 37.9688 43.875Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                    </svg>                                    
                                </i> */}
                            </a>
                            <Link to="/gdu-media/news" className="g-hl__col g-hl__item g-dfw">
                                <h4>Новости</h4>
                                <p>{data?.postCount} материалов</p>
                                <i className="g-hlc__icon">
                                    {/* <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.6">
                                            <path d="M18.5625 11.8125H13.5C12.568 11.8125 11.8125 12.568 11.8125 13.5V40.5C11.8125 41.432 12.568 42.1875 13.5 42.1875H18.5625C19.4945 42.1875 20.25 41.432 20.25 40.5V13.5C20.25 12.568 19.4945 11.8125 18.5625 11.8125Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M40.5 11.8125H35.4375C34.5055 11.8125 33.75 12.568 33.75 13.5V40.5C33.75 41.432 34.5055 42.1875 35.4375 42.1875H40.5C41.432 42.1875 42.1875 41.432 42.1875 40.5V13.5C42.1875 12.568 41.432 11.8125 40.5 11.8125Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M42.1875 16.875H47.25C47.6976 16.875 48.1268 17.0528 48.4432 17.3693C48.7597 17.6857 48.9375 18.1149 48.9375 18.5625V35.4375C48.9375 35.8851 48.7597 36.3143 48.4432 36.6307C48.1268 36.9472 47.6976 37.125 47.25 37.125H42.1875" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M11.8125 37.125H6.75C6.30245 37.125 5.87322 36.9472 5.55676 36.6307C5.24029 36.3143 5.0625 35.8851 5.0625 35.4375V18.5625C5.0625 18.1149 5.24029 17.6857 5.55676 17.3693C5.87322 17.0528 6.30245 16.875 6.75 16.875H11.8125" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M20.25 27H33.75" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M48.9375 27H52.3125" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M1.6875 27H5.0625" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                    </svg>                                     */}
                                </i>
                            </Link>
                            <Link to="/help" className="g-hl__col g-hl__item g-dfw">
                                <h4>Вопросы председателю</h4>
                                <p>{data?.helpQuestionCount} ответов</p>
                                <i className="g-hlc__icon">
                                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.6">
                                            <path d="M27 47.25C38.1838 47.25 47.25 38.1838 47.25 27C47.25 15.8162 38.1838 6.75 27 6.75C15.8162 6.75 6.75 15.8162 6.75 27C6.75 38.1838 15.8162 47.25 27 47.25Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M27 39.6562C27.932 39.6562 28.6875 38.9007 28.6875 37.9688C28.6875 37.0368 27.932 36.2812 27 36.2812C26.068 36.2812 25.3125 37.0368 25.3125 37.9688C25.3125 38.9007 26.068 39.6562 27 39.6562Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M27 30.375V28.6875C28.1681 28.6875 29.3101 28.3411 30.2813 27.6921C31.2526 27.0431 32.0096 26.1207 32.4567 25.0415C32.9037 23.9623 33.0207 22.7747 32.7928 21.629C32.5649 20.4833 32.0024 19.4309 31.1764 18.6049C30.3503 17.7789 29.298 17.2164 28.1523 16.9885C27.0066 16.7606 25.819 16.8776 24.7398 17.3246C23.6606 17.7716 22.7381 18.5286 22.0891 19.4999C21.4401 20.4712 21.0938 21.6131 21.0938 22.7813" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                    </svg>                                    
                                </i>
                            </Link>
                            <div className="g-hl__col g-dfw">
                                <div className="g-banner__slider__navigation g-slider__navigation g-dfw g-dfw">
                                    <div className="g-bsn__btn prev">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.25 14.625L5.625 9L11.25 3.375" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    </div>
                                    <div className="g-bsn__btn next">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.75 3.375L12.375 9L6.75 14.625" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Media Section */}
            <section className="g-section g-media__section">
                <div className="g-container">
                    <div className="g-row">
                        <h3 className="g-section__tag">медиа</h3>
                        <h2 className="g-section__heading">Новости профсоюза</h2>
                        <div className="g-section__actions g-dfw">
                            <Link to="/gdu-media/news" className="g-sa__link">Смотреть все</Link>
                            <div className="g-media__slider__navigation g-slider__navigation g-dfw">
                                <div onClick={()=> postSwiper.slidePrev()} className="g-bsn__btn prev">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.25 14.625L5.625 9L11.25 3.375" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                                <div onClick={()=> postSwiper.slideNext()} className="g-bsn__btn next">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.75 3.375L12.375 9L6.75 14.625" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                            </div>
                        </div>

                        <Swiper className='g-slider g-media__slider'
                            slidesPerView={1}
                            spaceBetween={10}
                            loop={true}
                            modules={[Controller]}
                            onSwiper={setPostSwiper}

                            breakpoints={{
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                    
                                }
                            }}
                        >
                            {data?.posts.map((item,idx) => (
                                <SwiperSlide onClick={() => navigate('/gdu-media/news/'+item.slug)} className='g-post g-dfw' key={idx}>
                                    <div className="g-post__banner">
                                            <img src={'/media/' + item.pic} alt="" />
                                            <div className="g-post__meta g-bage blue">{fmtDate(item.createdAt)}</div>
                                        </div>
                                        <div className="g-post_excerpt g-dfw">
                                            <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                                </svg>    
                                            </div>
                                            <div className="g-pe__wrap">
                                                <h2 className="g-pe__title">{item.title}</h2>
                                                <p className="g-pe__text">Новость · {fmtDate(item.createdAt)}</p>
                                            </div>
                                        </div>
                                </SwiperSlide>
                            ) )}
                        </Swiper>


                    </div>
                </div>
            </section>
            {/* Counter Section */}
            <section className="g-section g-counter__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-counter g-dfw">
                            <div className="g-c__text-wrap g-text__wrap">
                                <h3 className="g-section__tag white">Членов профсоюза</h3>
                                <h2 className="g-c__title">> 12 000</h2>
                            </div>
                            <svg className="g-counter__icon" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5001 30C21.8848 30 26.2501 25.6348 26.2501 20.25C26.2501 14.8652 21.8848 10.5 16.5001 10.5C11.1153 10.5 6.75006 14.8652 6.75006 20.25C6.75006 25.6348 11.1153 30 16.5001 30Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M29.1398 10.8632C30.4809 10.4853 31.8873 10.3992 33.2644 10.6107C34.6415 10.8222 35.9573 11.3264 37.1232 12.0892C38.289 12.852 39.2779 13.8558 40.0231 15.033C40.7684 16.2102 41.2527 17.5334 41.4435 18.9135C41.6343 20.2937 41.5271 21.6986 41.1292 23.0339C40.7313 24.3691 40.0518 25.6035 39.1367 26.654C38.2215 27.7046 37.0918 28.5468 35.8237 29.1239C34.5557 29.7011 33.1786 29.9998 31.7854 30" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M2.99927 37.0119C4.52202 34.8459 6.54357 33.0781 8.89324 31.8577C11.2429 30.6372 13.8517 30.0001 16.4994 30C19.1471 29.9999 21.756 30.6368 24.1058 31.8571C26.4555 33.0773 28.4772 34.845 30.0001 37.0108" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M31.7854 30C34.4333 29.9981 37.0428 30.6343 39.3927 31.8546C41.7427 33.075 43.764 34.8437 45.2855 37.0109" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>                            
                        </div>
                        <div className="g-counter g-dfw">
                            {/* <div className="g-c__text-wrap g-text__wrap">
                                <h3 className="g-section__tag">проектов развития</h3>
                                <h2 className="g-c__title">> 348</h2>
                            </div>
                            <svg className="g-counter__icon" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M40.5015 13.5H7.50146C6.67304 13.5 6.00146 14.1716 6.00146 15V39C6.00146 39.8284 6.67304 40.5 7.50146 40.5H40.5015C41.3299 40.5 42.0015 39.8284 42.0015 39V15C42.0015 14.1716 41.3299 13.5 40.5015 13.5Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M31.5 13.5V10.5C31.5 9.70435 31.1839 8.94129 30.6213 8.37868C30.0587 7.81607 29.2956 7.5 28.5 7.5H19.5C18.7044 7.5 17.9413 7.81607 17.3787 8.37868C16.8161 8.94129 16.5 9.70435 16.5 10.5V13.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M42.0015 23.683C36.5308 26.8481 30.3203 28.51 24 28.5C17.6808 28.51 11.4713 26.8486 6.00123 23.6846" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21.75 22.5H26.25" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> */}
                        </div>
                        <div className="g-counter g-dfw">
                            <div className="g-c__text-wrap g-text__wrap">
                                <h3 className="g-section__tag">Поддержано инициатив</h3>
                                <h2 className="g-c__title">> 30</h2>
                            </div>
                            <svg className="g-counter__icon" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.8271 35.7624L34.2837 41.7537C35.4925 42.5196 36.9933 41.3805 36.6346 39.9695L33.9024 29.2215C33.8255 28.9222 33.8346 28.6072 33.9287 28.3128C34.0228 28.0184 34.1981 27.7565 34.4344 27.5573L42.9146 20.4992C44.0288 19.5718 43.4537 17.7225 42.0221 17.6296L30.9477 16.9109C30.6494 16.8896 30.3633 16.784 30.1227 16.6063C29.8821 16.4287 29.6969 16.1864 29.5887 15.9076L25.4584 5.50646C25.3461 5.21055 25.1464 4.95581 24.886 4.77604C24.6255 4.59627 24.3165 4.5 24 4.5C23.6835 4.5 23.3745 4.59627 23.114 4.77604C22.8536 4.95581 22.6539 5.21055 22.5417 5.50646L18.4114 15.9076C18.3031 16.1864 18.1179 16.4287 17.8774 16.6063C17.6368 16.784 17.3507 16.8896 17.0524 16.9109L5.97788 17.6296C4.54631 17.7225 3.97119 19.5718 5.08545 20.4992L13.5656 27.5573C13.8019 27.7565 13.9772 28.0184 14.0713 28.3128C14.1654 28.6072 14.1745 28.9222 14.0976 29.2215L11.5638 39.1891C11.1333 40.8823 12.9343 42.2491 14.3849 41.3301L23.1729 35.7624C23.4201 35.605 23.707 35.5215 24 35.5215C24.293 35.5215 24.5799 35.605 24.8271 35.7624V35.7624Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </section>
            {/*Active Projects Section */}
            <section className="g-section g-active__projects">
                <div className="g-container">
                    <div className="g-row">
                        <h3 className="g-section__tag">интересное</h3>
                        <h2 className="g-section__heading">Проекты профсоюза</h2>
                        <div className="g-section__actions g-dfw">
                            <Link to="/gdu-media/projects" className="g-sa__link">Смотреть все</Link>
                            <div className="g-active-project__slider__navigation g-slider__navigation g-dfw">
                                <button onClick={()=> eventSwiper.slidePrev()} className="g-bsn__btn prev">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.25 14.625L5.625 9L11.25 3.375" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </button>
                                <button onClick={()=> eventSwiper.slideNext()} className="g-bsn__btn next">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.75 3.375L12.375 9L6.75 14.625" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </button>
                            </div>
                        </div>
                        <Swiper  className='g-slider g-active-project__slider'
                            slidesPerView={1}
                            spaceBetween={10}
                            loop={true}
                            modules={[Controller]}
                            onSwiper={setEventSwiper}

                            breakpoints={{
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                    
                                }
                            }}
                        >
                            {data?.events.map((item,idx) => (
                                <SwiperSlide onClick={() => navigate('/gdu-media/projects/'+item.slug )} className='g-post g-dfw' key={idx}>
                                    <div className="g-post__banner">
                                            <img src={'/media/' + item.pic} alt="" />
                                            <div className="g-post__meta g-bage blue">{fmtDate(item.start)}</div>
                                        </div>
                                        <div className="g-post_excerpt g-dfw">
                                            <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                                </svg>    
                                            </div>
                                            <div className="g-pe__wrap">
                                                <h2 className="g-pe__title">{item.title}</h2>
                                                <p className="g-pe__text">Проект · {fmtDate(item.end)}</p>
                                            </div>
                                        </div>
                                </SwiperSlide>
                            ) )}
                        </Swiper>

                    </div>
                </div>
            </section>
            {/* About Section */}
            <section className="g-section g-about__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-about">
                            <h3 className="g-section__tag">Об организации</h3>
                            <h2 className="g-section__heading">«Газпром добыча Уренгой профсоюз» создана в 1979 году.</h2>
                            <Link to="/union" className="g-btn g-btn__transparent-blue g-dfw g-jc-sb">
                                <span>Об организации</span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 16.5L16.5 5.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="black" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>
                            </Link>
                        </div>
                        <div className="g-exerpt__info g-dfw">
                            <div className="g-slider swiper g-about__slider info">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide g-about__slide g-dfw">
                                        <div className="g-as__wrap">
                                            <h3 className="g-as__title">Цели</h3>
                                            <p>Представительство
                                                и защита профессиональных, социально-трудовых прав
                                                и интересов своих членов,
                                                а также создания благоприятных условий
                                                для повышения жизненного уровня членов профсоюза
                                            </p>
                                            <div className="g-about__slider__navigation g-slider__navigation g-dfw">
                                                <div className="g-bsn__btn prev">
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.25 14.625L5.625 9L11.25 3.375" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>                                        
                                                </div>
                                                <div className="g-bsn__btn next">
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.75 3.375L12.375 9L6.75 14.625" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>                                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="g-slider swiper g-about__slider">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide g-dfw">
                                        <img src="/assets/img/home/ab.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Hot Links Section */}
            <section className="g-section g-hot_links__section">
                <div className="g-container">
                    <div className="g-row g-jc-sb">
                        <Link to="/gdu-media/projects" className="g-hot_link">
                            <h4 className="g-hl__title">Проекты профсоюза</h4>
                            <div className="g-hl__data g-dfw g-jc-sb">
                                <p>{data?.eventCount} проектов</p>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.75 37.125L27 48.9375L47.25 37.125" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6.75 27L27 38.8125L47.25 27" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6.75 16.875L27 28.6875L47.25 16.875L27 5.0625L6.75 16.875Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </div>
                        </Link>
                        <a href="http://discount-prof.ru/participants/" target={"_blank"} className="g-hot_link">
                            <h4 className="g-hl__title">Профсоюзный дисконт</h4>
                            <div className="g-hl__data g-dfw g-jc-sb">
                                <p>{data?.bonusPartnerCount} скидок</p>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M42.1875 11.8125L11.8125 42.1875" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M16.0312 21.9375C19.2932 21.9375 21.9375 19.2932 21.9375 16.0312C21.9375 12.7693 19.2932 10.125 16.0312 10.125C12.7693 10.125 10.125 12.7693 10.125 16.0312C10.125 19.2932 12.7693 21.9375 16.0312 21.9375Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M37.9688 43.875C41.2307 43.875 43.875 41.2307 43.875 37.9688C43.875 34.7068 41.2307 32.0625 37.9688 32.0625C34.7068 32.0625 32.0625 34.7068 32.0625 37.9688C32.0625 41.2307 34.7068 43.875 37.9688 43.875Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </a>
                        {/* <Link to="/useful" className="g-hot_link">
                            <h4 className="g-hl__title">Спортивный компекс</h4>
                            <div className="g-hl__data g-dfw g-jc-sb">
                                <p>СК «Факел»</p>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.5625 11.8125H13.5C12.568 11.8125 11.8125 12.568 11.8125 13.5V40.5C11.8125 41.432 12.568 42.1875 13.5 42.1875H18.5625C19.4945 42.1875 20.25 41.432 20.25 40.5V13.5C20.25 12.568 19.4945 11.8125 18.5625 11.8125Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M40.5 11.8125H35.4375C34.5055 11.8125 33.75 12.568 33.75 13.5V40.5C33.75 41.432 34.5055 42.1875 35.4375 42.1875H40.5C41.432 42.1875 42.1875 41.432 42.1875 40.5V13.5C42.1875 12.568 41.432 11.8125 40.5 11.8125Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M42.1875 16.875H47.25C47.6976 16.875 48.1268 17.0528 48.4432 17.3693C48.7597 17.6857 48.9375 18.1149 48.9375 18.5625V35.4375C48.9375 35.8851 48.7597 36.3143 48.4432 36.6307C48.1268 36.9472 47.6976 37.125 47.25 37.125H42.1875" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M11.8125 37.125H6.75C6.30245 37.125 5.87322 36.9472 5.55676 36.6307C5.24029 36.3143 5.0625 35.8851 5.0625 35.4375V18.5625C5.0625 18.1149 5.24029 17.6857 5.55676 17.3693C5.87322 17.0528 6.30245 16.875 6.75 16.875H11.8125" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M20.25 27H33.75" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M48.9375 27H52.3125" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M1.6875 27H5.0625" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </Link> */}
                        <Link to="/help" className="g-hot_link">
                            <h4 className="g-hl__title">Вопросы председателю</h4>
                            <div className="g-hl__data g-dfw g-jc-sb">
                                <p>{data?.helpQuestionCount} ответов</p>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.0001 47.25C38.1839 47.25 47.2501 38.1838 47.2501 27C47.2501 15.8162 38.1839 6.75 27.0001 6.75C15.8163 6.75 6.75011 15.8162 6.75011 27C6.75011 38.1838 15.8163 47.25 27.0001 47.25Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M27 39.6562C27.932 39.6562 28.6875 38.9007 28.6875 37.9688C28.6875 37.0368 27.932 36.2812 27 36.2812C26.068 36.2812 25.3125 37.0368 25.3125 37.9688C25.3125 38.9007 26.068 39.6562 27 39.6562Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M27 30.376V28.6885C28.1681 28.6885 29.3101 28.3421 30.2813 27.6931C31.2526 27.0441 32.0096 26.1217 32.4567 25.0425C32.9037 23.9632 33.0207 22.7757 32.7928 21.63C32.5649 20.4843 32.0024 19.4319 31.1764 18.6059C30.3503 17.7799 29.298 17.2174 28.1523 16.9895C27.0066 16.7616 25.819 16.8785 24.7398 17.3256C23.6606 17.7726 22.7381 18.5296 22.0891 19.5009C21.4401 20.4722 21.0938 21.6141 21.0938 22.7822" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </Link>
                        <Link to="/gdu-media/print" className="g-hot_link">
                            <h4 className="g-hl__title">Печатные издания</h4>
                            <div className="g-hl__data g-dfw g-jc-sb">
                                <p>{data?.magazinesCount} выпусков</p>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27 18.5625C27 16.7723 27.7112 15.0554 28.977 13.7895C30.2429 12.5237 31.9598 11.8125 33.75 11.8125H47.25C47.6976 11.8125 48.1268 11.9903 48.4432 12.3068C48.7597 12.6232 48.9375 13.0524 48.9375 13.5V40.5C48.9375 40.9476 48.7597 41.3768 48.4432 41.6932C48.1268 42.0097 47.6976 42.1875 47.25 42.1875H33.75C31.9598 42.1875 30.2429 42.8987 28.977 44.1645C27.7112 45.4304 27 47.1473 27 48.9375" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.0625 40.5C5.0625 40.9476 5.24029 41.3768 5.55676 41.6932C5.87322 42.0097 6.30245 42.1875 6.75 42.1875H20.25C22.0402 42.1875 23.7571 42.8987 25.023 44.1645C26.2888 45.4304 27 47.1473 27 48.9375V18.5625C27 16.7723 26.2888 15.0554 25.023 13.7895C23.7571 12.5237 22.0402 11.8125 20.25 11.8125H6.75C6.30245 11.8125 5.87322 11.9903 5.55676 12.3068C5.24029 12.6232 5.0625 13.0524 5.0625 13.5V40.5Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                                               
                            </div>
                        </Link>
                        <Link to="/gdu-media/materials" className="g-hot_link">
                            <h4 className="g-hl__title">Фото и видео материалы</h4>
                            <div className="g-hl__data g-dfw g-jc-sb">
                                <p>Смотреть</p>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27 47.25C38.1838 47.25 47.25 38.1838 47.25 27C47.25 15.8162 38.1838 6.75 27 6.75C15.8162 6.75 6.75 15.8162 6.75 27C6.75 38.1838 15.8162 47.25 27 47.25Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M33.75 27L23.625 20.25V33.75L33.75 27Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                                         
                            </div>
                        </Link>
                    </div>
                </div>
            </section>
            {/* Competitions Section */}
            {/* <section className="g-section g-competition__section">
                <div className="g-container">
                    <div className="g-row">
                        <h3 className="g-section__tag">конкурсы</h3>
                        <h2 className="g-section__heading">Принимайте участие</h2>
                        <div className="g-section__actions g-dfw">
                            <Link to="/useful/competition-projects" className="g-sa__link">Смотреть все</Link>
                            <div className="g-competitions__slider__navigation g-slider__navigation g-dfw">
                                <div onClick={()=> attendSwiper.slidePrev()} className="g-bsn__btn prev">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.25 14.625L5.625 9L11.25 3.375" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                                <div onClick={()=> attendSwiper.slideNext()} className="g-bsn__btn next">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.75 3.375L12.375 9L6.75 14.625" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                            </div>
                        </div>

                        <Swiper className='g-slider g-competitions__slider'
                            slidesPerView={1}
                            spaceBetween={10}
                            loop={true}
                            modules={[Controller]}
                            onSwiper={setAttendSwiper}

                            breakpoints={{
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                    
                                }
                            }}
                        >
                            {data?.events.map((item,idx) => (
                                <SwiperSlide onClick={() => navigate('/gdu-media/projects/'+item.slug)} className='g-post g-dfw' key={idx}>
                                    <div className="g-post__banner">
                                            <img src={'/media/' + item.pic} alt="" />
                                            <div className="g-post__meta g-bage blue">{fmtDate(item.start)}</div>
                                        </div>
                                        <div className="g-post_excerpt g-dfw">
                                            <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                                </svg>    
                                            </div>
                                            <div className="g-pe__wrap">
                                                <h2 className="g-pe__title">{item.title}</h2>
                                                <p className="g-pe__text">Проект · {fmtDate(item.end)}</p>
                                            </div>
                                        </div>
                                </SwiperSlide>
                            ) )}
                        </Swiper>

                    </div>
                </div>
            </section> */}
        </>
    )
}

export default Home;