import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
const FETCH_MUTATION = gql`
mutation MyMutation($theme: ID!, $body: String!, $date: Date!) {
    createHelpVisit(inp: {themeId: $theme, body: $body, desiredAt: $date}) {
      ok
      errors
    }
  }
`
const FETCH_QUERY = gql`
    query MyQuery {
        helpVisitThemes {
        id
        name
        }
    }
`

const maskGenerator = createDefaultMaskGenerator('+7 999 999 9999');

function BookApp() {
    const [sendHandler, {data}]= useMutation(FETCH_MUTATION)
    const themes = useQuery(FETCH_QUERY)
    const navigation = useNavigate()
    const [theme, setTheme] = useState('')
    const [body, setBody] = useState('')
    const [date, setDate] = useState(new Date().toISOString().split("T")[0])
    const [phone, setPhone] = useState('')
    const onSubmit = (e)=> {
        e.preventDefault()
        sendHandler({variables: {theme: theme, body: body, date: date}}).then(res => res.data?.createHelpVisit.ok ? navigation('/help-request__success') : toast.error(res.data?.createHelpVisit.errors[0]))
    }
    return (
        <>
            <link rel="stylesheet" href="/assets/css/help.css" />
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <Link to="/" className="g-bc__item">главная</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">Записаться на прием</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                        <h2 className="g-section__heading">Записаться на прием к председателю</h2>
                        <form onSubmit={onSubmit} className="g-form g-dfw">
                            <label className="g-f__label w-33">
                                <span>Ваше имя</span>
                                <input type="text" name="name" />
                            </label>
                            <label className="g-f__label w-33">
                                <span>Номер телефона</span>
                                {/* <input type="tel" name="tel[]" className="masked-phone" placeholder="+7" /> */}
                                <MaskedInput
                                    maskGenerator={maskGenerator}
                                    value={phone}
                                    onChange={setPhone}
                                />
                            </label>
                            <label className="g-f__label w-33">
                                <span>Электронная почта</span>
                                <input type="email" placeholder="name@mail.com" />
                            </label>
                            {/* <label className="g-f__label w-33 g-hi">
                                <div className="g-fl__wrap">
                                    <span>Тема вашей встречи</span>
                                    <select onChange={e => setTheme(e.target.value)} required>
                                        <option value="">Выберите тему для встречи</option>
                                        {themes.data?.helpVisitThemes.map(item => (
                                            <option value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5 9L12 16.5L4.5 9" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </label> */}
                            <label className="g-f__label w-33 g-hi">
                                <div className="g-fl__wrap">
                                    <span>Выберите желаемую дату</span>
                                    <input onChange={e => setDate(e.target.value)} type="date" value={date} />
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M16.5 2.25V5.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5 2.25V5.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M3.75 8.25H20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.625 11.9998H11.25L9.75 13.8748C9.99664 13.8748 10.2395 13.9356 10.457 14.0518C10.6745 14.1681 10.86 14.3362 10.9971 14.5412C11.1341 14.7463 11.2185 14.9819 11.2427 15.2274C11.267 15.4728 11.2303 15.7205 11.136 15.9484C11.0417 16.1763 10.8926 16.3774 10.702 16.5339C10.5114 16.6904 10.2852 16.7976 10.0433 16.8458C9.80143 16.894 9.55139 16.8819 9.31534 16.8104C9.07928 16.7389 8.86449 16.6104 8.68999 16.4361" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M13.5 13.1248L15 11.9998V16.8748" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </label>
                            <label className="g-f__label w-33">
                                <span>Повестка встречи</span>
                                <input onChange={e => setBody(e.target.value)} type="text" placeholder="Проект..."  />
                            </label>
                            <button type="submit" className="g-btn g-btn__blue g-f__label reset w-33 g-f__btn g-dfw g-alt-center g-jc-sb">
                                <span className="reset">Отправить запрос</span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 16.5L16.5 5.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="white" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>                                
                            </button>
                            <label className="g-f__label agreement reset w-33 g-dfw g-alt-center">
                                <input type="checkbox" checked required />
                                <div className="g-fl__wrap">
                                    <span>Принимаю <a href="#">условия обработки</a> персональных данных</span>
                                </div>
                            </label>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BookApp;