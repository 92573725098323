function RequestSuccessQuetion() {
    return (
        <>
            <link rel="stylesheet" href="/assets/css/help__request_success.css" />
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <a href="/" className="g-bc__item">главная</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <a href="/help" className="g-bc__item">помощь</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">вопрос</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-post__content g-dfw">
                            <h2 className="g-section__heading">Отлично! Ваш вопрос председателю принят.</h2>  
                            <h3 className="g-section__tag gray">В ближайшее время председатель ответит на ваш вопрос.
                                Вы получите оповещение на электронную почту.</h3>
                            <a href="/" className="g-btn g-btn__blue g-f__label reset w-33 g-f__btn g-dfw g-alt-center g-jc-sb">
                                <span className="reset">Перейти на главную</span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 16.5L16.5 5.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="white" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>                                
                            </a>
                        </div>
                        <img src="/assets/img/help/good_man.png" className="g-post__man" alt=""/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RequestSuccessQuetion;