import { Swiper, SwiperSlide } from "swiper/react";
import { Controller } from 'swiper';
import { useQuery, gql } from "@apollo/client";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fmtDate } from "../../util";



const FETCH_QUERY = gql`
query MyQuery {
    postCount
    eventCount
    galleriesCount
    magazinesCount
    helpQuestionCount

    posts(order: {createdAt: DESC}, pagination: {offset: 0, limit: 10}) {
      id
      pic
      title
      slug
      createdAt
    }
    events(pagination: {offset: 0, limit: 10}) {
      id
      pic
      start
      end
      title
      slug
    }
    magazines(pagination: {offset: 0, limit: 10}) {
      id
      attach
      title
      pic
      issueDate
      issueNum
      desc
    }
    galleries {
      id
      createdAt
      title
      slug
      itemCount
      pics {
        isMain
        pic
      }
    }
}
`
function Media() {
    const { data } = useQuery(FETCH_QUERY);
    let navigate = useNavigate()
    const [topSwiper, setTopSwiper] = useState(null)
    const [eventSwiper, setEventSwiper] = useState(null)
    const [postSwiper, setPostSwiper] = useState(null)
    const [printSwiper, setPrintSwiper] = useState(null)
    const [gallerySwiper, setGallerySwiper] = useState(null)

    return (
        <>
            <link rel="stylesheet" href="/assets/css/media.css" />
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row g-position_relative">
                        <div className="g-bread__crumbs g-dfw">
                            <Link to="/" className="g-bc__item">главная</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">медиа</p>
                        </div>
                        <h2 className="g-banner__heading">Главное сегодня</h2>
                        
                        {data?.posts? <Swiper className='g-slider g-active-project__slider'
                            slidesPerView={1}
                            spaceBetween={10}
                            loop={true}
                            onSwiper={setTopSwiper}

                            breakpoints={{
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                    
                                }
                            }}
                        >
                            {data.posts.map((item,idx) => (
                                <SwiperSlide onClick={() => navigate('/gdu-media/news/'+item.slug)} className='g-post g-dfw' key={item.id}>
                                    <div className="g-post__banner">
                                            <img src={'/media/' + item.pic} alt="" />
                                            <div className="g-post__meta g-bage blue">{fmtDate(item.createdAt)}</div>
                                        </div>
                                        <div className="g-post_excerpt g-dfw">
                                            <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                                </svg>    
                                            </div>
                                            <div className="g-pe__wrap">
                                                <h2 className="g-pe__title">{item.title}</h2>
                                                <p className="g-pe__text">Новости · {fmtDate(item.createdAt)}</p>
                                            </div>
                                        </div>
                                </SwiperSlide>
                            ) )}
                        </Swiper>: null}

                        <div className="g-active-project__slider__navigation banner-nav g-slider__navigation g-dfw">
                            <div onClick={()=> topSwiper.slidePrev()} className="g-bsn__btn g-btn g-btn__transparent prev">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.25 14.625L5.625 9L11.25 3.375" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                        
                            </div>
                            <div onClick={()=> topSwiper.slideNext()} className="g-bsn__btn g-btn g-btn__transparent next">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.75 3.375L12.375 9L6.75 14.625" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                        
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            {/* <!-- Hot Links Section --> */}
            <section className="g-section g-hot_links__section">
                <div className="g-container">
                    <div className="g-row g-jc-sb">
                    <Link to="/gdu-media/projects" className="g-hot_link">
                            <h4 className="g-hl__title">Проекты профсоюза</h4>
                            <div className="g-hl__data g-dfw g-jc-sb">
                                <p>{data?.eventCount} проектов</p>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.75 37.125L27 48.9375L47.25 37.125" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6.75 27L27 38.8125L47.25 27" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6.75 16.875L27 28.6875L47.25 16.875L27 5.0625L6.75 16.875Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                            </div>
                        </Link>
                        <Link to="/gdu-media/news" className="g-hot_link">
                            <h4 className="g-hl__title">Новости профсоюза</h4>
                            <div className="g-hl__data g-dfw g-jc-sb">
                                <p>{data?.postCount} новых</p>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.25 20.25H33.75" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M20.25 27H33.75" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M20.25 33.75H27" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M33.051 45.5625H10.125C9.67745 45.5625 9.24823 45.3847 8.93176 45.0682C8.61529 44.7518 8.4375 44.3226 8.4375 43.875V10.125C8.4375 9.67745 8.61529 9.24823 8.93176 8.93176C9.24823 8.61529 9.67745 8.4375 10.125 8.4375H43.875C44.3226 8.4375 44.7518 8.61529 45.0682 8.93176C45.3847 9.24823 45.5625 9.67745 45.5625 10.125V33.051C45.5625 33.2726 45.5189 33.4921 45.434 33.6968C45.3492 33.9015 45.2249 34.0876 45.0682 34.2443L34.2443 45.0682C34.0876 45.2249 33.9015 45.3492 33.6968 45.434C33.4921 45.5189 33.2726 45.5625 33.051 45.5625V45.5625Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M45.41 33.7483H33.75V45.4089" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </Link>
                        <Link to="/gdu-media/print" className="g-hot_link">
                            <h4 className="g-hl__title">Печатные издания</h4>
                            <div className="g-hl__data g-dfw g-jc-sb">
                                <p>{data?.magazinesCount} издания</p>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27 18.5625C27 16.7723 27.7112 15.0554 28.977 13.7895C30.2429 12.5237 31.9598 11.8125 33.75 11.8125H47.25C47.6976 11.8125 48.1268 11.9903 48.4432 12.3068C48.7597 12.6232 48.9375 13.0524 48.9375 13.5V40.5C48.9375 40.9476 48.7597 41.3768 48.4432 41.6932C48.1268 42.0097 47.6976 42.1875 47.25 42.1875H33.75C31.9598 42.1875 30.2429 42.8987 28.977 44.1645C27.7112 45.4304 27 47.1473 27 48.9375" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.0625 40.5C5.0625 40.9476 5.24029 41.3768 5.55676 41.6932C5.87322 42.0097 6.30245 42.1875 6.75 42.1875H20.25C22.0402 42.1875 23.7571 42.8987 25.023 44.1645C26.2888 45.4304 27 47.1473 27 48.9375V18.5625C27 16.7723 26.2888 15.0554 25.023 13.7895C23.7571 12.5237 22.0402 11.8125 20.25 11.8125H6.75C6.30245 11.8125 5.87322 11.9903 5.55676 12.3068C5.24029 12.6232 5.0625 13.0524 5.0625 13.5V40.5Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                            </div>
                        </Link>
                        <Link to="/gdu-media/materials" className="g-hot_link">
                            <h4 className="g-hl__title">Фото-видео материалы</h4>
                            <div className="g-hl__data g-dfw g-jc-sb">
                                <p>{data?.galleriesCount} альбомов</p>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27 47.25C38.1838 47.25 47.25 38.1838 47.25 27C47.25 15.8162 38.1838 6.75 27 6.75C15.8162 6.75 6.75 15.8162 6.75 27C6.75 38.1838 15.8162 47.25 27 47.25Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M33.75 27L23.625 20.25V33.75L33.75 27Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                            </div>
                        </Link>
                        <Link to="/help" className="g-hot_link">
                            <h4 className="g-hl__title">Вопросы председателю</h4>
                            <div className="g-hl__data g-dfw g-jc-sb">
                                <p>{data?.helpQuestionCount} вопросов</p>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M35.4387 47.25L23.6247 38.8125L11.8137 47.25V15.1875C11.8137 14.7399 11.9915 14.3107 12.308 13.9943C12.6244 13.6778 13.0537 13.5 13.5012 13.5H33.7512C34.1988 13.5 34.628 13.6778 34.9445 13.9943C35.2609 14.3107 35.4387 14.7399 35.4387 15.1875V47.25Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M18.5637 6.75H40.5012C40.9488 6.75 41.378 6.92779 41.6945 7.24426C42.0109 7.56073 42.1887 7.98995 42.1887 8.4375V40.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                                              
                            </div>
                        </Link>
                        {/* <Link to="/gdu-media/favorites" className="g-hot_link">
                            <h4 className="g-hl__title">Мое избранное</h4>
                            <div className="g-hl__data g-dfw g-jc-sb">
                                <p>52 сохранено</p>
                                <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M35.4387 47.25L23.6247 38.8125L11.8137 47.25V15.1875C11.8137 14.7399 11.9915 14.3107 12.308 13.9943C12.6244 13.6778 13.0537 13.5 13.5012 13.5H33.7512C34.1988 13.5 34.628 13.6778 34.9445 13.9943C35.2609 14.3107 35.4387 14.7399 35.4387 15.1875V47.25Z" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M18.5637 6.75H40.5012C40.9488 6.75 41.378 6.92779 41.6945 7.24426C42.0109 7.56073 42.1887 7.98995 42.1887 8.4375V40.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>         
                            </div>
                        </Link> */}
                    </div>
                </div>
            </section>
            {/* <!-- Media Section --> */}
            <section className="g-section g-media__section">
                <div className="g-container">
                    <div className="g-row">
                        <h2 className="g-section__heading">Новости профсоюза</h2>
                        <div className="g-section__actions g-dfw">
                            <Link to="/gdu-media/news" className="g-sa__link">Смотреть все</Link>
                            <div className="g-media__slider__navigation g-slider__navigation g-dfw">
                                <div onClick={()=> postSwiper.slidePrev()} className="g-bsn__btn prev">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.25 14.625L5.625 9L11.25 3.375" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                                <div onClick={()=> postSwiper.slideNext()} className="g-bsn__btn next">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.75 3.375L12.375 9L6.75 14.625" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                            </div>
                        </div>
                        {data?.posts? <Swiper className='g-slider g-media__slider'
                            slidesPerView={1}
                            spaceBetween={10}
                            loop={true}
                            onSwiper={setPostSwiper}

                            breakpoints={{
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                    
                                }
                            }}
                        >
                            {data.posts.map((item,idx) => (
                                <SwiperSlide onClick={() => navigate('/gdu-media/news/'+item.slug)} className='g-post g-dfw' key={idx}>
                                    <div className="g-post__banner">
                                            <img src={'/media/' + item.pic} alt="" />
                                            <div className="g-post__meta g-bage blue">{fmtDate(item.createdAt)}</div>
                                        </div>
                                        <div className="g-post_excerpt g-dfw">
                                            <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                                </svg>    
                                            </div>
                                            <div className="g-pe__wrap">
                                                <h2 className="g-pe__title">{item.title}</h2>
                                                <p className="g-pe__text">Новости · {fmtDate(item.createdAt)}</p>
                                            </div>
                                        </div>
                                </SwiperSlide>
                            ) )}
                        </Swiper>: null}
                    </div>
                </div>
            </section>
            {/* <!-- Active Projects Section --> */}
            <section className="g-section g-active__projects">
                <div className="g-container">
                    <div className="g-row">
                        <h2 className="g-section__heading">Проекты профсоюза</h2>
                        <div className="g-section__actions g-dfw">
                            <Link to="/gdu-media/projects" className="g-sa__link">Смотреть все</Link>
                            <div className="g-active-project__slider__navigation g-slider__navigation g-dfw">
                                <div onClick={()=> eventSwiper.slidePrev()} className="g-bsn__btn prev">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.25 14.625L5.625 9L11.25 3.375" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                                <div onClick={()=> eventSwiper.slideNext()} className="g-bsn__btn next">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.75 3.375L12.375 9L6.75 14.625" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                            </div>
                        </div>

                        <Swiper className='g-slider g-active-project__slider'
                            slidesPerView={1}
                            spaceBetween={10}
                            loop={true}
                            onSwiper={setEventSwiper}

                            breakpoints={{
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                    
                                }
                            }}
                        >
                            {data?.events.map((item,idx) => (
                                <SwiperSlide onClick={() => navigate('/gdu-media/projects/'+item.slug)} className='g-post g-dfw' key={idx}>
                                    <div className="g-post__banner">
                                            <img src={'/media/' + item.pic} alt="" />
                                            <div className="g-post__meta g-bage blue">{fmtDate(item.start)}</div>
                                        </div>
                                        <div className="g-post_excerpt g-dfw">
                                            <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                                </svg>    
                                            </div>
                                            <div className="g-pe__wrap">
                                                <h2 className="g-pe__title">{item.title}</h2>
                                                <p className="g-pe__text">Проект · {fmtDate(item.start)}</p>
                                            </div>
                                        </div>
                                </SwiperSlide>
                            ) )}
                        </Swiper>
                    </div>
                </div>
            </section>
            {/* <!-- Printed Section --> */}
            <section className="g-section g-printed__section">
                <div className="g-container">
                    <div className="g-row">
                        <h2 className="g-section__heading">Печатные издания</h2>
                        <div className="g-section__actions g-dfw">
                            <Link to="/gdu-media/print" className="g-sa__link">Смотреть все</Link>
                            <div className="g-printed__slider__navigation g-slider__navigation g-dfw">
                                <div onClick={()=> printSwiper.slidePrev()} className="g-bsn__btn prev">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.25 14.625L5.625 9L11.25 3.375" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                                <div onClick={()=> printSwiper.slideNext()} className="g-bsn__btn next">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.75 3.375L12.375 9L6.75 14.625" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                            </div>
                        </div>


                        <Swiper className='g-slider g-printed__slider'
                            slidesPerView={1}
                            spaceBetween={10}
                            loop={true}
                            onSwiper={setPrintSwiper}

                            breakpoints={{
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                    
                                }
                            }}
                        >
                            {data?.magazines.map((item,idx) => (
                                <SwiperSlide className='g-post printed g-dfw' key={idx}>
                                    <div className="g-post__banner">
                                        <img src={'/media/' + item.pic} />
                                    </div>
                                    <div className="g-post_excerpt g-dfw">
                                        <div className="g-pe__wrap">
                                            <p className="g-pe__text">{item.attach.slice(item.attach.length - 3, item.attach.length).toUpperCase()} · {item.issueDate}</p>
                                            <h2 className="g-pe__title">{item.title}</h2>
                                            <div className="g-post__meta g-bage blue">{item.issueNum}</div>
                                            <p className="g-pe__content">{item.desc}</p>
                                        </div>
                                        <a href={'/media/' + item.attach} target="blank" className="g-btn g-btn__transparent-blue">Читать выпуск</a>
                                    </div>
                                </SwiperSlide>
                            ) )}
                        </Swiper>

                    </div>
                </div>
            </section>
            {/* <!-- Photo & Video Section --> */}
            <section className="g-section g-galary__section">
                <div className="g-container">
                    <div className="g-row">
                        <h2 className="g-section__heading">Фото и видео материалы</h2>
                        <div className="g-section__actions g-dfw">
                            <Link to="/gdu-media/materials" className="g-sa__link">Смотреть все</Link>
                            <div className="g-galary__slider__navigation g-slider__navigation g-dfw">
                                <div onClick={()=> gallerySwiper.slidePrev()} className="g-bsn__btn prev">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.25 14.625L5.625 9L11.25 3.375" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                                <div onClick={()=> gallerySwiper.slideNext()} className="g-bsn__btn next">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.75 3.375L12.375 9L6.75 14.625" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                        
                                </div>
                            </div>
                        </div>

                        <Swiper className='g-slider g-galary__slider'
                            slidesPerView={2}
                            spaceBetween={10}
                            loop={true}
                            onSwiper={setGallerySwiper}

                            breakpoints={{
                                992: {
                                    slidesPerView: 6,
                                    spaceBetween: 15,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 15,
                        
                                }
                            }}
                        >
                            {data?.galleries.map((item,idx) => (
                                <SwiperSlide onClick={() => navigate('/gdu-media/materials/'+item.slug)} className='g-post galary g-dfw' key={idx}>
                                    <div href="#" className="g-post__banner">
                                        <img src={'/media/' + item.pics[0].pic} alt=""/>
                                    </div>
                                    <div className="g-btn g-btn__white g-count">{item.itemCount}</div>
                                    <div className="g-pe__wrap">
                                        <h2 className="g-pe__title">{item.title}</h2>
                                        <p className="g-pe__text">PDF · {fmtDate(item.createdAt)}</p>
                                    </div>
                                </SwiperSlide>
                            ) )}
                        </Swiper>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Media;