import { gql, useQuery } from "@apollo/client";

const personQuery = gql`query($depType: DepType){
    staffList(
      depType: $depType
      pagination: {offset:  0, limit: 1}
    ) {
      id
      firstName
      lastName
      middleName
      phone
      email
      position {
        id
        name
      }
      pic
    }
  }`

export function JobSafetyChief({depType}) {
    let ft = useQuery(personQuery, {variables: {depType}})
    let person = ft.data?.staffList && ft.data?.staffList[0]
    if (!person) {
        return null
    }
  return (
    <div className="g-maneger g-dfw">
      <div className="g-mg__img">
        <img src={'/media/'+person.pic} />
      </div>
      <div className="g-mg__text-wrap">
        <h4 className="g-mgtw__heading">{person.lastName} {person.firstName} {person.middleName}</h4>
        <p className="g-mgtw__text">{person.position.name}</p>
        <div className="g-mgtw__contacts">
          <div className="g-mgtwc__item g-dfw g-alt-center">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.0573 6.82574C5.51111 7.75353 6.26318 8.50218 7.19303 8.95176C7.26106 8.984 7.33631 8.99797 7.41138 8.99229C7.48645 8.98661 7.55875 8.96148 7.62116 8.91937L8.9903 8.0064C9.05085 7.96602 9.12052 7.94139 9.19299 7.93473C9.26547 7.92807 9.33846 7.9396 9.40536 7.96827L11.9668 9.06601C12.0538 9.10297 12.1264 9.16721 12.1737 9.24905C12.2211 9.33088 12.2405 9.42588 12.2292 9.51974C12.1482 10.1532 11.8391 10.7355 11.3598 11.1575C10.8804 11.5795 10.2637 11.8124 9.625 11.8124C7.65245 11.8124 5.7607 11.0288 4.36589 9.63401C2.97109 8.23921 2.1875 6.34745 2.1875 4.3749C2.18753 3.73625 2.42036 3.1195 2.84237 2.64015C3.26439 2.16079 3.84666 1.8517 4.48016 1.77074C4.57402 1.75938 4.66902 1.77883 4.75085 1.82616C4.83269 1.87349 4.89693 1.94613 4.93389 2.03314L6.03259 4.59677C6.06101 4.66308 6.07259 4.73539 6.0663 4.80726C6.06001 4.87913 6.03604 4.94833 5.99653 5.00869L5.08673 6.39884C5.04532 6.46137 5.02084 6.53358 5.01568 6.6084C5.01052 6.68323 5.02486 6.75811 5.0573 6.82574V6.82574Z"
                stroke="#0074C1"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <a href={"tel:"+person.phone}>{person.phone}</a>
          </div>
          <div className="g-mgtwc__item g-dfw g-alt-center">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.75 3.0625H12.25V10.5C12.25 10.616 12.2039 10.7273 12.1219 10.8094C12.0398 10.8914 11.9285 10.9375 11.8125 10.9375H2.1875C2.07147 10.9375 1.96019 10.8914 1.87814 10.8094C1.79609 10.7273 1.75 10.616 1.75 10.5V3.0625Z"
                stroke="#0074C1"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.25 3.0625L7 7.875L1.75 3.0625"
                stroke="#0074C1"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <a href={"mailto:"+person.email}>{person.email}</a>
          </div>
        </div>
      </div>
    </div>
  );
}
