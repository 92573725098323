import { Link } from "react-router-dom";

function POFavorite() {
    return (
        <>
            <link rel="stylesheet" href="/assets/css/personal__ooffice/po__favorite.css"></link>
            
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <a href="/" className="g-bc__item">главная</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">избранное</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-po__nav">
                        <Link to="/project-office" className="g-pon__item g-dfw">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.2507 6.75H3.75073C3.33652 6.75 3.00073 7.08579 3.00073 7.5V19.5C3.00073 19.9142 3.33652 20.25 3.75073 20.25H20.2507C20.6649 20.25 21.0007 19.9142 21.0007 19.5V7.5C21.0007 7.08579 20.6649 6.75 20.2507 6.75Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M15.75 6.75V5.25C15.75 4.85218 15.592 4.47064 15.3107 4.18934C15.0294 3.90804 14.6478 3.75 14.25 3.75H9.75C9.35218 3.75 8.97064 3.90804 8.68934 4.18934C8.40804 4.47064 8.25 4.85218 8.25 5.25V6.75" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M21.0008 11.8418C18.2654 13.4243 15.1602 14.2553 12 14.2503C8.84038 14.2553 5.73564 13.4246 3.00061 11.8426" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10.875 11.25H13.125" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span>Проектный офис</span>                                
                            </Link>
                            <Link to="/profile" className="g-pon__item g-dfw">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.9812 18.6913C6.54615 17.5806 7.40744 16.6478 8.46973 15.9963C9.53202 15.3448 10.7539 15 12 15C13.2462 15 14.468 15.3448 15.5303 15.9963C16.5926 16.6478 17.4539 17.5806 18.0189 18.6913" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span>Личные данные</span>                                
                            </Link>
                            <Link to="/profile_sets" className="g-pon__item g-dfw">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.6249 16.4372V7.56241C20.6249 7.4296 20.5896 7.29917 20.5227 7.18445C20.4557 7.06973 20.3595 6.97485 20.2439 6.90949L12.3689 2.45841C12.2563 2.39477 12.1292 2.36133 11.9999 2.36133C11.8706 2.36133 11.7434 2.39477 11.6308 2.45841L3.75584 6.90949C3.64021 6.97485 3.54401 7.06973 3.47708 7.18445C3.41015 7.29917 3.37488 7.4296 3.37488 7.56241V16.4372C3.37488 16.57 3.41015 16.7005 3.47708 16.8152C3.54401 16.9299 3.64021 17.0248 3.75584 17.0901L11.6308 21.5412C11.7434 21.6049 11.8706 21.6383 11.9999 21.6383C12.1292 21.6383 12.2563 21.6049 12.3689 21.5412L20.2439 17.0901C20.3595 17.0248 20.4557 16.9299 20.5227 16.8152C20.5896 16.7005 20.6249 16.57 20.6249 16.4372V16.4372Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M11.9999 15.375C13.8638 15.375 15.3749 13.864 15.3749 12C15.3749 10.136 13.8638 8.625 11.9999 8.625C10.1359 8.625 8.62488 10.136 8.62488 12C8.62488 13.864 10.1359 15.375 11.9999 15.375Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                
                                <span>Настройки профиля</span>                                
                            </Link>
                            {/* <Link to="/profile_favorites" className="g-pon__item active g-dfw">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.75 21L10.4993 17.25L5.25 21V6.75C5.25 6.55109 5.32902 6.36032 5.46967 6.21967C5.61032 6.07902 5.80109 6 6 6H15C15.1989 6 15.3897 6.07902 15.5303 6.21967C15.671 6.36032 15.75 6.55109 15.75 6.75V21Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.25 6V3.75C8.25 3.55109 8.32902 3.36032 8.46967 3.21967C8.61032 3.07902 8.80109 3 9 3H18C18.1989 3 18.3897 3.07902 18.5303 3.21967C18.671 3.36032 18.75 3.55109 18.75 3.75V18L15.75 15.8574" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                                       
                                <span>Избранное</span>                                
                            </Link> */}
                            <Link to="/" onClick={() => {localStorage.removeItem('token'); window.location.href = '/'}} className="g-pon__item g-dfw">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.3135 8.0625L20.2499 12L16.3135 15.9375" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.75 12H20.2472" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.75 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H9.75" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                                                                                       
                                <span>Выход</span>                                
                            </Link>
                        </div>
                        <div className="g-po__content g-dfw">
                            <div className="g-post g-dfw">
                                <div className="g-post__banner">
                                    <img src="../../assets/img/home/ap__2.png" alt=""/>
                                    <div className="g-post__meta g-bage blue">17 августа · 2022</div>
                                </div>
                                <div className="g-post_excerpt g-dfw">
                                    <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                        </svg>    
                                    </div>
                                    <div className="g-pe__wrap">
                                        <h2 className="g-pe__title">Ямальский марафон 2022</h2>
                                        <p className="g-pe__text">Проект · 10 июля 2022</p>
                                    </div>
                                </div>
                            </div>
                            <div className="g-post g-dfw">
                                <div className="g-post__banner">
                                    <img src="../../assets/img/home/ap__1.png" alt=""/>
                                    <div className="g-post__meta g-bage blue">17 августа · 2022</div>
                                </div>
                                <div className="g-post_excerpt g-dfw">
                                    <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                        </svg>    
                                    </div>
                                    <div className="g-pe__wrap">
                                        <h2 className="g-pe__title">Ямальский марафон 2022</h2>
                                        <p className="g-pe__text">Проект · 10 июля 2022</p>
                                    </div>
                                </div>
                            </div>
                            <div className="g-post g-dfw">
                                <div className="g-post__banner">
                                    <img src="../../assets/img/home/ap__3.png" alt=""/>
                                    <div className="g-post__meta g-bage blue">17 августа · 2022</div>
                                </div>
                                <div className="g-post_excerpt g-dfw">
                                    <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                        </svg>    
                                    </div>
                                    <div className="g-pe__wrap">
                                        <h2 className="g-pe__title">Ямальский марафон 2022</h2>
                                        <p className="g-pe__text">Проект · 10 июля 2022</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default POFavorite;