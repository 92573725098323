import { gql, useQuery } from "@apollo/client"

const QUERY = gql`query ($categoryId: ID!) {
    bonusPartners(categoryId: $categoryId) {
      id
      name
      subname
      discount
      discountNote
      link
      category {
        pk
      }
      desc
      logo
      pic
      geopos
    }
  }
  `

export function Offers({ category }) {
    
    let ft = useQuery(QUERY, {variables: {categoryId: category.id}})

    return <>
        {ft.data?.bonusPartners?.map(p => <div key={p.id} className="g-osah__card g-dfw">
            <div className="g-osahc__wrap g-dfw">
                <div className="g-osahcw__row g-dfw">
                    <div className="g-osahcwr__text-wrap">
                        <h4>{p.name}</h4>
                        <p>{p.subname}</p>
                    </div>
                    <img src={'/media/'+p.logo} alt="" />
                </div>
                <div className="g-osahcw__row g-dfw g-alt-center">
                    <div className="g-bage red ">{p.discount}% скидка</div>
                    <p className="g-pd__item">{p.discountNote}</p>
                </div>
            </div>
        </div>)}
        <div className="g-pagination g-dfw g-alt-center">
            {/* <button className="g-btn g-btn__gray g-dfw g-alt-center">
                <span>Загрузить больше</span>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.86066 8.57031H2.73566V4.44531" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.65253 16.3475C6.71017 17.4051 8.05768 18.1254 9.52466 18.4172C10.9916 18.709 12.5122 18.5592 13.8941 17.9868C15.2759 17.4145 16.457 16.4452 17.288 15.2015C18.119 13.9579 18.5625 12.4957 18.5625 11C18.5625 9.50428 18.119 8.04215 17.288 6.7985C16.457 5.55486 15.2759 4.58555 13.8941 4.01316C12.5122 3.44078 10.9916 3.29101 9.52466 3.58281C8.05768 3.87462 6.71017 4.59487 5.65253 5.65251L2.73572 8.56932" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button> */}
            {/* <div className="g-pages__list g-dfw">
                <a href="#" className="g-pl__item active">1</a>
                <a href="#" className="g-pl__item">2</a>
                <a href="#" className="g-pl__item">3</a>
                <a href="#" className="g-pl__item">4</a>
            </div> */}
        </div>
    </>
}