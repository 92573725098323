import { Navigation } from 'swiper';
import { useLocation, Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { useState, useEffect } from 'react';

const FETCH_QUERY = gql`
  query MyQuery ( $pagination: OffsetPaginationInput, $tag: ID) {
    magazines(pagination: $pagination, order: {issueDate: DESC} filters: {tags: $tag}) {
        id
        attach
        title
        pic
        issueDate
        issueNum
        desc
    }
    magazineTags {
        id
        title
    }
  }
`


const PAGE_SIZE = 9

function Print() {
    let [magazinesFilter, setMagazinesFilter] = useState(null)
    let variables = {pagination: {offset: 0, limit: PAGE_SIZE}}
    if (magazinesFilter) {
        variables.tag = magazinesFilter
    }
    const { data, fetchMore } = useQuery(FETCH_QUERY, {variables});

    let [hasMore, setHasMore] = useState(true)

    useEffect(()=> {
        setHasMore(true)
    }, [magazinesFilter])

    return (
        <>
            <link rel="stylesheet" href="/assets/css/media/projects.css"/>
            <link rel="stylesheet" href="/assets/css/media/print.css?v=2"/>
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <Link to="/" className="g-bc__item">главная</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <Link to="/gdu-media" className="g-bc__item">медиа</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">Печатные издания</p>
                        </div>
                    </div>
                </div>
                
            </section>
            {/* <!-- Printed Articels Section --> */}
            <section className="g-section g-active__projects">
                <div className="g-container">
                    <div className="g-row">
                        <h2 className="g-section__heading">Печатные издания</h2>
                        <div className="g-section__actions g-tab__nav g-dfw">
                            <button onClick={() => {setMagazinesFilter(null)}} className={['g-tn__item g-btn reset ' ,  magazinesFilter == '0' ? ' g-btn__black' : ' g-btn__transparent-gray']}>Все</button>
                            {data?.magazineTags.map((item, idx) => (
                                <button key={item.id} onClick={() => {setMagazinesFilter(item.id)}} className={['g-tn__item g-btn reset ' , magazinesFilter == item.id ? ' g-btn__black' : ' g-btn__transparent-gray']}>{item.title}</button>
                            ))}
                        </div>
                    </div>
                    <div className="g-row">
                        {data?.magazines.map((item,idx) => (
                            <div key={item.id} className='g-post printed g-dfw' key={idx}>
                                <div className="g-post__banner">
                                    <img src={'/media/' + item.pic} />
                                </div>
                                <div className="g-post_excerpt g-dfw">
                                    <div className="g-pe__wrap">
                                        <p className="g-pe__text">{item.attach.slice(item.attach.length - 3, item.attach.length).toUpperCase()} · {item.issueDate}</p>
                                        <h2 className="g-pe__title">{item.title}</h2>
                                        <div className="g-post__meta g-bage blue">{item.issueNum}</div>
                                        <p className="g-pe__content">{item.desc}</p>
                                    </div>
                                    <a href={'/media/' + item.attach} target="blank" className="g-btn g-btn__transparent-blue">Читать выпуск</a>
                                </div>
                            </div>
                        ) )}
                        {hasMore? <div className="g-pagination g-dfw">
                            <button onClick={() =>  {
                                console.log('', data.magazines.length+PAGE_SIZE)
                                fetchMore({
                                            variables: {pagination: {offset: data.magazines.length, limit: PAGE_SIZE}}
                                        }).then(resp => {
                                            if (resp.data.magazines.length < PAGE_SIZE) {
                                                setHasMore(false)
                                            }
                                    })
                            }
                            } className="g-btn g-btn__gray g-dfw">
                                <span>Загрузить больше</span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.86066 8.57031H2.73566V4.44531" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.65253 16.3475C6.71017 17.4051 8.05768 18.1254 9.52466 18.4172C10.9916 18.709 12.5122 18.5592 13.8941 17.9868C15.2759 17.4145 16.457 16.4452 17.288 15.2015C18.119 13.9579 18.5625 12.4957 18.5625 11C18.5625 9.50428 18.119 8.04215 17.288 6.7985C16.457 5.55486 15.2759 4.58555 13.8941 4.01316C12.5122 3.44078 10.9916 3.29101 9.52466 3.58281C8.05768 3.87462 6.71017 4.59487 5.65253 5.65251L2.73572 8.56932" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>                            
                            </button>
                            {/* <div className="g-pages__list g-dfw">
                                <a href="#" className="g-pl__item active">1</a>
                                <a href="#" className="g-pl__item">2</a>
                                <a href="#" className="g-pl__item">3</a>
                                <a href="#" className="g-pl__item">4</a>
                            </div> */}
                        </div>: null}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Print;