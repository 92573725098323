import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { initTabs } from "../tabs";
import { DocColumns } from "./doc_columns";
import { JobSafetyChief } from "./inner_widgets/comission_cheif";
import { JobSafetyComissions } from "./inner_widgets/job_safety_comissions";
function OccSafHealth() {

    let loc = useLocation()

    useEffect(()=>{
        //Tabination
        initTabs(loc)
    })
    return (
        <>
            <link rel="stylesheet" href="/assets/css/trade-union/occupational-safety-and-health.css"/>
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <a href="/" className="g-bc__item">главная</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                              
                            </p>
                            <a href="/union" className="g-bc__item">Профсоюзная организация</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                              
                            </p>
                            <p className="g-bc__item">Охрана труда</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                        <h1 className="g-section__heading">Охрана труда</h1>
                        
                        <div className="g-tabination">
                            <div className="g-tab_navigation g-dfw">
                                <div className="g-tn__item g-dfw" data-path="comission">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 4.37402C3.75 4.37402 1.25 9.99963 1.25 9.99963C1.25 9.99963 3.75 15.624 10 15.624C16.25 15.624 18.75 9.99963 18.75 9.99963C18.75 9.99963 16.25 4.37402 10 4.37402Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M10 13.125C11.7259 13.125 13.125 11.7259 13.125 10C13.125 8.27411 11.7259 6.875 10 6.875C8.27411 6.875 6.875 8.27411 6.875 10C6.875 11.7259 8.27411 13.125 10 13.125Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                                                     
                                    Комиссия
                                </div>
                                {/* <div className="g-tn__item g-dfw">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.875 12.5C9.11866 12.5 10.9375 10.6812 10.9375 8.4375C10.9375 6.19384 9.11866 4.375 6.875 4.375C4.63134 4.375 2.8125 6.19384 2.8125 8.4375C2.8125 10.6812 4.63134 12.5 6.875 12.5Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12.1416 4.52628C12.7004 4.36885 13.2864 4.33299 13.8602 4.4211C14.434 4.50922 14.9822 4.71928 15.468 5.03713C15.9538 5.35497 16.3658 5.77323 16.6763 6.26372C16.9868 6.75421 17.1886 7.30555 17.2681 7.8806C17.3476 8.45565 17.303 9.04106 17.1372 9.5974C16.9714 10.1537 16.6883 10.6681 16.307 11.1058C15.9256 11.5435 15.4549 11.8944 14.9266 12.1349C14.3982 12.3754 13.8244 12.4999 13.2439 12.5" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M1.24969 15.4216C1.88417 14.5191 2.72649 13.7825 3.70551 13.274C4.68454 12.7655 5.77155 12.5 6.87476 12.5C7.97797 12.5 9.06499 12.7654 10.0441 13.2738C11.0231 13.7822 11.8655 14.5187 12.5 15.4212" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M13.2439 12.5C14.3472 12.4992 15.4345 12.7643 16.4136 13.2728C17.3928 13.7813 18.235 14.5182 18.8689 15.4212" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    Уполномоченные
                                </div> */}
                                <div className="g-tn__item g-dfw" data-path="tasks">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.125 8.33333V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H16.25C16.4158 3.125 16.5747 3.19085 16.6919 3.30806C16.8092 3.42527 16.875 3.58424 16.875 3.75V8.33333C16.875 14.897 11.3042 17.0716 10.1919 17.4404C10.0675 17.4832 9.93246 17.4832 9.80811 17.4404C8.69578 17.0716 3.125 14.897 3.125 8.33333Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15.8126 12.8189L9.99999 8.75L4.18726 12.8189" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                    
                                    Цели и задачи
                                </div>
                                <div className="g-tn__item g-dfw" data-path="docs">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.5 7.5H12.5" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.5 10H12.5" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.5 12.5H10" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12.2411 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H16.25C16.4158 3.125 16.5747 3.19085 16.6919 3.30806C16.8092 3.42527 16.875 3.58424 16.875 3.75V12.2411C16.875 12.3232 16.8588 12.4045 16.8274 12.4803C16.796 12.5561 16.75 12.625 16.6919 12.6831L12.6831 16.6919C12.625 16.75 12.5561 16.796 12.4803 16.8274C12.4045 16.8588 12.3232 16.875 12.2411 16.875V16.875Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M16.8185 12.499H12.5V16.8178" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                                                      
                                    Нормативные документы
                                </div>
                            </div>
                            <div className="g-tab g-dfw">
                                <JobSafetyComissions />
                            </div>
                            {/* <div className="g-tab g-dfw">
                                <h4 className="g-tab__heading">Первая ППО</h4>
                                <div className="g-com__item g-dfw">
                                    <div className="g-comi__avatar">
                                        <img src="../assets/img/trade-union/ca_avatar.png" alt=""/>
                                    </div>
                                    <div className="g-comi__wrap">
                                        <h4>Зорин Александр Николаевич</h4>
                                        <p>Председатель комиссии</p>
                                    </div>
                                </div>
                                <div className="g-com__item g-dfw">
                                    <div className="g-comi__avatar">
                                        <img src="../assets/img/trade-union/ca_avatar.png" alt=""/>
                                    </div>
                                    <div className="g-comi__wrap">
                                        <h4>Зорин Александр Николаевич</h4>
                                        <p>Председатель комиссии</p>
                                    </div>
                                </div>
                                <div className="g-com__item g-dfw">
                                    <div className="g-comi__avatar">
                                        <img src="../assets/img/trade-union/ca_avatar.png" alt=""/>
                                    </div>
                                    <div className="g-comi__wrap">
                                        <h4>Зорин Александр Николаевич</h4>
                                        <p>Председатель комиссии</p>
                                    </div>
                                </div>
                                <div className="g-com__item g-dfw">
                                    <div className="g-comi__avatar">
                                        <img src="../assets/img/trade-union/ca_avatar.png" alt=""/>
                                    </div>
                                    <div className="g-comi__wrap">
                                        <h4>Зорин Александр Николаевич</h4>
                                        <p>Председатель комиссии</p>
                                    </div>
                                </div>
                                <div className="g-com__item g-dfw">
                                    <div className="g-comi__avatar">
                                        <img src="../assets/img/trade-union/ca_avatar.png" alt=""/>
                                    </div>
                                    <div className="g-comi__wrap">
                                        <h4>Зорин Александр Николаевич</h4>
                                        <p>Председатель комиссии</p>
                                    </div>
                                </div>
                                <div className="g-com__item g-dfw">
                                    <div className="g-comi__avatar">
                                        <img src="../assets/img/trade-union/ca_avatar.png" alt=""/>
                                    </div>
                                    <div className="g-comi__wrap">
                                        <h4>Зорин Александр Николаевич</h4>
                                        <p>Председатель комиссии</p>
                                    </div>
                                </div>
                                <h4 className="g-tab__heading">Вторая ППО</h4>
                                <div className="g-com__item g-dfw">
                                    <div className="g-comi__avatar">
                                        <img src="../assets/img/trade-union/ca_avatar.png" alt=""/>
                                    </div>
                                    <div className="g-comi__wrap">
                                        <h4>Зорин Александр Николаевич</h4>
                                        <p>Председатель комиссии</p>
                                    </div>
                                </div>
                                <div className="g-com__item g-dfw">
                                    <div className="g-comi__avatar">
                                        <img src="../assets/img/trade-union/ca_avatar.png" alt=""/>
                                    </div>
                                    <div className="g-comi__wrap">
                                        <h4>Зорин Александр Николаевич</h4>
                                        <p>Председатель комиссии</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="g-tab g-dfw">
                                <div className="g-post__content">
                                    <div className="g-post__block">
                                        <h4 className="g-post__heading">Цели и задачи охраны труда</h4>
                                        <p className="g-post__text">
                                        Профсоюзная организация представляет и защищает профессиональные и социально-трудовые права и интересы членов Профсоюза, и это главная уставная цель нашей профсоюзной организации.
                                        </p>
                                        <div className="g-ol">
                                            <div className="g-ol__item g-dfw">
                                                <div className="g-btn g-btn__blue">1</div>
                                                <p>Главной задачей также является создание благоприятных условий для повышения жизненного уровня членов Профсоюза и их семей.</p>
                                            </div>
                                            <div className="g-ol__item g-dfw">
                                                <div className="g-btn g-btn__blue">2</div>
                                                <p>Проводится работа с обращениями членов профсоюза и их правовая консультация, а также вносятся предложения по улучшению локально-нормативных актов, касающихся социально-трудовых сферы.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="g-post__sidebar">
                                    <JobSafetyChief depType="job_safety" />
                                </div>
                            </div>
                            <div className="g-tab g-dfw g-jc-sb">
                                <DocColumns queryName="jobsecDocs" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OccSafHealth;