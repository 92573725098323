import { Link } from "react-router-dom";

function UnionContacts() {
    return (
        <>
            <link rel="stylesheet" href="/assets/css/help/union_contacts.css?v=2"/>
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <a href="/" className="g-bc__item">главная</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">контакты</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-post__content g-dfw">
                            <h2 className="g-section__heading">Контакты профсоюза</h2>  
                            <div className="g-contact__data g-dfw">
                                <div className="g-mmcc__contact g-dfw g-alt-center">
                                    <div className="g-btn">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.22471 9.75133C7.87302 11.0768 8.94739 12.1462 10.2758 12.7885C10.3729 12.8346 10.4804 12.8545 10.5877 12.8464C10.6949 12.8383 10.7982 12.8024 10.8874 12.7422L12.8433 11.438C12.9298 11.3803 13.0293 11.3451 13.1328 11.3356C13.2364 11.3261 13.3407 11.3426 13.4362 11.3835L17.0954 12.9517C17.2197 13.0045 17.3234 13.0963 17.3911 13.2132C17.4587 13.3301 17.4865 13.4658 17.4702 13.5999C17.3546 14.5049 16.913 15.3367 16.2282 15.9396C15.5434 16.5425 14.6624 16.8751 13.75 16.8751C10.9321 16.8751 8.22956 15.7557 6.23699 13.7631C4.24442 11.7706 3.125 9.06806 3.125 6.25014C3.12505 5.33777 3.45765 4.45671 4.06053 3.77192C4.66341 3.08712 5.49523 2.64556 6.40023 2.5299C6.53431 2.51368 6.67002 2.54146 6.78693 2.60908C6.90384 2.67669 6.99561 2.78046 7.04841 2.90476L8.61798 6.5671C8.65858 6.66183 8.67512 6.76513 8.66614 6.8678C8.65715 6.97047 8.62291 7.06932 8.56647 7.15556L7.26676 9.14147C7.2076 9.23081 7.17263 9.33396 7.16526 9.44085C7.15789 9.54775 7.17837 9.65472 7.22471 9.75133V9.75133Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </div>
                                    <div className="g-mmccc__text-wrap">
                                        <a className="g-mmcc__link">8 (3494) 94-09-88</a>
                                        <p className="g-mmcc__text">Горячаяя линия</p>
                                    </div>
                                </div>
                                <div className="g-mmcc__contact g-dfw g-alt-center">
                                    <div className="g-btn">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.5 4.375H17.5V15C17.5 15.1658 17.4342 15.3247 17.3169 15.4419C17.1997 15.5592 17.0408 15.625 16.875 15.625H3.125C2.95924 15.625 2.80027 15.5592 2.68306 15.4419C2.56585 15.3247 2.5 15.1658 2.5 15V4.375Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M17.5 4.375L10 11.25L2.5 4.375" stroke="black" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>                        
                                    </div>
                                    <div className="g-mmccc__text-wrap">
                                        <a className="g-mmcc__link">mail@oppogdu.ru</a>
                                        <p className="g-mmcc__text">Вопросы и предложения</p>
                                    </div>
                                </div>
                                <div className="g-mmcc__contact g-dfw g-alt-center">
                                    <div className="g-btn">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 12.75C13.6569 12.75 15 11.4069 15 9.75C15 8.09315 13.6569 6.75 12 6.75C10.3431 6.75 9 8.09315 9 9.75C9 11.4069 10.3431 12.75 12 12.75Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M19.5 9.75C19.5 16.5 12 21.75 12 21.75C12 21.75 4.5 16.5 4.5 9.75C4.5 7.76088 5.29018 5.85322 6.6967 4.4467C8.10322 3.04018 10.0109 2.25 12 2.25C13.9891 2.25 15.8968 3.04018 17.3033 4.4467C18.7098 5.85322 19.5 7.76088 19.5 9.75V9.75Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>            
                                    </div>
                                    <div className="g-mmccc__text-wrap">
                                        <a className="g-mmcc__link">Ямало-Ненецкий А.О., Новый Уренгой, улица Железнодорожная, д. 8</a>
                                        <p className="g-mmcc__text">Вопросы и рпедложения</p>
                                    </div>
                                </div>
                            </div>
                            <Link to={localStorage.getItem('token')? "/help-book": "/auth?back=/help-book"} className="g-btn g-btn__blue g-f__label reset w-33 g-f__btn g-dfw g-alt-center g-jc-sb">
                                <span className="reset">Запись на прием</span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 16.5L16.5 5.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="white" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>                                
                            </Link>
                        </div>
                        <div className="g-post__sidebar">

                            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A9fd1ea4e7d86d678dd696b23c3560d6e80218639924c8ab01654d627b712de9b&amp;source=constructor" width="100%" height="100%" frameborder="0"></iframe>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default UnionContacts;