import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SlTabs } from "../sl_tabs";
import { initTabs } from "../tabs";
import { Offers } from "./inner_widgets/partner_offers";

const FETCH_QUERY = gql`
  query MyQuery {
    bonusPartnerCategories {
      id
      logo
      name
      partnerCount
    }
  }
`

function GazPriv() {
    let loc = useLocation()
    let ft = useQuery(FETCH_QUERY)
    useEffect(() => {
        //PopUp`s
        let popUpBtns = document.querySelectorAll('.g-pop_up__btn'),
        popUps = document.querySelectorAll('.g-pop_up');
        
        function popUper(elem) {
            popUps.forEach((item) => {
                const closeBtn = item.querySelector('.fa-close');
                closeBtn.addEventListener('click', function () {
                    item.classList.remove('active');
                })
            });
            elem.addEventListener('click', function () {
                for (let i = 0; i < popUps.length; i++) {
                    if (elem.id == popUps[i].dataset.target) {
                        popUps[i].classList.add('active');
                    }
                }
            })
        }
        if (popUpBtns != undefined && popUps != undefined) {
            popUpBtns.forEach((item) =>  popUper(item));
        }

        initTabs(loc)
        //FAQ
        const faqs = document.querySelectorAll('.g-faq');
        console.log(faqs);

        faqs.forEach(item => {
            let currentHeight = item.clientHeight;
            let collapseHeight = item.querySelector('.g-faq__header').clientHeight + 30;
            const btn = item.querySelector('.g-btn');
            item.style.height = collapseHeight + 'px';
            
            btn.addEventListener('click', (e) => {
                if (item.classList.contains('active')) {
                    item.classList.remove('active');
                    item.style.height = collapseHeight + 'px';
                }else {
                    item.classList.add('active');
                    item.style.height = currentHeight + 'px';
                }
            })
        })
    })
    return (
        <>
            <link rel="stylesheet" href="/assets/css/useful/gazprom-privilege.css"/>
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <a href="#" className="g-bc__item">главная</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">медиа</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                        <h2 className="g-section__heading">Газпром «Привилегия»</h2>
                        
                        <div className="g-tabination">
                            <div className="g-tab_navigation g-dfw">
                                <div className="g-tn__item g-dfw">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.5 13.75L10 18.125L17.5 13.75" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M2.5 10L10 14.375L17.5 10" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M2.5 6.25L10 10.625L17.5 6.25L10 1.875L2.5 6.25Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                                                                                                             
                                    Партнеры
                                </div>
                                <div className="g-tn__item g-dfw">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 10.625C11.3807 10.625 12.5 9.50571 12.5 8.125C12.5 6.74429 11.3807 5.625 10 5.625C8.61929 5.625 7.5 6.74429 7.5 8.125C7.5 9.50571 8.61929 10.625 10 10.625Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M16.25 8.125C16.25 13.75 10 18.125 10 18.125C10 18.125 3.75 13.75 3.75 8.125C3.75 6.4674 4.40848 4.87769 5.58058 3.70558C6.75269 2.53348 8.3424 1.875 10 1.875C11.6576 1.875 13.2473 2.53348 14.4194 3.70558C15.5915 4.87769 16.25 6.4674 16.25 8.125V8.125Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                           
                                    На карте
                                </div>
                                <div className="g-tn__item g-dfw">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.5 7.5H12.5" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.5 10H12.5" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.5 12.5H10" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12.2411 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H16.25C16.4158 3.125 16.5747 3.19085 16.6919 3.30806C16.8092 3.42527 16.875 3.58424 16.875 3.75V12.2411C16.875 12.3232 16.8588 12.4045 16.8274 12.4803C16.796 12.5561 16.75 12.625 16.6919 12.6831L12.6831 16.6919C12.625 16.75 12.5561 16.796 12.4803 16.8274C12.4045 16.8588 12.3232 16.875 12.2411 16.875V16.875Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M16.8185 12.4994H12.5V16.8181" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                                                  
                                    О программе
                                </div>
                                <div className="g-tn__item g-dfw">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.5 4.375H2.5C2.15482 4.375 1.875 4.65482 1.875 5V15C1.875 15.3452 2.15482 15.625 2.5 15.625H17.5C17.8452 15.625 18.125 15.3452 18.125 15V5C18.125 4.65482 17.8452 4.375 17.5 4.375Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M13.1245 13.125H15.6245" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M9.37451 13.125H10.6245" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M1.87451 7.56659H18.1245" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    Участвовать
                                </div>
                            </div>
                            <div className="g-tab g-dfw">
                                <SlTabs tabItems={ft.data?.bonusPartnerCategories || []}
                                    renderItem={({item}) => <>
                                    <div className="g-bage gray">{item.partnerCount}</div>
                                            <p>{item.name}</p>
                                            <div className="g-tni__icon">
                                                <img src={'/media/'+item.logo} />
                                            </div></>} 

                                    renderTab={({tab})=> {
                                        return <Offers category={tab} />
                                    }}
                                            
                                />

                                {/* <!-- Mobile  --> */}
                                <div className="g-faq media mobile">
                                    <div className="g-faq__header gp g-dfw g-alt-center">
                                        <div className="g-bage gray">2</div>
                                        <p>Автовладельцам</p>
                                        <div className="g-tni__icon">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_14_29700)">
                                                <path d="M11 21.2143C16.6412 21.2143 21.2143 16.6412 21.2143 11C21.2143 5.3588 16.6412 0.785706 11 0.785706C5.3588 0.785706 0.785706 5.3588 0.785706 11C0.785706 16.6412 5.3588 21.2143 11 21.2143Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11 14.9286C13.1697 14.9286 14.9286 13.1697 14.9286 11C14.9286 8.83029 13.1697 7.07141 11 7.07141C8.83029 7.07141 7.07141 8.83029 7.07141 11C7.07141 13.1697 8.83029 14.9286 11 14.9286Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M7.07141 11V1.57141" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11 7.07141H20.4286" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M14.9286 11V20.4286" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11 14.9286H1.57141" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_14_29700">
                                                <rect width="22" height="22" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className="g-btn">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.75 12H20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12 3.75V20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>                                                                                        
                                        </div>                        
                                    </div>
                                    <div className="g-faq__body g-dfw">
                                            <div className="g-osah__card g-dfw">
                                                <div className="g-osahc__wrap g-dfw">
                                                    <div className="g-osahcw__row g-dfw">
                                                        <div className="g-osahcwr__text-wrap">
                                                            <h4>Petshop.ru</h4>
                                                            <p>Товары для животных</p>
                                                        </div>
                                                        <img src="../assets/img/useful/privilege/logo1.png" alt=""/>
                                                    </div>
                                                    <div className="g-osahcw__row g-dfw g-alt-center">
                                                        <div className="g-bage red ">7% скидка</div>
                                                        <p className="g-pd__item">По промокоду на сайте</p>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="g-osah__card g-dfw">
                                                <div className="g-osahc__wrap g-dfw">
                                                    <div className="g-osahcw__row g-dfw">
                                                        <div className="g-osahcwr__text-wrap">
                                                            <h4>AllTime</h4>
                                                            <p>Часы и ювелирные украшения</p>
                                                        </div>
                                                        <img src="../assets/img/useful/privilege/logo2.png" alt=""/>
                                                    </div>
                                                    <div className="g-osahcw__row g-dfw g-alt-center">
                                                        <div className="g-bage red ">7% скидка</div>
                                                        <p className="g-pd__item">По промокоду на сайте</p>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="g-osah__card g-dfw">
                                                <div className="g-osahc__wrap g-dfw">
                                                    <div className="g-osahcw__row g-dfw">
                                                        <div className="g-osahcwr__text-wrap">
                                                            <h4>Кэшбэк-витрина</h4>
                                                            <p>Купоны на кэшбэк</p>
                                                        </div>
                                                        <img src="../assets/img/useful/privilege/logo3.png" alt=""/>
                                                    </div>
                                                    <div className="g-osahcw__row g-dfw g-alt-center">
                                                        <div className="g-bage red ">30% скидка</div>
                                                        <p className="g-pd__item">Кэшбэк в любимых магазинах</p>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="g-osah__card g-dfw">
                                                <div className="g-osahc__wrap g-dfw">
                                                    <div className="g-osahcw__row g-dfw">
                                                        <div className="g-osahcwr__text-wrap">
                                                            <h4>ЕдаЭко клуб</h4>
                                                            <p>Продукты питания</p>
                                                        </div>
                                                        <img src="../assets/img/useful/privilege/logo4.png" alt=""/>
                                                    </div>
                                                    <div className="g-osahcw__row g-dfw g-alt-center">
                                                        <div className="g-bage red ">7% скидка</div>
                                                        <p className="g-pd__item">По промокоду на сайте</p>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="g-osah__card g-dfw">
                                                <div className="g-osahc__wrap g-dfw">
                                                    <div className="g-osahcw__row g-dfw">
                                                        <div className="g-osahcwr__text-wrap">
                                                            <h4>GOOD-AUTO</h4>
                                                            <p>Магазин автозапчастей</p>
                                                        </div>
                                                        <img src="../assets/img/useful/privilege/logo5.png" alt=""/>
                                                    </div>
                                                    <div className="g-osahcw__row g-dfw g-alt-center">
                                                        <div className="g-bage red ">7% скидка</div>
                                                        <p className="g-pd__item">По промокоду на сайте</p>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="g-osah__card g-dfw">
                                                <div className="g-osahc__wrap g-dfw">
                                                    <div className="g-osahcw__row g-dfw">
                                                        <div className="g-osahcwr__text-wrap">
                                                            <h4>Petshop.ru</h4>
                                                            <p>Товары для животных</p>
                                                        </div>
                                                        <img src="../assets/img/useful/privilege/logo1.png" alt=""/>
                                                    </div>
                                                    <div className="g-osahcw__row g-dfw g-alt-center">
                                                        <div className="g-bage red ">7% скидка</div>
                                                        <p className="g-pd__item">По промокоду на сайте</p>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="g-pagination g-dfw g-alt-center">
                                                <button className="g-btn g-btn__gray g-dfw g-alt-center">
                                                    <span>Загрузить больше</span>
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.86066 8.57031H2.73566V4.44531" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M5.65253 16.3475C6.71017 17.4051 8.05768 18.1254 9.52466 18.4172C10.9916 18.709 12.5122 18.5592 13.8941 17.9868C15.2759 17.4145 16.457 16.4452 17.288 15.2015C18.119 13.9579 18.5625 12.4957 18.5625 11C18.5625 9.50428 18.119 8.04215 17.288 6.7985C16.457 5.55486 15.2759 4.58555 13.8941 4.01316C12.5122 3.44078 10.9916 3.29101 9.52466 3.58281C8.05768 3.87462 6.71017 4.59487 5.65253 5.65251L2.73572 8.56932" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>                            
                                                </button>
                                                <div className="g-pages__list g-dfw">
                                                    <a href="#" className="g-pl__item active">1</a>
                                                    <a href="#" className="g-pl__item">2</a>
                                                    <a href="#" className="g-pl__item">3</a>
                                                    <a href="#" className="g-pl__item">4</a>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div className="g-faq media mobile">
                                    <div className="g-faq__header gp g-dfw g-alt-center">
                                        <div className="g-bage gray">2</div>
                                        <p>Для детей</p>
                                        <div className="g-tni__icon">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.5 10.2144V19.6429C5.5 20.0597 5.66556 20.4594 5.96026 20.7541C6.25496 21.0488 6.65466 21.2144 7.07143 21.2144H14.9286C15.3453 21.2144 15.745 21.0488 16.0397 20.7541C16.3344 20.4594 16.5 20.0597 16.5 19.6429V10.2144" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M16.5 10.2143V7.07148C16.5 6.65471 16.3344 6.25501 16.0397 5.96031C15.745 5.66561 15.3453 5.50005 14.9286 5.50005C14.5118 5.50005 14.1121 5.33449 13.8174 5.03979C13.5227 4.74509 13.3571 4.34539 13.3571 3.92862V3.14291C13.3571 2.51776 13.1088 1.91821 12.6668 1.47616C12.2247 1.03411 11.6252 0.785767 11 0.785767C10.3748 0.785767 9.7753 1.03411 9.33325 1.47616C8.8912 1.91821 8.64286 2.51776 8.64286 3.14291V3.92862C8.64286 4.34539 8.4773 4.74509 8.1826 5.03979C7.8879 5.33449 7.4882 5.50005 7.07143 5.50005C6.65466 5.50005 6.25496 5.66561 5.96026 5.96031C5.66556 6.25501 5.5 6.65471 5.5 7.07148V10.2143" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M3.92859 10.2144H18.0714" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11.7857 14.9286H16.5" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>                                                
                                        </div>
                                        <div className="g-btn">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.75 12H20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12 3.75V20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>                                                                                        
                                        </div>                        
                                    </div>
                                    <div className="g-faq__body g-dfw">
                                            <div className="g-osah__card g-dfw">
                                                <div className="g-osahc__wrap g-dfw">
                                                    <div className="g-osahcw__row g-dfw">
                                                        <div className="g-osahcwr__text-wrap">
                                                            <h4>Petshop.ru</h4>
                                                            <p>Товары для животных</p>
                                                        </div>
                                                        <img src="../assets/img/useful/privilege/logo1.png" alt=""/>
                                                    </div>
                                                    <div className="g-osahcw__row g-dfw g-alt-center">
                                                        <div className="g-bage red ">7% скидка</div>
                                                        <p className="g-pd__item">По промокоду на сайте</p>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="g-osah__card g-dfw">
                                                <div className="g-osahc__wrap g-dfw">
                                                    <div className="g-osahcw__row g-dfw">
                                                        <div className="g-osahcwr__text-wrap">
                                                            <h4>AllTime</h4>
                                                            <p>Часы и ювелирные украшения</p>
                                                        </div>
                                                        <img src="../assets/img/useful/privilege/logo2.png" alt=""/>
                                                    </div>
                                                    <div className="g-osahcw__row g-dfw g-alt-center">
                                                        <div className="g-bage red ">7% скидка</div>
                                                        <p className="g-pd__item">По промокоду на сайте</p>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="g-osah__card g-dfw">
                                                <div className="g-osahc__wrap g-dfw">
                                                    <div className="g-osahcw__row g-dfw">
                                                        <div className="g-osahcwr__text-wrap">
                                                            <h4>Кэшбэк-витрина</h4>
                                                            <p>Купоны на кэшбэк</p>
                                                        </div>
                                                        <img src="../assets/img/useful/privilege/logo3.png" alt=""/>
                                                    </div>
                                                    <div className="g-osahcw__row g-dfw g-alt-center">
                                                        <div className="g-bage red ">30% скидка</div>
                                                        <p className="g-pd__item">Кэшбэк в любимых магазинах</p>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="g-osah__card g-dfw">
                                                <div className="g-osahc__wrap g-dfw">
                                                    <div className="g-osahcw__row g-dfw">
                                                        <div className="g-osahcwr__text-wrap">
                                                            <h4>ЕдаЭко клуб</h4>
                                                            <p>Продукты питания</p>
                                                        </div>
                                                        <img src="../assets/img/useful/privilege/logo4.png" alt=""/>
                                                    </div>
                                                    <div className="g-osahcw__row g-dfw g-alt-center">
                                                        <div className="g-bage red ">7% скидка</div>
                                                        <p className="g-pd__item">По промокоду на сайте</p>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="g-osah__card g-dfw">
                                                <div className="g-osahc__wrap g-dfw">
                                                    <div className="g-osahcw__row g-dfw">
                                                        <div className="g-osahcwr__text-wrap">
                                                            <h4>GOOD-AUTO</h4>
                                                            <p>Магазин автозапчастей</p>
                                                        </div>
                                                        <img src="../assets/img/useful/privilege/logo5.png" alt=""/>
                                                    </div>
                                                    <div className="g-osahcw__row g-dfw g-alt-center">
                                                        <div className="g-bage red ">7% скидка</div>
                                                        <p className="g-pd__item">По промокоду на сайте</p>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="g-osah__card g-dfw">
                                                <div className="g-osahc__wrap g-dfw">
                                                    <div className="g-osahcw__row g-dfw">
                                                        <div className="g-osahcwr__text-wrap">
                                                            <h4>Petshop.ru</h4>
                                                            <p>Товары для животных</p>
                                                        </div>
                                                        <img src="../assets/img/useful/privilege/logo1.png" alt=""/>
                                                    </div>
                                                    <div className="g-osahcw__row g-dfw g-alt-center">
                                                        <div className="g-bage red ">7% скидка</div>
                                                        <p className="g-pd__item">По промокоду на сайте</p>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="g-pagination g-dfw g-alt-center">
                                                <button className="g-btn g-btn__gray g-dfw g-alt-center">
                                                    <span>Загрузить больше</span>
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.86066 8.57031H2.73566V4.44531" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M5.65253 16.3475C6.71017 17.4051 8.05768 18.1254 9.52466 18.4172C10.9916 18.709 12.5122 18.5592 13.8941 17.9868C15.2759 17.4145 16.457 16.4452 17.288 15.2015C18.119 13.9579 18.5625 12.4957 18.5625 11C18.5625 9.50428 18.119 8.04215 17.288 6.7985C16.457 5.55486 15.2759 4.58555 13.8941 4.01316C12.5122 3.44078 10.9916 3.29101 9.52466 3.58281C8.05768 3.87462 6.71017 4.59487 5.65253 5.65251L2.73572 8.56932" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>                            
                                                </button>
                                                <div className="g-pages__list g-dfw">
                                                    <a href="#" className="g-pl__item active">1</a>
                                                    <a href="#" className="g-pl__item">2</a>
                                                    <a href="#" className="g-pl__item">3</a>
                                                    <a href="#" className="g-pl__item">4</a>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="g-tab g-dfw">
                                <div className="g-tabination__sl media g-dfw">
                                    <div className="g-tab_navigation__sl">
                                        <div className="g-tn__item__sl g-dfw">
                                            <div className="g-bage gray">2</div>
                                            <p>Автовладельцам</p>
                                            <div className="g-tni__icon">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_14_29700)">
                                                    <path d="M11 21.2143C16.6412 21.2143 21.2143 16.6412 21.2143 11C21.2143 5.3588 16.6412 0.785706 11 0.785706C5.3588 0.785706 0.785706 5.3588 0.785706 11C0.785706 16.6412 5.3588 21.2143 11 21.2143Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M11 14.9286C13.1697 14.9286 14.9286 13.1697 14.9286 11C14.9286 8.83029 13.1697 7.07141 11 7.07141C8.83029 7.07141 7.07141 8.83029 7.07141 11C7.07141 13.1697 8.83029 14.9286 11 14.9286Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M7.07141 11V1.57141" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M11 7.07141H20.4286" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.9286 11V20.4286" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M11 14.9286H1.57141" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_14_29700">
                                                    <rect width="22" height="22" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="g-tn__item__sl g-dfw">
                                            <div className="g-bage gray">2</div>
                                            <p>Бытовая техника</p>
                                            <div className="g-tni__icon">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_14_29708)">
                                                    <path d="M20.4286 3.92859H1.57142C1.13748 3.92859 0.785706 4.28037 0.785706 4.7143V17.2857C0.785706 17.7197 1.13748 18.0714 1.57142 18.0714H20.4286C20.8625 18.0714 21.2143 17.7197 21.2143 17.2857V4.7143C21.2143 4.28037 20.8625 3.92859 20.4286 3.92859Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M13.3572 7.07141H4.7143C4.28037 7.07141 3.92859 7.42319 3.92859 7.85713V14.1428C3.92859 14.5768 4.28037 14.9286 4.7143 14.9286H13.3572C13.7911 14.9286 14.1429 14.5768 14.1429 14.1428V7.85713C14.1429 7.42319 13.7911 7.07141 13.3572 7.07141Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M17.2857 7.85712H18.0714" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M17.2857 10.2143H18.0714" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_14_29708">
                                                    <rect width="22" height="22" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>                                                
                                            </div>
                                        </div>
                                        <div className="g-tn__item__sl g-dfw">
                                            <div className="g-bage gray">2</div>
                                            <p>Для детей</p>
                                            <div className="g-tni__icon">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.5 10.2144V19.6429C5.5 20.0597 5.66556 20.4594 5.96026 20.7541C6.25496 21.0488 6.65466 21.2144 7.07143 21.2144H14.9286C15.3453 21.2144 15.745 21.0488 16.0397 20.7541C16.3344 20.4594 16.5 20.0597 16.5 19.6429V10.2144" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M16.5 10.2143V7.07148C16.5 6.65471 16.3344 6.25501 16.0397 5.96031C15.745 5.66561 15.3453 5.50005 14.9286 5.50005C14.5118 5.50005 14.1121 5.33449 13.8174 5.03979C13.5227 4.74509 13.3571 4.34539 13.3571 3.92862V3.14291C13.3571 2.51776 13.1088 1.91821 12.6668 1.47616C12.2247 1.03411 11.6252 0.785767 11 0.785767C10.3748 0.785767 9.7753 1.03411 9.33325 1.47616C8.8912 1.91821 8.64286 2.51776 8.64286 3.14291V3.92862C8.64286 4.34539 8.4773 4.74509 8.1826 5.03979C7.8879 5.33449 7.4882 5.50005 7.07143 5.50005C6.65466 5.50005 6.25496 5.66561 5.96026 5.96031C5.66556 6.25501 5.5 6.65471 5.5 7.07148V10.2143" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M3.92859 10.2144H18.0714" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M11.7857 14.9286H16.5" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>                                                
                                            </div>
                                        </div>
                                        <div className="g-tn__item__sl g-dfw">
                                            <div className="g-bage gray">2</div>
                                            <p>Интернет-магазины</p>
                                            <div className="g-tni__icon">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_14_29721)">
                                                    <path d="M2.85998 11.7857H15.7143L17.2857 3.92859H1.57141C1.45649 3.92901 1.34307 3.95463 1.23913 4.00365C1.13519 4.05268 1.04327 4.1239 0.969847 4.2123C0.896426 4.30071 0.843291 4.40414 0.814188 4.51532C0.785085 4.62649 0.780723 4.74269 0.801409 4.85573L2.08998 11.1414C2.12004 11.3232 2.21426 11.4881 2.35553 11.6063C2.49681 11.7246 2.67579 11.7882 2.85998 11.7857V11.7857Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M17.2857 3.92862L17.9457 1.41434C17.9819 1.23687 18.0784 1.07736 18.2187 0.962819C18.359 0.848275 18.5346 0.78573 18.7157 0.785767H21.2143" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M15.7143 11.7858L15.0543 15.0858C15.0181 15.2632 14.9216 15.4227 14.7813 15.5373C14.641 15.6518 14.4654 15.7144 14.2843 15.7143H4.71429" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M5.50001 21.2144C5.93395 21.2144 6.28572 20.8626 6.28572 20.4287C6.28572 19.9947 5.93395 19.6429 5.50001 19.6429C5.06607 19.6429 4.71429 19.9947 4.71429 20.4287C4.71429 20.8626 5.06607 21.2144 5.50001 21.2144Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M13.3571 21.2144C13.7911 21.2144 14.1428 20.8626 14.1428 20.4287C14.1428 19.9947 13.7911 19.6429 13.3571 19.6429C12.9232 19.6429 12.5714 19.9947 12.5714 20.4287C12.5714 20.8626 12.9232 21.2144 13.3571 21.2144Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_14_29721">
                                                    <rect width="22" height="22" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>                                                
                                            </div>
                                        </div>
                                        <div className="g-tn__item__sl g-dfw">
                                            <div className="g-bage gray">2</div>
                                            <p>Компьютеры и оргтехника</p>
                                            <div className="g-tni__icon">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.4286 3.14282H1.57142C1.13748 3.14282 0.785706 3.4946 0.785706 3.92854V16.5C0.785706 16.9339 1.13748 17.2857 1.57142 17.2857H20.4286C20.8625 17.2857 21.2143 16.9339 21.2143 16.5V3.92854C21.2143 3.4946 20.8625 3.14282 20.4286 3.14282Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M9.42855 17.2856L7.85712 21.2142" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12.5714 17.2856L14.1428 21.2142" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M6.28571 21.2142H15.7143" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>                                                                                              
                                            </div>
                                        </div>
                                        <div className="g-tn__item__sl g-dfw">
                                            <div className="g-bage gray">2</div>
                                            <p>Красота и здоровье</p>
                                            <div className="g-tni__icon">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.911438 6.77281C1.71287 2.90709 6.47429 -5.12684e-05 11 5.13852C17.4586 -2.26291 24.3572 6.91423 19.6743 11.6442L11 19.6428L5.9243 14.9285" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M0.785706 10.9999H4.71428L7.07142 7.85706L10.2143 13.3571L12.5714 10.2142H14.9286" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="g-tn__item__sl g-dfw">
                                            <div className="g-bage gray">2</div>
                                            <p>Недвижимость</p>
                                            <div className="g-tni__icon">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_14_29738)">
                                                    <path d="M13.3571 21.2143H0.785706V6.28577L7.07142 0.785767L13.3571 6.28577V21.2143Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M13.3571 21.2144H21.2143V10.2144H13.3571" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M7.07141 21.2144V18.0715" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M4.71429 13.3572H9.42858" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M4.71429 8.64294H9.42858" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_14_29738">
                                                    <rect width="22" height="22" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>                                                
                                            </div>
                                        </div>
                                        <div className="g-tn__item__sl g-dfw">
                                            <div className="g-bage gray">2</div>
                                            <p>Образовательные услуги</p>
                                            <div className="g-tni__icon">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.64285 14.1428H0.785706V18.8571C0.785706 19.2739 0.951266 19.6736 1.24597 19.9683C1.54067 20.263 1.94037 20.4285 2.35713 20.4285H7.07142C7.48819 20.4285 7.88789 20.263 8.18259 19.9683C8.47729 19.6736 8.64285 19.2739 8.64285 18.8571V14.1428Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M21.2143 14.1428H13.3571V18.8571C13.3571 19.2739 13.5227 19.6736 13.8174 19.9683C14.1121 20.263 14.5118 20.4285 14.9285 20.4285H19.6428C20.0596 20.4285 20.4593 20.263 20.754 19.9683C21.0487 19.6736 21.2143 19.2739 21.2143 18.8571V14.1428Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M8.64282 14.1428H13.3571" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M21.2143 14.1428V4.71427C21.2143 3.88073 20.8832 3.08133 20.2938 2.49193C19.7044 1.90253 18.905 1.57141 18.0714 1.57141H16.5" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M0.785706 14.1428V4.71427C0.785706 3.88073 1.11683 3.08133 1.70623 2.49193C2.29563 1.90253 3.09503 1.57141 3.92856 1.57141H5.49999" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>                                                
                                            </div>
                                        </div>
                                        <div className="g-tn__item__sl g-dfw">
                                            <div className="g-bage gray">2</div>
                                            <p>Одежда</p>
                                            <div className="g-tni__icon">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.9286 5.50005L17.2857 7.8572L20.4286 4.71434L16.5 0.785767H5.49998L1.57141 4.71434L4.71427 7.8572L7.07141 5.50005V11.0001C7.07141 13.1215 4.5257 14.6143 4.02284 19.4858C4.00036 19.7037 4.02369 19.9239 4.09135 20.1323C4.159 20.3407 4.26948 20.5326 4.4157 20.6958C4.56262 20.8586 4.74198 20.9888 4.94225 21.0781C5.14252 21.1674 5.35927 21.2138 5.57855 21.2143H16.4214C16.6407 21.2138 16.8574 21.1674 17.0577 21.0781C17.258 20.9888 17.4373 20.8586 17.5843 20.6958C17.7305 20.5326 17.841 20.3407 17.9086 20.1323C17.9763 19.9239 17.9996 19.7037 17.9771 19.4858C17.4743 14.6143 14.9286 13.1215 14.9286 11.0001V5.50005Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M7.07141 9.82141C8.31558 10.3426 9.65104 10.6111 11 10.6111C12.3489 10.6111 13.6844 10.3426 14.9286 9.82141" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="g-tn__item__sl g-dfw">
                                            <div className="g-bage gray">2</div>
                                            <p>Продукты и напитки</p>
                                            <div className="g-tni__icon">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_14_29755)">
                                                    <path d="M6.28571 0.785767H15.7143C17.173 0.785767 18.5719 1.36523 19.6034 2.39668C20.6348 3.42813 21.2143 4.82708 21.2143 6.28577C21.2143 6.70254 21.0487 7.10223 20.754 7.39693C20.4593 7.69163 20.0596 7.8572 19.6428 7.8572H2.35713C1.94037 7.8572 1.54067 7.69163 1.24597 7.39693C0.951266 7.10223 0.785706 6.70254 0.785706 6.28577C0.785706 4.82708 1.36517 3.42813 2.39662 2.39668C3.42807 1.36523 4.82701 0.785767 6.28571 0.785767V0.785767Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M0.785706 11.7858H21.2143" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M20.4286 15.7144H11L8.64285 18.0715L3.92856 15.7144H1.57142C1.36304 15.7144 1.16319 15.7971 1.01584 15.9445C0.868486 16.0918 0.785706 16.2917 0.785706 16.5001V16.5001C0.785706 17.7504 1.28239 18.9495 2.16649 19.8336C3.05059 20.7177 4.24968 21.2144 5.49999 21.2144H16.5C17.7503 21.2144 18.9494 20.7177 19.8335 19.8336C20.7176 18.9495 21.2143 17.7504 21.2143 16.5001C21.2143 16.2917 21.1315 16.0918 20.9841 15.9445C20.8368 15.7971 20.6369 15.7144 20.4286 15.7144Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_14_29755">
                                                    <rect width="22" height="22" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>                                                
                                            </div>
                                        </div>
                                        <div className="g-tn__item__sl g-dfw">
                                            <div className="g-bage gray">2</div>
                                            <p>Путешествия и отдых</p>
                                            <div className="g-tni__icon">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_14_29760)">
                                                    <path d="M21.2143 21.1829H20.4286C19.595 21.1829 18.7956 20.8518 18.2062 20.2624C17.6168 19.673 17.2857 18.8736 17.2857 18.04C17.2857 18.8736 16.9546 19.673 16.3652 20.2624C15.7758 20.8518 14.9764 21.1829 14.1428 21.1829C13.3093 21.1829 12.5099 20.8518 11.9205 20.2624C11.3311 19.673 11 18.8736 11 18.04C11 18.8736 10.6689 19.673 10.0795 20.2624C9.49007 20.8518 8.69067 21.1829 7.85713 21.1829C7.0236 21.1829 6.2242 20.8518 5.6348 20.2624C5.0454 19.673 4.71428 18.8736 4.71428 18.04C4.71428 18.8736 4.38316 19.673 3.79376 20.2624C3.20436 20.8518 2.40496 21.1829 1.57142 21.1829H0.785706" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M15.7143 14.8972C14.9049 13.9132 13.8877 13.1207 12.7357 12.5766C11.5837 12.0325 10.3255 11.7504 9.05141 11.7504C7.77736 11.7504 6.51913 12.0325 5.36711 12.5766C4.21509 13.1207 3.19788 13.9132 2.38855 14.8972" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M10.2143 11.8329C10.3086 8.28147 11.3929 5.04433 13.75 3.88147" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M9.05139 0.895733C10.07 0.695152 11.1268 0.893 12.0039 1.4485C12.881 2.00399 13.5116 2.87481 13.7657 3.88145" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M19.5014 4.46283C18.7782 3.68187 17.7947 3.19184 16.7356 3.08478C15.6766 2.97772 14.6149 3.261 13.75 3.88141" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M7.98285 5.5629C8.59861 4.65572 9.51133 3.99154 10.5639 3.68465C11.6165 3.37776 12.7432 3.44736 13.75 3.88147C14.7599 4.12846 15.6343 4.75829 16.1884 5.63794C16.7426 6.51759 16.9332 7.5782 16.72 8.59576" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_14_29760">
                                                    <rect width="22" height="22" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>                                                
                                            </div>
                                        </div>
                                        <div className="g-tn__item__sl g-dfw">
                                            <div className="g-bage gray">2</div>
                                            <p>Развлечения</p>
                                            <div className="g-tni__icon">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_14_29769)">
                                                        <path d="M2.78142 13.3572C1.48887 11.7187 0.785845 9.69265 0.785706 7.60573V2.48287C2.93972 1.36823 5.32967 0.786499 7.75499 0.786499C10.1803 0.786499 12.5703 1.36823 14.7243 2.48287" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M12.2414 21.12C11.6915 21.2443 11.12 21.2364 10.5737 21.0971C10.0273 20.9578 9.52184 20.691 9.09858 20.3185V20.3185C7.62812 19.0509 6.53924 17.3993 5.95368 15.5482C5.36813 13.6972 5.30899 11.7198 5.78287 9.83712L6.93001 5.24855C9.42148 4.61667 12.0309 4.61324 14.524 5.23858C17.0171 5.86392 19.316 7.09846 21.2143 8.83141L20.0672 13.4043C19.6047 15.2887 18.6283 17.0077 17.2466 18.37C15.8649 19.7323 14.1322 20.6843 12.2414 21.12V21.12Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M8.81573 10.23C9.01397 10.0226 9.26522 9.87338 9.54223 9.79859C9.81925 9.72379 10.1115 9.72627 10.3872 9.80576C10.657 9.86924 10.9053 10.003 11.1068 10.1934C11.3082 10.3838 11.4557 10.6242 11.5343 10.89" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M15.0386 11.7857C15.2305 11.5795 15.4743 11.4287 15.7444 11.349C16.0146 11.2693 16.3012 11.2637 16.5743 11.3328C16.8474 11.4018 17.0969 11.543 17.2967 11.7416C17.4965 11.9402 17.6392 12.1888 17.71 12.4614" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_14_29769">
                                                            <rect width="22" height="22" fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="g-tab__sl g-dfw">
                                        <img id="discont" className="g-pop_up__btn" src="../assets/img/useful/privilege/map.png" alt=""/>
                                    </div>

                                </div>  
                                {/* <!-- Mobile --> */}
                                <div className="g-faq media mobile">
                                    <div className="g-faq__header gp g-dfw g-alt-center">
                                        <div className="g-bage gray">2</div>
                                        <p>Автовладельцам</p>
                                        <div className="g-tni__icon">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_14_29700)">
                                                <path d="M11 21.2143C16.6412 21.2143 21.2143 16.6412 21.2143 11C21.2143 5.3588 16.6412 0.785706 11 0.785706C5.3588 0.785706 0.785706 5.3588 0.785706 11C0.785706 16.6412 5.3588 21.2143 11 21.2143Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11 14.9286C13.1697 14.9286 14.9286 13.1697 14.9286 11C14.9286 8.83029 13.1697 7.07141 11 7.07141C8.83029 7.07141 7.07141 8.83029 7.07141 11C7.07141 13.1697 8.83029 14.9286 11 14.9286Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M7.07141 11V1.57141" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11 7.07141H20.4286" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M14.9286 11V20.4286" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11 14.9286H1.57141" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_14_29700">
                                                <rect width="22" height="22" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className="g-btn">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.75 12H20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12 3.75V20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>                                                                                        
                                        </div>                        
                                    </div>
                                    <div className="g-faq__body g-dfw">
                                        <img id="discont" className="g-pop_up__btn" src="../assets/img/useful/privilege/map.png" alt=""/>
                                    </div>
                                </div>
                                <div className="g-faq media mobile">
                                    <div className="g-faq__header gp g-dfw g-alt-center">
                                        <div className="g-bage gray">2</div>
                                        <p>Автовладельцам</p>
                                        <div className="g-tni__icon">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_14_29700)">
                                                <path d="M11 21.2143C16.6412 21.2143 21.2143 16.6412 21.2143 11C21.2143 5.3588 16.6412 0.785706 11 0.785706C5.3588 0.785706 0.785706 5.3588 0.785706 11C0.785706 16.6412 5.3588 21.2143 11 21.2143Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11 14.9286C13.1697 14.9286 14.9286 13.1697 14.9286 11C14.9286 8.83029 13.1697 7.07141 11 7.07141C8.83029 7.07141 7.07141 8.83029 7.07141 11C7.07141 13.1697 8.83029 14.9286 11 14.9286Z" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M7.07141 11V1.57141" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11 7.07141H20.4286" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M14.9286 11V20.4286" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11 14.9286H1.57141" stroke="#BAC6CF" strokeLinecap="round" strokeLinejoin="round"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_14_29700">
                                                <rect width="22" height="22" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className="g-btn">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.75 12H20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12 3.75V20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>                                                                                        
                                        </div>                        
                                    </div>
                                    <div className="g-faq__body g-dfw">
                                        <img id="discont" className="g-pop_up__btn" src="../assets/img/useful/privilege/map.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="g-tab g-dfw">
                                <div className="g-t__col x40p">
                                    <img src="../assets/img/useful/privilege/tb3.png" alt=""/>
                                </div>
                                <div className="g-t__col x60p">
                                    <h4 className="g-post__heading">Контакты комплекса</h4>
                                    <p className="g-post__text">
                                        это карта, которая позволяет ее владельцу (члену профсоюза) получать скидку,совершая покупки у участника дисконтной системы. Это социальный проект, в рамках которого у владельцев карт есть возможность получать скидки на товары и услуги в более чем 100 заведениях г. Новый Уренгой. Держателями карт являются более 29 000 жителей Нового Уренгоя.
                                    </p>
                                    <div className="g-contact__data g-dfw">
                                        <div className="g-mmcc__contact g-dfw g-alt-center">
                                            <div className="g-btn">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.22471 9.75133C7.87302 11.0768 8.94739 12.1462 10.2758 12.7885C10.3729 12.8346 10.4804 12.8545 10.5877 12.8464C10.6949 12.8383 10.7982 12.8024 10.8874 12.7422L12.8433 11.438C12.9298 11.3803 13.0293 11.3451 13.1328 11.3356C13.2364 11.3261 13.3407 11.3426 13.4362 11.3835L17.0954 12.9517C17.2197 13.0045 17.3234 13.0963 17.3911 13.2132C17.4587 13.3301 17.4865 13.4658 17.4702 13.5999C17.3546 14.5049 16.913 15.3367 16.2282 15.9396C15.5434 16.5425 14.6624 16.8751 13.75 16.8751C10.9321 16.8751 8.22956 15.7557 6.23699 13.7631C4.24442 11.7706 3.125 9.06806 3.125 6.25014C3.12505 5.33777 3.45765 4.45671 4.06053 3.77192C4.66341 3.08712 5.49523 2.64556 6.40023 2.5299C6.53431 2.51368 6.67002 2.54146 6.78693 2.60908C6.90384 2.67669 6.99561 2.78046 7.04841 2.90476L8.61798 6.5671C8.65858 6.66183 8.67512 6.76513 8.66614 6.8678C8.65715 6.97047 8.62291 7.06932 8.56647 7.15556L7.26676 9.14147C7.2076 9.23081 7.17263 9.33396 7.16526 9.44085C7.15789 9.54775 7.17837 9.65472 7.22471 9.75133V9.75133Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </div>
                                            <div className="g-mmccc__text-wrap">
                                                <a className="g-mmcc__link">8 (3494) 94-09-88</a>
                                                <p className="g-mmcc__text">Горячаяя линия</p>
                                            </div>
                                        </div>
                                        <div className="g-mmcc__contact g-dfw g-alt-center">
                                            <div className="g-btn">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.5 4.375H17.5V15C17.5 15.1658 17.4342 15.3247 17.3169 15.4419C17.1997 15.5592 17.0408 15.625 16.875 15.625H3.125C2.95924 15.625 2.80027 15.5592 2.68306 15.4419C2.56585 15.3247 2.5 15.1658 2.5 15V4.375Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M17.5 4.375L10 11.25L2.5 4.375" stroke="black" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>                        
                                            </div>
                                            <div className="g-mmccc__text-wrap">
                                                <a className="g-mmcc__link">mail@oppogdu.ru</a>
                                                <p className="g-mmcc__text">Вопросы и предложения</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="g-tab">
                                <h4 className="g-post__heading">Получить дисконтную карту Газпром «Привилегия»</h4>
                                <p className="g-post__text">
                                    Оставьте онлайн заявку на участие в прогнрамме в форме ниже.
                                </p>
                                <form action="#" className="g-form g-dfw">
                                    <label className="g-f__label w-33">
                                        <span>Табельный номер</span>
                                        <input type="number" placeholder="889 605 978"/>
                                    </label>
                                    <label className="g-f__label w-33">
                                        <span>Ваше имя</span>
                                        <input type="text" name="name"/>
                                    </label>
                                    <label className="g-f__label w-33">
                                        <span>Фамилия</span>
                                        <input type="text" name=""/>
                                    </label>
                                    <label className="g-f__label w-33">
                                        <span>Номер телефона</span>
                                        <input type="number" placeholder="+7"/>
                                    </label>
                                    <label className="g-f__label w-33">
                                        <span>Электронная почта</span>
                                        <input type="email" placeholder="name@mail.com"/>
                                    </label>
                                    <label className="g-f__label w-33">
                                        <span>Подразделение</span>
                                        <input type="text" placeholder="От..." />
                                    </label>
                                    <button className="g-btn g-btn__blue g-f__label reset w-33 g-f__btn g-dfw g-alt-center g-jc-sb">
                                        <span className="reset">Отправить запрос</span>
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 16.5L16.5 5.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.5625 5.5H16.5V14.4375" stroke="white" strokeLinecap="square" strokeLinejoin="round"/>
                                        </svg>                                
                                    </button>
                                    <label className="g-f__label agreement reset w-33 g-dfw g-alt-center">
                                        <input type="checkbox"/>
                                        <div className="g-fl__wrap">
                                            <span>Принимаю <a href="#">условия обработки</a> персональных данных</span>
                                        </div>
                                    </label>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-pop_ups">
                <div className="g-pop_up g-dfw g-alt-center" data-target="discont">
                    <div className="g-pop_up__wrap">
                        <div className="g-pop_up__wrap__header">
                            <svg className="fa-close" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25 7L7 25" stroke="#CECECE" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M25 25L7 7" stroke="#CECECE" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div className="g-popup__banner g-dfw">
                            <div className="g-popb__media">
                                <img src="../assets/img/useful/pop_up__img1.png" alt=""/>
                            </div>
                            <div className="g-popb__wrap g-dfw">
                                <h4 className="g-sport__type">Good-Auto</h4>
                                <div className="g-pop-info__wrap">
                                    <p className="g-pop-info">Город Чайковский</p>
                                    <div className="g-popi__info g-dfw">
                                        <div className="g-bage red">7% скидка</div>
                                        <p className="g-pop-info"><b>Магазин автозапчастей</b></p>
                                    </div>
                                </div>
                                <p className="g-pop-info">
                                    Магазин автозапчастей GOOD-AUTO предоставляет участникам Программы «Газпром профсоюз ПРИВИЛЕГИЯ» скидку при покупке по адресу офиса Пермский край, г. Чайковский, ул. Ленина, д. 2. при предъявлении Карты Программы.
                                </p>
                                <a href="" className="g-btn g-btn__transparent-blue g-dfw g-jc-sb">
                                    <span>Перейти на сайт · Good-auto.pro</span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.5 13.5L13.5 4.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6.1875 4.5H13.5V11.8125" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default GazPriv;