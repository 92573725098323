import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { initTabs } from "../tabs";
import { DocColumns } from "./doc_columns";
import { JobSafetyChief } from "./inner_widgets/comission_cheif";

function LegalWork() {

    let loc = useLocation()

    useEffect(() => {
        //PopUp`s
        let popUpBtns = document.querySelectorAll('.g-pop_up__btn'),
        popUps = document.querySelectorAll('.g-pop_up');
        
        function popUper(elem) {
            popUps.forEach((item) => {
                const closeBtn = item.querySelector('.fa-close');
                closeBtn.addEventListener('click', function () {
                    item.classList.remove('active');
                })
            });
            elem.addEventListener('click', function () {
                for (let i = 0; i < popUps.length; i++) {
                    if (elem.id == popUps[i].dataset.target) {
                        popUps[i].classList.add('active');
                    }
                }
            })
        }
        if (popUpBtns != undefined && popUps != undefined) {
            popUpBtns.forEach((item) =>  popUper(item));
        }


        initTabs(loc)
        //FAQ
        const faqs = document.querySelectorAll('.g-faq');

        faqs.forEach(item => {
            let currentHeight = item.clientHeight;
            let collapseHeight = item.querySelector('.g-faq__header').clientHeight + 30;
            const btn = item.querySelector('.g-btn');
            item.style.height = collapseHeight + 'px';
            
            btn.addEventListener('click', (e) => {
                if (item.classList.contains('active')) {
                    item.classList.remove('active');
                    item.style.height = collapseHeight + 'px';
                }else {
                    item.classList.add('active');
                    item.style.height = currentHeight + 'px';
                }
            })
        })
    })

    return (
        <>
            <link rel="stylesheet" href="/assets/css/trade-union/legal-work.css"/>
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <a href="/" className="g-bc__item">главная</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <Link to="/union" className="g-bc__item">Профсоюз</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">Правовая работа</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                        <h1 className="g-section__heading">Правовая работа</h1>
                        
                        <div className="g-tabination">
                            <div className="g-tab_navigation g-dfw">
                                <div className="g-tn__item g-dfw" data-path="tasks">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.125 8.33333V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H16.25C16.4158 3.125 16.5747 3.19085 16.6919 3.30806C16.8092 3.42527 16.875 3.58424 16.875 3.75V8.33333C16.875 14.897 11.3042 17.0716 10.1919 17.4404C10.0675 17.4832 9.93246 17.4832 9.80811 17.4404C8.69578 17.0716 3.125 14.897 3.125 8.33333Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15.8126 12.8189L9.99999 8.75L4.18726 12.8189" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                    
                                    Цели и задачи
                                </div>
                                <div className="g-tn__item g-dfw" data-path="docs">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.5 7.5H12.5" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.5 10H12.5" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7.5 12.5H10" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12.2411 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H16.25C16.4158 3.125 16.5747 3.19085 16.6919 3.30806C16.8092 3.42527 16.875 3.58424 16.875 3.75V12.2411C16.875 12.3232 16.8588 12.4045 16.8274 12.4803C16.796 12.5561 16.75 12.625 16.6919 12.6831L12.6831 16.6919C12.625 16.75 12.5561 16.796 12.4803 16.8274C12.4045 16.8588 12.3232 16.875 12.2411 16.875V16.875Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M16.8185 12.499H12.5V16.8178" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                                                      
                                    Нормативные документы
                                </div>
                                <div className="g-tn__item g-dfw" data-path="faq">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M10 14.6875C10.3452 14.6875 10.625 14.4077 10.625 14.0625C10.625 13.7173 10.3452 13.4375 10 13.4375C9.65482 13.4375 9.375 13.7173 9.375 14.0625C9.375 14.4077 9.65482 14.6875 10 14.6875Z" fill="#8D9BA5"/>
                                        <path d="M10 11.25V10.625C10.4326 10.625 10.8556 10.4967 11.2153 10.2563C11.575 10.016 11.8554 9.67433 12.021 9.27462C12.1866 8.87491 12.2299 8.43507 12.1455 8.01074C12.0611 7.58641 11.8527 7.19663 11.5468 6.89071C11.2409 6.58478 10.8511 6.37644 10.4268 6.29203C10.0024 6.20763 9.56259 6.25095 9.16288 6.41651C8.76317 6.58208 8.42153 6.86246 8.18116 7.22219C7.94079 7.58192 7.8125 8.00485 7.8125 8.4375" stroke="#8D9BA5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                                                  
                                    Частые вопросы
                                </div>
                            </div>
                            <div className="g-tab g-dfw">
                                <div className="g-post__content">
                                    <div className="g-post__block">
                                        <h4 className="g-post__heading">Цели и задачи правовой работы</h4>
                                        <p className="g-post__text">
                                            Профсоюзная организация представляет и защищает профессиональные и социально-трудовые права и интересы членов Профсоюза, и это главная уставная цель нашей профсоюзной организации.
                                        </p>
                                        <div className="g-ol">
                                            <div className="g-ol__item g-dfw">
                                                <div className="g-btn g-btn__blue">1</div>
                                                <p>Главной задачей также является создание благоприятных условий для повышения жизненного уровня членов Профсоюза и их семей.</p>
                                            </div>
                                            <div className="g-ol__item g-dfw">
                                                <div className="g-btn g-btn__blue">2</div>
                                                <p>Проводится работа с обращениями членов профсоюза и их правовая консультация, а также вносятся предложения по улучшению локально-нормативных актов, касающихся социально-трудовых сферы.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="g-post__sidebar">
                                    <JobSafetyChief depType="job_inspection" />
                                </div>
                            </div>
                            <div className="g-tab g-dfw g-jc-sb">
                                <DocColumns queryName="jobinsDocs" />
                            </div>
                            <div className="g-tab">
                                <div className="g-faq">
                                    <div className="g-faq__header g-dfw g-alt-center">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#7744CC" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M16 23.5C16.5523 23.5 17 23.0523 17 22.5C17 21.9477 16.5523 21.5 16 21.5C15.4477 21.5 15 21.9477 15 22.5C15 23.0523 15.4477 23.5 16 23.5Z" fill="#7744CC"/>
                                            <path d="M16 18.001V17.001C16.6922 17.001 17.3689 16.7957 17.9445 16.4111C18.5201 16.0265 18.9687 15.4799 19.2336 14.8404C19.4985 14.2008 19.5678 13.4971 19.4327 12.8182C19.2977 12.1392 18.9644 11.5156 18.4749 11.0261C17.9854 10.5366 17.3617 10.2033 16.6828 10.0682C16.0039 9.93318 15.3002 10.0025 14.6606 10.2674C14.0211 10.5323 13.4744 10.9809 13.0899 11.5565C12.7053 12.1321 12.5 12.8087 12.5 13.501" stroke="#7744CC" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <h2>Что такое специальная оценка условий труда?</h2>                
                                        <div className="g-btn">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.75 12H20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12 3.75V20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>                                                                                        
                                        </div>                        
                                    </div>
                                    <div className="g-faq__body">
                                        <p>
                                            это комплекс мероприятий, выполняемых в определенном порядке: Установление экспертом наличия вредных и (или) опасных факторов на рабочих местах. Проведение исследований (измерений) уровней выявленных факторов на рабочих местах.
                                        </p>
                                    </div>
                                </div>
                                <div className="g-faq">
                                    <div className="g-faq__header g-dfw g-alt-center">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#7744CC" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M16 23.5C16.5523 23.5 17 23.0523 17 22.5C17 21.9477 16.5523 21.5 16 21.5C15.4477 21.5 15 21.9477 15 22.5C15 23.0523 15.4477 23.5 16 23.5Z" fill="#7744CC"/>
                                            <path d="M16 18.001V17.001C16.6922 17.001 17.3689 16.7957 17.9445 16.4111C18.5201 16.0265 18.9687 15.4799 19.2336 14.8404C19.4985 14.2008 19.5678 13.4971 19.4327 12.8182C19.2977 12.1392 18.9644 11.5156 18.4749 11.0261C17.9854 10.5366 17.3617 10.2033 16.6828 10.0682C16.0039 9.93318 15.3002 10.0025 14.6606 10.2674C14.0211 10.5323 13.4744 10.9809 13.0899 11.5565C12.7053 12.1321 12.5 12.8087 12.5 13.501" stroke="#7744CC" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <h2>Какие несчастные случаи подлежат учету и расследованию?</h2>                
                                        <div className="g-btn">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.75 12H20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12 3.75V20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>                                                                                        
                                        </div>                        
                                    </div>
                                    <div className="g-faq__body">
                                        <p>
                                            это комплекс мероприятий, выполняемых в определенном порядке: Установление экспертом наличия вредных и (или) опасных факторов на рабочих местах. Проведение исследований (измерений) уровней выявленных факторов на рабочих местах.
                                        </p>
                                    </div>
                                </div>
                                <div className="g-faq">
                                    <div className="g-faq__header g-dfw g-alt-center">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#7744CC" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M16 23.5C16.5523 23.5 17 23.0523 17 22.5C17 21.9477 16.5523 21.5 16 21.5C15.4477 21.5 15 21.9477 15 22.5C15 23.0523 15.4477 23.5 16 23.5Z" fill="#7744CC"/>
                                            <path d="M16 18.001V17.001C16.6922 17.001 17.3689 16.7957 17.9445 16.4111C18.5201 16.0265 18.9687 15.4799 19.2336 14.8404C19.4985 14.2008 19.5678 13.4971 19.4327 12.8182C19.2977 12.1392 18.9644 11.5156 18.4749 11.0261C17.9854 10.5366 17.3617 10.2033 16.6828 10.0682C16.0039 9.93318 15.3002 10.0025 14.6606 10.2674C14.0211 10.5323 13.4744 10.9809 13.0899 11.5565C12.7053 12.1321 12.5 12.8087 12.5 13.501" stroke="#7744CC" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <h2>Что такое специальная оценка условий труда?</h2>                
                                        <div className="g-btn">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.75 12H20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12 3.75V20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>                                                                                        
                                        </div>                        
                                    </div>
                                    <div className="g-faq__body">
                                        <p>
                                            это комплекс мероприятий, выполняемых в определенном порядке: Установление экспертом наличия вредных и (или) опасных факторов на рабочих местах. Проведение исследований (измерений) уровней выявленных факторов на рабочих местах.
                                        </p>
                                    </div>
                                </div>
                                <div className="g-faq">
                                    <div className="g-faq__header g-dfw g-alt-center">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#7744CC" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M16 23.5C16.5523 23.5 17 23.0523 17 22.5C17 21.9477 16.5523 21.5 16 21.5C15.4477 21.5 15 21.9477 15 22.5C15 23.0523 15.4477 23.5 16 23.5Z" fill="#7744CC"/>
                                            <path d="M16 18.001V17.001C16.6922 17.001 17.3689 16.7957 17.9445 16.4111C18.5201 16.0265 18.9687 15.4799 19.2336 14.8404C19.4985 14.2008 19.5678 13.4971 19.4327 12.8182C19.2977 12.1392 18.9644 11.5156 18.4749 11.0261C17.9854 10.5366 17.3617 10.2033 16.6828 10.0682C16.0039 9.93318 15.3002 10.0025 14.6606 10.2674C14.0211 10.5323 13.4744 10.9809 13.0899 11.5565C12.7053 12.1321 12.5 12.8087 12.5 13.501" stroke="#7744CC" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <h2>Какие несчастные случаи подлежат учету и расследованию?</h2>                
                                        <div className="g-btn">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.75 12H20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12 3.75V20.25" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>                                                                                        
                                        </div>                        
                                    </div>
                                    <div className="g-faq__body">
                                        <p>
                                            это комплекс мероприятий, выполняемых в определенном порядке: Установление экспертом наличия вредных и (или) опасных факторов на рабочих местах. Проведение исследований (измерений) уровней выявленных факторов на рабочих местах.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LegalWork;