import React from 'react';
import './App.css';
import { BrowserRouter as Router,Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Home from './components/home';
import Media from './components/media/media';
import Projects from './components/media/projects';
import ProjectIn from './components/media/projects-in';
import Print from './components/media/print';
import News from './components/media/news';
import NewsIn from './components/media/news-in';
import Materials from './components/media/materials';
import MaterialsIn from './components/media/materials-in';
import Favorites from './components/media/favorites';
import QuetionToChairman from './components/help/questions_to_the_chairman';
import QuetionToChairmanIn from './components/help/questions_to_the_chairman-in';
import AskQuetion from './components/help/ask_a_question_to_the_chairman';
import BookApp from './components/help/book_an_appointment';
import LocalRegs from './components/help/local-regulations';
import LocalRegsIn from './components/help/local-regulations-in';
import RequestSuccess from './components/help/request_success';
import RequestSuccessQuetion from './components/help/request_success__quetion';
import UnionContacts from './components/help/union_contacts';
import TradeUnion from './components/trade-union/trade-union';
import InterProt from './components/trade-union/interests-protection';
import UnionJobSafety from './components/trade-union/02-job-safety'
import UnionHealth from './components/trade-union/03-health'
import UnionSport from './components/trade-union/04-sport'
import UnionPrograms from './components/trade-union/05-programs'
import UnionPatriot from './components/trade-union/06-patriot'
import UnionKidsSport from './components/trade-union/06-kids-sport'
import UnionDevelopment from './components/trade-union/08-development'
import LegalWork from './components/trade-union/legal-work';
import OccSafHealth from './components/trade-union/occupational-safety-and-health';

import Useful from './components/useful/useful';
import ComProjects from './components/useful/competition-projects';
import GazPriv from './components/useful/gazprom-privilege';
import Auth from './components/personal__office/auth';
import Registration from './components/personal__office/registration';
import POFavorite from './components/personal__office/po_favorite';
import AccountActivation from './components/personal__office/accountActiveation';
import Policy from './components/policy';
import ScrollToTop from './components/navbar/scrollToTop';
import { Toaster } from 'react-hot-toast';

const OrgStr = React.lazy(()=> import('./components/trade-union/organizational-structure'))

const ProjectOffice = React.lazy(()=> import('./components/personal__office/project-office'))
const ProjectOfficeIn = React.lazy(()=> import('./components/personal__office/project-office-in'))
const ProjectPassportfilling = React.lazy(()=> import('./components/personal__office/project-passport__filing'))
const ProjectPassport = React.lazy(()=> import('./components/personal__office/project-passport'));
const Profile = React.lazy(()=> import('./components/personal__office/profile'));
const ProfileSetts = React.lazy(()=> import('./components/personal__office/profile_settings'));

function App() {

  return (
    <Router>
      <ScrollToTop />
      <header id='header'>
        <Navbar />
      </header>
      <main className='g-main'>
        <React.Suspense>
        <Routes basename="/test.oppo-gdu.ru">
          <Route exact path='/' element={< Home />}></Route>
          <Route exact path='/gdu-media' element={< Media />}></Route>
          <Route exact path='/gdu-media/projects' element={< Projects />}></Route>
          <Route exact path='/gdu-media/projects/:slug' element={< ProjectIn />}></Route>
          <Route exact path='/gdu-media/print' element={< Print />}></Route>
          <Route exact path='/gdu-media/news' element={< News />}></Route>
          <Route exact path='/gdu-media/news/in-tag/:slug' element={< News />}></Route>
          <Route exact path='/gdu-media/news/:slug' element={< NewsIn />}></Route>
          <Route exact path='/gdu-media/materials' element={< Materials />}></Route>
          <Route exact path='/gdu-media/materials/:slug' element={< MaterialsIn />}></Route>
          <Route exact path='/gdu-media/favorites' element={< Favorites />}></Route>
          <Route exact path='/help' element={< QuetionToChairman />}></Route>
          <Route exact path='/help-in' element={< QuetionToChairmanIn />}></Route>
          <Route exact path='/help-ask-quetion' element={< AskQuetion />}></Route>
          <Route exact path='/help-book' element={< BookApp />}></Route>
          <Route exact path='/help-local-regs' element={< LocalRegs />}></Route>
          <Route exact path='/help-local-regs-in' element={< LocalRegsIn />}></Route>
          <Route exact path='/help-request__success' element={< RequestSuccess />}></Route>
          <Route exact path='/help-request__success-quetion' element={< RequestSuccessQuetion />}></Route>
          <Route exact path='/contacts' element={< UnionContacts />}></Route>
          <Route exact path='/union' element={< TradeUnion />}></Route>
          <Route exact path='/union/interests-protection' element={< InterProt />}></Route>
          <Route exact path='/union/job-safety-inner' element={<UnionJobSafety />}></Route>
          <Route exact path='/union/education' element={<UnionHealth />}></Route>
          <Route exact path='/union/sport' element={<UnionSport />}></Route>
          <Route exact path='/union/programs' element={<UnionPrograms />}></Route>
          <Route exact path='/union/patriot' element={<UnionPatriot />}></Route>
          <Route exact path='/union/teens' element={<UnionKidsSport />}></Route>
          <Route exact path='/union/development' element={<UnionDevelopment />}></Route>
          <Route exact path='/union/legal-work' element={< LegalWork />}></Route>
          <Route exact path='/union/job-safety' element={< OccSafHealth />}></Route>
          <Route exact path='/union/org/:id' element={< OrgStr />}></Route>
          <Route exact path='/useful' element={< Useful />}></Route>
          <Route exact path='/useful/competition-projects' element={< ComProjects />}></Route>
          <Route exact path='/useful/gazprom-privilege' element={< GazPriv />}></Route>
          <Route exact path='/auth' element={< Auth />}></Route>
          <Route exact path='/registration' element={< Registration />}></Route>
          <Route exact path='/activation' element={< AccountActivation />}></Route>
          {localStorage.getItem('token') ? (
            <>
              <Route exact path='/project-office' element={< ProjectOffice />}></Route>
              <Route exact path='/project-office-in' element={< ProjectOfficeIn />}></Route>
              <Route exact path='/profile' element={< Profile />}></Route>
              <Route exact path='/profile_sets' element={< ProfileSetts />}></Route>
              <Route exact path='/profile_favorites' element={< POFavorite />}></Route>
              <Route exact path='/project-passport' element={< ProjectPassport />}></Route>
              <Route exact path='/project-passport-filling' element={< ProjectPassportfilling />}></Route>
            </>
          ) : ''}
          <Route exact path='/policy' element={<Policy />}></Route>
          <Route exact path='*' element={<Navigate to="/"/>}></Route>
        </Routes>
        </React.Suspense>
      </main>
      <footer id='footer'>
        <Footer />
        <div><Toaster/></div>
      </footer>
    </Router>
  );
}

export default App;
