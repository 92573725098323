export function initTabs(loc, postfix="") {
    //Tabination
    const tabination = document.querySelectorAll('.g-tabination'+postfix);

    function activeTabination(elem) {
        const tabNav = elem.querySelector('.g-tab_navigation'+postfix),
        tabBtns = elem.querySelectorAll('.g-tn__item'+postfix),
        tabs = elem.querySelectorAll('.g-tab'+postfix);

        tabNav.addEventListener('click', function (event) {
            for (let i = 0; i < tabBtns.length; i++) {
                if (event.target == tabBtns[i]) {
                    if (tabBtns[i].dataset?.path) {
                        window.history.pushState(null, null, "#"+tabBtns[i].dataset?.path)
                    }
                    showTab(i);
                }
            }
        })
        let btnIndex  = -1
        if (loc.hash) {
            let hash = loc.hash.slice(1, loc.hash.length)
            btnIndex = Array.from(tabBtns).findIndex(it => it.dataset?.path == hash)
            if (btnIndex > -1) {
                showTab(btnIndex)
            }
        }
        if (btnIndex == -1) showTab(0);
        function showTab(n) {
            tabBtns.forEach((item) => item.classList.remove('active'));
            tabs.forEach((item) => item.classList.remove('active'));
            if (tabBtns.length) tabBtns[n].classList.add('active');
            if (tabs.length) tabs[n].classList.add('active');
        }
    }
    if (tabination !== undefined && tabination !== null) {
        tabination.forEach(item => activeTabination(item));
    }

    return ()=> {
        Array.from(document.querySelectorAll(`.g-tabination${postfix} .g-tab_navigation${postfix}`)).map(elem => {
            elem.removeEventListener("click")
        })
    }
}