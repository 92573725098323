import { Link } from "react-router-dom";
function LocalRegs() {
    return (
        <>
            <link rel="stylesheet" href="/assets/css/help.css" />
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <Link to="/" className="g-bc__item">главная</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">нормативные акты</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                        <h2 className="g-section__heading">Локальные нормативные акты</h2>
                        <a href="./help__local-regulations-in.html" className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <div className="g-bage white">4</div>
                                <h4 className="g-osahc__heading">Устав нефтегазстрой профсоюза РФ</h4>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>
                            </div>
                        </a>
                        <a href="./help__local-regulations-in.html" className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <div className="g-bage white">4</div>
                                <h4 className="g-osahc__heading">Устав нефтегазстрой профсоюза РФ</h4>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>
                            </div>
                        </a>
                        <a href="./help__local-regulations-in.html" className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <div className="g-bage white">4</div>
                                <h4 className="g-osahc__heading">Устав нефтегазстрой профсоюза РФ</h4>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>
                            </div>
                        </a>
                        <a href="./help__local-regulations-in.html" className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <div className="g-bage white">4</div>
                                <h4 className="g-osahc__heading">Устав нефтегазстрой профсоюза РФ</h4>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>
                            </div>
                        </a>
                        <a href="./help__local-regulations-in.html" className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <div className="g-bage white">4</div>
                                <h4 className="g-osahc__heading">Устав нефтегазстрой профсоюза РФ</h4>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>
                            </div>
                        </a>
                        <a href="./help__local-regulations-in.html" className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <div className="g-bage white">4</div>
                                <h4 className="g-osahc__heading">Устав нефтегазстрой профсоюза РФ</h4>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>
                            </div>
                        </a>
                        <a href="./help__local-regulations-in.html" className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <div className="g-bage white">4</div>
                                <h4 className="g-osahc__heading">Устав нефтегазстрой профсоюза РФ</h4>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>
                            </div>
                        </a>
                        <a href="./help__local-regulations-in.html" className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <div className="g-bage white">4</div>
                                <h4 className="g-osahc__heading">Устав нефтегазстрой профсоюза РФ</h4>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>
                            </div>
                        </a>
                        <a href="./help__local-regulations-in.html" className="g-osah__card g-dfw">
                            <div className="g-osahc__wrap g-dfw">
                                <div className="g-bage white">4</div>
                                <h4 className="g-osahc__heading">Устав нефтегазстрой профсоюза РФ</h4>
                            </div>
                            <div className="g-osahc__icon">
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6C6 3.79086 7.79086 2 10 2H18H21.5632C22.1568 2 22.7197 2.26365 23.0997 2.71963L29.5364 10.4437C29.836 10.8032 30 11.2562 30 11.7241V17V28C30 30.2091 28.2091 32 26 32H10C7.79086 32 6 30.2091 6 28V6Z" stroke="#0074C2" strokeWidth="1.5"/>
                                    <path d="M22.5 2.75V9C22.5 10.1046 23.3954 11 24.5 11H29.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 17H24" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path opacity="0.3" d="M12 24.5H18" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round"/>
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LocalRegs;