import { Link } from "react-router-dom";

function InterProt() {
    return (
        <>
            <link rel="stylesheet" href="/assets/css/trade-union/interests-protection.css" />
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <a href="/" className="g-bc__item">главная</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <a href="/union" className="g-bc__item">Профсоюз</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">Защита интересов</p>
                        </div>
                    </div>
                </div>
                
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-post__content">
                            {/* <div className="g-post__banner__widget">
                                <img src="../assets/img/trade-union/isbg.png" alt=""/>
                            </div> */}
                            <div className="g-post__data g-dfw g-alt-center">
                                <h2 className="g-post__title">Защита интересов</h2>
                                
                            </div>
                            <div className="g-post__block">
                                <p className="g-post__text">
                                    Профсоюзная организация представляет и защищает профессиональные и социально-трудовые права и интересы членов Профсоюза,  и это главная уставная цель нашей профсоюзной организации. 
                                </p>
                                <p className="g-post__text">
                                    Главной задачей также является создание благоприятных 
                                    условий для повышения жизненного уровня членов Профсоюза и их семей.
                                </p>
                                <p className="g-post__text">
                                    Проводится работа с обращениями членов профсоюза и их правовая консультация, а также вносятся предложения по улучшению локально-нормативных актов, касающихся социально-трудовых сферы.
                                </p>
                            </div>
                        </div>
                        <div className="g-post__sidebar">
                            <h2 className="g-post__sidebar__heading">Цели</h2>
                            <Link to="/union/interests-protection" className="g-mgtw__heading active">Защита интересов </Link>
                            <Link to="/union/job-safety-inner" className="g-mgtw__heading">Охрана труда</Link>
                            <Link to="/union/education" className="g-mgtw__heading">Обучение</Link>
                            <Link to="/union/sport" className="g-mgtw__heading">Спорт</Link>
                            <Link to="/union/programs" className="g-mgtw__heading">Программы</Link>
                            <Link to="/union/patriot" className="g-mgtw__heading">Патриотизм</Link>
                            <Link to="/union/teens" className="g-mgtw__heading">Молодежь</Link>
                            <Link to="/union/development" className="g-mgtw__heading">Развитие</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default InterProt;