import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper';
import { useLocation, Link, useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { fmtDate, fmtDatetime } from "../../util";

const FETCH_QUERY = gql`
query MyQuery ($slug: String) {
    event(slug: $slug) {
      id
      title
      pic
      pics {
        pic
        isMain
      }
      start
      end
      faved
      viewed
      shared
      desc
      comments(pagination: {offset: 0, limit: 10}) {
        id
        creator {
          firstName
          lastName
          pic
        }
        createdAt
        body
      }
      commentCount
    }
    events(pagination: {offset: 0, limit: 6}) {
        id
        title
        slug
        pic
        start
        end
    }
  }
`
const FETCH_MUTATION = gql`
  mutation MyMutation ($postId: ID!, $body: String!) {
    createPostComment(inp: {postId: $postId, body: $body}) {
      ok
      errors
    }
  }
`
function ProjectIn() {
    let {slug} = useParams()
    const [comment, setComment] = useState('')
    const { data } = useQuery(FETCH_QUERY, {
        variables: {slug}
    });
    const [picSwiper, setPicSwiper] = useState()
    const [submitComment, commentStatus] = useMutation(FETCH_MUTATION, {variables: {postId: data?.event.id, body: comment}})
    const [commentLimit, setCommentLimit] = useState(3)
    return (
        <>
            <link rel="stylesheet" href="/assets/css/media/projects-in.css"/>
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <Link to="/" className="g-bc__item">главная</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <Link to="/gdu-media" className="g-bc__item">медиа</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <Link to="/gdu-media/projects" className="g-bc__item">проекты профсоюза</Link>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">{data?.event.title}</p>
                        </div>
                    </div>
                </div>
                
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-post__content">
                            <div className="g-post__banner__widget">
                                <img src={'/media/' + data?.event.pic} alt=""/>
                            </div>
                            <div className="g-post__data g-dfw g-alt-center">
                                <h2 className="g-post__title">{data?.event.title}</h2>
                                
                                {/* <div className="g-pd__item">
                                    <h4 className="g-bage transparent g-dfw">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.4994 12.25L6.99898 10.0625L3.49939 12.25V2.625C3.49939 2.50897 3.54548 2.39769 3.62753 2.31564C3.70958 2.23359 3.82086 2.1875 3.93689 2.1875H10.0619C10.1779 2.1875 10.2892 2.23359 10.3712 2.31564C10.4533 2.39769 10.4994 2.50897 10.4994 2.625V12.25Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <span>{data?.event.faved}</span>                                        
                                    </h4>
                                </div> */}
                                {/* <div className="g-pd__item g-dfw">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 3.0625C2.625 3.0625 0.875 7.00043 0.875 7.00043C0.875 7.00043 2.625 10.9375 7 10.9375C11.375 10.9375 13.125 7.00043 13.125 7.00043C13.125 7.00043 11.375 3.0625 7 3.0625Z" stroke="#B2BCC3" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7 9.1875C8.20812 9.1875 9.1875 8.20812 9.1875 7C9.1875 5.79188 8.20812 4.8125 7 4.8125C5.79188 4.8125 4.8125 5.79188 4.8125 7C4.8125 8.20812 5.79188 9.1875 7 9.1875Z" stroke="#B2BCC3" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    {data?.event.viewed}                                  
                                </div>
                                <div className="g-pd__item g-dfw">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.625 8.3125L12.25 5.6875L9.625 3.0625" stroke="#B2BCC3" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M10.5 11.8125H2.1875C2.07147 11.8125 1.96019 11.7664 1.87814 11.6844C1.79609 11.6023 1.75 11.491 1.75 11.375V4.8125" stroke="#B2BCC3" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M4.10291 9.62498C4.39428 8.49801 5.05179 7.49977 5.97212 6.78707C6.89246 6.07436 8.02348 5.68759 9.1875 5.6875H12.25" stroke="#B2BCC3" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                    
                                    {data?.event.shared}                                  
                                </div> */}
                                <div className="g-pd__item g-dfw">Проект · {fmtDate(data?.event.start)}</div>
                            </div>
                            <div className="g-post__block">
                                <p className="g-post__text" style={{whiteSpace: "pre-wrap", lineHeight: 1.5}}>
                                    {data?.event.desc} 
                                </p>
                            </div>
                            <Swiper className='g-post__slider g-slider'
                                slidesPerView={1}
                                spaceBetween={10}
                                loop={true}
                                onSwiper={setPicSwiper}
                            >
                                {data?.event.pics.map((item,idx) => (
                                    <SwiperSlide className='g-post g-dfw' key={idx}>
                                        <img src={'/media/' + item.pic} alt=""/>
                                    </SwiperSlide>
                                ) )}
                                {data?.event.pics.length > 1? <div className="g-slider-navigation g-post__slider-1__navigation g-dfw g-jc-center">
                                    <div onClick={()=> picSwiper.slidePrev()} className="g-slider__nav prev">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 13L5 8L10 3" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    <div className="g-slider__pagination"></div>
                                    <div onClick={()=> picSwiper.slideNext()} className="g-slider__nav next">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 3L11 8L6 13" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>: null}
                            </Swiper>

                            <div className="g-post__comments">
                                <h4 className="g-post__heading">Комментарии</h4>
                                <form action="#" className="g-form g-dfw g-jc-sb">
                                    <label className="g-label">
                                        <input type="text" name="post_comment" value={comment} onInput={e => setComment(e.target.value)} placeholder="Ваш комментрий"/>
                                    </label>
                                    <button type="button" className="g-btn g-btn__blue" onClick={()=> submitComment().then(res => window.location.reload(true) )}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.375 20.249H4.46875C4.27813 20.249 4.09531 20.1733 3.96052 20.0385C3.82573 19.9037 3.75 19.7209 3.75 19.5303V11.624C3.75 10.4914 3.97309 9.3698 4.40654 8.32337C4.83998 7.27694 5.4753 6.32613 6.2762 5.52522C7.0771 4.72432 8.02791 4.08901 9.07435 3.65556C10.1208 3.22211 11.2423 2.99902 12.375 2.99902H12.375C13.5077 2.99902 14.6292 3.22212 15.6756 3.65556C16.7221 4.08901 17.6729 4.72432 18.4738 5.52523C19.2747 6.32613 19.91 7.27695 20.3435 8.32338C20.7769 9.36981 21 10.4914 21 11.624V11.624C21 13.9115 20.0913 16.1053 18.4738 17.7228C16.8563 19.3403 14.6625 20.249 12.375 20.249Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M9.37494 10.499H14.9999" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M9.37494 13.499H14.9999" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>                                        
                                    </button>
                                </form>
                                <div className="g-comments-wrap">
                                    {data?.event.comments.slice(0, commentLimit).map((item, idx) => (
                                        <div className="g-comment g-dfw g-alt-center g-jc-sb">
                                            <div className="g-comment__user__avatar">
                                                <img src={'/media/' + item.creator.pic} alt=""/>
                                            </div>
                                            <div className="g-comment__text-wrap">
                                                <h4>{ item.creator.firstName + ' ' + item.creator.lastName }</h4>
                                                <span className="g-ctw__data">{fmtDatetime(item.createdAt)}</span>
                                                <p>{item.body}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <p className="g-comments__collapser g-dfw" onClick={() => setCommentLimit(data?.event.comments.length)}>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="#0074C2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <span>Загрузить больше</span>                                    
                                </p>
                            </div>
                        </div>
                        <div className="g-post__sidebar">
                            <h2 className="g-post__sidebar__heading">Другие проекты</h2>
                            {data?.events.map(item => (
                            <Link to={"/gdu-media/projects/"+item.slug} className="g-post g-dfw">
                                <div className="g-post__banner">
                                    <img src={'/media/' + item.pic} alt=""/>
                                    <div className="g-post__meta g-bage blue">{fmtDatetime(item.end)}</div>
                                </div>
                                <div className="g-post_excerpt g-dfw">
                                    <div className="g-pe__icon g-btn g-btn__transparent-blue">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 16.5L16.5 5.5" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M7.5625 5.5H16.5V14.4375" stroke="#0072C5" strokeLinecap="square" strokeLinejoin="round"/>
                                        </svg>    
                                    </div>
                                    <div className="g-pe__wrap">
                                        <h2 className="g-pe__title">{item.title}</h2>
                                        <p className="g-pe__text">Проект · {fmtDate(item.start)}</p>
                                    </div>
                                </div>
                            </Link>
                            ))}

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProjectIn;