import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

const FETCH_QUERY = gql`
    mutation MyMutation ($user: String!, $password: String!) {
        auth(password: $password, username: $user) {
        ok
        errors
        user {
            email
            firstName
            lastName
            middleName
            phone
            pic
            unionCardNum
            position {
            id
            name
            }
        }
        jwt {
            token
        }
        }
    }
`
const PASSWORD_RECOVERY = gql`
    mutation MyMutation ($email: String!) {
        recoverPassword(email: $email) {
            errors
            ok
        }
    }
`


function Auth() {
    let [searchParams, setSearchParams] = useSearchParams();
    let backUrl = searchParams.get("back")
    
    useEffect(()=> {
        let popUpBtns = document.querySelectorAll('.g-pop_up__btn'),
        popUps = document.querySelectorAll('.g-pop_up');
        
        function popUper(elem) {
            popUps.forEach((item) => {
                const closeBtn = item.querySelector('.fa-close');
                closeBtn.addEventListener('click', function () {
                    item.classList.remove('active');
                })
            });
            elem.addEventListener('click', function () {
                for (let i = 0; i < popUps.length; i++) {
                    if (elem.id == popUps[i].dataset.target) {
                        popUps[i].classList.add('active');
                    }
                }
            })
        }
        if (popUpBtns != undefined && popUps != undefined) {
            popUpBtns.forEach((item) =>  popUper(item));
        }
    })

    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')

    const [email, setEmail] = useState('')

    const [submitHandler, { data, error }] = useMutation(FETCH_QUERY, {
        variables: {user: user, password: password}
    })
    const [recoverPassword, recoveryRes] = useMutation(PASSWORD_RECOVERY, {variables: {email: email}})

    return (
        <>
            <link rel="stylesheet" href="/assets/css/personal__ooffice/po__auth.css"></link>
            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <a href="/" className="g-bc__item">главная</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">авторизация</p>
                        </div>
                    </div>
                </div>
                <div className="g-auth__block">
                    <h2 className="g-section__heading">Войти</h2>
                    <form action="#" className="g-form g-dfw">
                        <label className="g-f__label g-hi">
                            <div className="g-fl__wrap">
                                <span>Электронная почта</span>
                                <input type="email" placeholder="name@mail.com" value={user} onChange={e => setUser(e.target.value)} />
                            </div>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M2.90527 20.2491C3.82736 18.6531 5.15322 17.3278 6.74966 16.4064C8.34611 15.485 10.1569 15 12.0002 15C13.8434 15 15.6542 15.4851 17.2506 16.4065C18.8471 17.3279 20.1729 18.6533 21.0949 20.2493" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>                                                        
                        </label>
                        <label className="g-f__label g-hi">
                            <div className="g-fl__wrap">
                                <span>Пароль</span>
                                <input type="password" placeholder="· · · · · · · · · · · · · · ·" value={password} onChange={e => setPassword(e.target.value)}/>
                            </div>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.5 8.25H4.5C4.08579 8.25 3.75 8.58579 3.75 9V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V9C20.25 8.58579 19.9142 8.25 19.5 8.25Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8.625 8.25V4.875C8.625 3.97989 8.98058 3.12145 9.61351 2.48851C10.2465 1.85558 11.1049 1.5 12 1.5C12.8951 1.5 13.7535 1.85558 14.3865 2.48851C15.0194 3.12145 15.375 3.97989 15.375 4.875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 15C12.4142 15 12.75 14.6642 12.75 14.25C12.75 13.8358 12.4142 13.5 12 13.5C11.5858 13.5 11.25 13.8358 11.25 14.25C11.25 14.6642 11.5858 15 12 15Z" fill="black"/>
                            </svg>                                                     
                        </label>
                        <p id="recover" className="g-recover g-pop_up__btn">Восстановить пароль</p>
                        <button type="button" className="g-btn g-btn__blue g-f__btn g-dfw g-alt-center g-jc-sb" onClick={() => submitHandler({variables: {user: user, password: password}}).then(res => {
                            if (res?.data?.auth.ok) {
                                localStorage.setItem('token', res?.data?.auth.jwt.token)
                                if (backUrl) {
                                    window.location.href = backUrl
                                } else {
                                    window.location.href = '/project-office'
                                }
                            } else alert(res?.data?.auth.errors[0])})}>
                            <span className="reset">Войти</span>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 16.5L16.5 5.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M7.5625 5.5H16.5V14.4375" stroke="white" strokeLinecap="square" strokeLinejoin="round"/>
                            </svg>                                
                        </button>
                        {/* <Link to="/registration" className="g-btn g-btn__transparent-blue g-f__label reset g-f__btn g-dfw g-alt-center g-jc-sb">
                            <span className="reset">Зарегистрироваться</span>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 13.75H8.25V11L16.5 2.75L19.25 5.5L11 13.75Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.4375 4.8125L17.1875 7.5625" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18.5625 10.3125V17.875C18.5625 18.0573 18.4901 18.2322 18.3611 18.3611C18.2322 18.4901 18.0573 18.5625 17.875 18.5625H4.125C3.94266 18.5625 3.7678 18.4901 3.63886 18.3611C3.50993 18.2322 3.4375 18.0573 3.4375 17.875V4.125C3.4375 3.94266 3.50993 3.7678 3.63886 3.63886C3.7678 3.50993 3.94266 3.4375 4.125 3.4375H11.6875" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>                            
                        </Link> */}
                    </form>
                </div>
            </section>
            <section className="g-pop_ups">
                <div className="g-pop_up g-dfw g-alt-center" data-target="recover">
                    <div className="g-pop_up__wrap">
                        <div className="g-pop_up__wrap__header">
                            <svg className="fa-close" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25 7L7 25" stroke="#CECECE" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M25 25L7 7" stroke="#CECECE" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <h4 className="g-fr__heiding">Восстановить мой пароль</h4>
                        <p className="g-fr__text">Введите адрес электройнной почты, для восстановления вашего доступа.</p>
                        <form className="g-form recovery g-dfw">
                            <label className="g-f__label">
                                <span>Электронная почта</span>
                                <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="name@mail.com"/>                                                        
                            </label>
                            <button type="button" onClick={() => recoverPassword().then(res => res.data?.recoverPassword.ok ? '' : alert(res.data?.recoverPassword.errors[0]))} className="g-btn g-btn__blue g-f__btn g-dfw g-alt-center g-jc-sb">
                                <span className="reset">Отправить пароль</span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 16.5L16.5 5.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="white" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>                                
                            </button>
                        </form>
                    </div>
                </div>
                <div className={recoveryRes.data?.recoverPassword.ok ? 'g-pop_up g-dfw g-alt-center active' : 'g-pop_up g-dfw g-alt-center'} data-target="recovered-successful">
                    <div className="g-pop_up__wrap">
                        <div className="g-pop_up__wrap__header">
                            <svg className="fa-close" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25 7L7 25" stroke="#CECECE" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M25 25L7 7" stroke="#CECECE" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div className="g-success-massege g-dfw">
                            <h4 className="g-fr__heiding">Отлично! Мы отправили ваш новый пароль
                                на электронную почту.</h4>
                            <svg width="81" height="90" viewBox="0 0 81 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_14_38964)">
                                    <path d="M40.3846 66.9234C38.4923 66.9234 36.9231 65.3541 36.9231 63.4618V54.2311C36.9231 52.3388 38.4923 50.7695 40.3846 50.7695C42.2769 50.7695 43.8462 52.3388 43.8462 54.2311V63.4618C43.8462 65.3541 42.2769 66.9234 40.3846 66.9234Z" fill="#60B0E7"/>
                                    <path d="M58.3845 9.36923C55.7537 5.63077 52.0614 2.76923 47.723 1.24615C45.3691 0.415385 42.8768 0 40.3845 0C40.1076 0 39.8768 0 39.5999 0C38.6307 0 37.6153 0.138462 36.6461 0.276923C36.1845 0.369231 35.723 0.461538 35.2614 0.553846C34.9384 0.646154 34.6153 0.646154 34.2922 0.738461C33.7384 0.876923 33.2307 1.15385 32.6768 1.33846C32.2614 1.47692 31.8461 1.61538 31.4768 1.8C30.323 2.30769 29.1691 2.90769 28.1076 3.6C28.0153 3.69231 27.923 3.78462 27.7845 3.83077C26.8153 4.47692 25.9384 5.21538 25.1076 6.04615C24.7845 6.32308 24.5537 6.64615 24.2307 6.96923C23.8614 7.38462 23.4461 7.75385 23.123 8.16923C23.0307 8.30769 22.9845 8.44615 22.9384 8.58462C20.123 12.2769 18.4153 16.8 18.4153 21.8308V31.0615H25.3384V21.8308C25.3384 17.9077 26.8614 14.3538 29.3537 11.6769C29.5384 11.4462 29.723 11.2615 29.9537 11.0308C30.5076 10.4769 31.1537 9.96923 31.7999 9.50769C31.8922 9.46154 31.9384 9.36923 32.0307 9.32308C32.7691 8.86154 33.5076 8.44615 34.3384 8.07692C34.6153 7.93846 34.8922 7.89231 35.1691 7.75385C35.723 7.52308 36.323 7.33846 36.923 7.2C37.2461 7.10769 37.5691 7.06154 37.8461 7.01538C38.4922 6.92308 39.1845 6.83077 39.8768 6.83077C41.7691 6.78462 43.6153 7.01538 45.4153 7.66154C48.3691 8.72308 50.9076 10.6615 52.7076 13.2C53.8153 14.7692 55.9384 15.1385 57.5076 14.0769C59.0768 13.0154 59.4461 10.8462 58.3845 9.27692V9.36923Z" fill="#60B0E7"/>
                                    <path d="M58.8462 90.0001H21.9231C9.83077 90.0001 0 80.1693 0 68.077V49.6155C0 37.5232 9.83077 27.6924 21.9231 27.6924H58.8462C70.9385 27.6924 80.7692 37.5232 80.7692 49.6155V68.077C80.7692 80.1693 70.9385 90.0001 58.8462 90.0001ZM21.9231 34.6155C13.6615 34.6155 6.92308 41.3539 6.92308 49.6155V68.077C6.92308 76.3385 13.6615 83.077 21.9231 83.077H58.8462C67.1077 83.077 73.8462 76.3385 73.8462 68.077V49.6155C73.8462 41.3539 67.1077 34.6155 58.8462 34.6155H21.9231Z" fill="#0074C1"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_14_38964">
                                        <rect width="80.7692" height="90" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>                        
                        </div>
                        <p className="g-fr__text">Проверьте указанный адрес электронной почты и выполните вход.</p>
                        <div className="g-form recovery g-dfw">
                            <a href="/auth" className="g-btn g-btn__blue g-f__btn g-dfw g-alt-center g-jc-sb">
                                <span className="reset">Перейти ко входу</span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 16.5L16.5 5.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5625 5.5H16.5V14.4375" stroke="white" strokeLinecap="square" strokeLinejoin="round"/>
                                </svg>                                
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Auth;