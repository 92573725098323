import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FETCH_QUERY = gql`
  query ($filters: QuestionFilter, $order: QuestionOrder, $pagination: OffsetPaginationInput) {
    helpQuestionTags {
        id
        title
    }
    helpQuestions(pagination: $pagination, order: $order, filters: $filters) {
      answer
      answerAt
      id
      body
    }
  }
`

let PAGE_SIZE = 10

function QuetionToChairman() {
    const [tag, setTag] = useState(null)
    const {data, fetchMore} = useQuery(FETCH_QUERY, {variables: {
        pagination: {limit: PAGE_SIZE, offset: 0},
        filters: {...(tag? {tags: tag}: {}), answerAt: {isNull: false}}
    }})

    let [hasMore, setHasMore] = useState(true)

    useEffect(()=> {
        setHasMore(true)
    }, [tag])

    useEffect(()=> {
        if (data?.helpQuestions?.length < PAGE_SIZE) {
            console.log('has more false')
            setHasMore(false)
        }
    }, [data])

    return (
        <>
            <link rel="stylesheet" href="/assets/css/help__questions_to_the_chairman.css" />

            <section className="g-section g-banner__section">
                <div className="g-container">
                    <div className="g-row">
                        <div className="g-bread__crumbs g-dfw">
                            <a href="/" className="g-bc__item">главная</a>
                            <p className="g-bc__item">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.3">
                                    <path d="M7.5 9.75L3.75 6L7.5 2.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                </svg>                                
                            </p>
                            <p className="g-bc__item">Помощь</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-section g-post__section">
                <div className="g-container">
                    <div className="g-row">
                        <h2 className="g-section__heading">Вопросы председателю</h2>
                        <div className="g-section__actions g-tab__nav g-dfw">
                            {/* <button onClick={()=> setTag(null)} className={clsx("g-tn__item g-btn g-btn__transparent-gray reset", tag == null && 'g-btn__black')}>Все</button>
                            {data?.helpQuestionTags?.map(t => <button  key={t.id}
                                onClick={()=> setTag(t.id)}
                                className={clsx("g-tn__item g-btn g-btn__transparent-gray reset", t.id == tag && "g-btn__black" )}>
                                    {t.title}
                                </button>)} */}
                            <Link to={localStorage.getItem("token")? "/help-ask-quetion": "/auth?back=/help-ask-quetion"} className="g-tn__item g-btn g-btn__blue">Задать свой вопрос</Link>
                        </div>
                    </div>
                    <div className="g-row g-jc-sb">
                        {data?.helpQuestions.map(item => (
                            <Link key={item.id} to="/help-in" state={{id: item.id}} className="g-post__materials g-dfw">
                                <div className="g-pm__text-wrap">
                                    <h4>{item.body}</h4>
                                    <div className="g-bage blue">ответ получен</div>
                                </div>
                                <div className="g-pm__icon">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.3438 18.5618H4.09635C3.92162 18.5618 3.75403 18.4924 3.63047 18.3688C3.50691 18.2452 3.4375 18.0777 3.4375 17.9029V10.6555C3.4375 9.61724 3.642 8.58915 4.03933 7.62992C4.43665 6.67069 5.01902 5.79911 5.75318 5.06495C6.48734 4.33079 7.35892 3.74842 8.31815 3.35109C9.27738 2.95377 10.3055 2.74927 11.3437 2.74927H11.3438C12.382 2.74927 13.4101 2.95377 14.3693 3.3511C15.3286 3.74842 16.2001 4.33079 16.9343 5.06495C17.6685 5.79912 18.2508 6.6707 18.6482 7.62993C19.0455 8.58916 19.25 9.61725 19.25 10.6555V10.6555C19.25 12.7524 18.417 14.7634 16.9343 16.2461C15.4516 17.7288 13.4406 18.5618 11.3438 18.5618Z" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M8.59375 9.62427H13.75" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M8.59375 12.3743H13.75" stroke="#0074C1" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                                                        
                                </div>
                            </Link>

                        ))}
                    </div>

                    {hasMore? <div className="g-pagination g-dfw">
                        <button onClick={() => fetchMore({variables: {offset: data.helpQuestions.length, limit: PAGE_SIZE}}).then(resp => {
                            if (resp.data?.helpQuestions?.length < PAGE_SIZE) {
                                setHasMore(false)
                            }
                        })} className="g-btn g-btn__gray g-dfw g-alt-center">
                            <span>Загрузить больше</span>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.86066 8.57031H2.73566V4.44531" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M5.65253 16.3475C6.71017 17.4051 8.05768 18.1254 9.52466 18.4172C10.9916 18.709 12.5122 18.5592 13.8941 17.9868C15.2759 17.4145 16.457 16.4452 17.288 15.2015C18.119 13.9579 18.5625 12.4957 18.5625 11C18.5625 9.50428 18.119 8.04215 17.288 6.7985C16.457 5.55486 15.2759 4.58555 13.8941 4.01316C12.5122 3.44078 10.9916 3.29101 9.52466 3.58281C8.05768 3.87462 6.71017 4.59487 5.65253 5.65251L2.73572 8.56932" stroke="#0072C5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>                            
                        </button>
                    </div>: null}

                </div>
            </section>
        </>
    )
}

export default QuetionToChairman;